import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { BsFillEyeFill } from 'react-icons/bs';
import { FaPrint } from 'react-icons/fa';
import Visitors from './VisitorsChart';
import SalesChart from "./SalesChart";
import axios from 'axios';
import BASE_URL from './constant/constant';

const Reports = () => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleButtonClick = (rowData) => {
    const combinedRowData = {
      ...rowData,
      ...itemsData.find((item) => item.invoiceNumber === rowData.invoiceNumber),
    };
    setSelectedRowData(combinedRowData);
    setModalShow(true);
  };
 
  const handlePrint = () => {
    // Open a new window with a print-friendly version of the modal content
    const printWindow = window.open('', '_blank');

    // Create a print-friendly HTML content
    const printContent = `
    <html>
      <head>
        <title>Invoice Details</title>
        <style>
          body {
            font-family: 'Poppins', Arial, sans-serif;
            color: #333;
          }
          .invoice-container {
            padding: 20px;
            font-family: 'Poppins';
          }
          .invoice-header {
            text-align: center;
            margin-bottom: 20px;
          }
          .your-info, .client-info, .invoice-info {
            margin-bottom: 20px;
          }
          .items-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          .items-table th, .items-table td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
          }
          .items-table th {
            background-color: #fbe7d4;
            font-weight: 800;
          }
          .footer {
            margin-top: 20px;
          }
          .terms-and-conditions {
            margin-top: 20px;
          }
        </style>
      </head>
      <body>
        <div class="invoice-container">
          <div class="invoice-header">
            <h1 style="font-size: 30px;">INVOICE</h1>
          </div>
          <div class="your-info">
            <p>${companyInfo[0].Companyname}</p>
            <p>${companyInfo[0].companyAddress}</p>
            <p>${companyInfo[0].phoneNumber}</p>
            <p>${companyInfo[0].companymail}</p>
          </div>
          <div class="row client-info">
            <div class="col-md-6">
              <h3 style="font-weight: 800; font-size: 16px;">Bill to:</h3>
              <p>${selectedRowData?.clientName}</p>
              <p>${selectedRowData?.address}</p>
              <p>${selectedRowData?.phoneNumber}</p>
              <p>${selectedRowData?.email}</p>
            </div>
            <div class="col-md-6" style="margin-top: 20px;">
              <p><strong>Invoice Number: </strong>${selectedRowData?.invoiceNumber}</p>
              <p><strong>Invoice Date: </strong>${selectedRowData?.invoiceDate}</p>
              <p><strong>Payment Due: </strong>${selectedRowData?.paymentDue}</p>
            </div>
          </div>
          <div class="items-table">
            <table>
              <thead>
                <tr>
                  <th style="width: 40%;">Item</th>
                  <th style="width: 20%;">Quantity</th>
                  <th style="width: 20%;">Price per Unit</th>
                  <th style="width: 20%;">Amount</th>
                </tr>
              </thead>
              <tbody>
                ${Object.keys(selectedRowData || {}).map((key) => {
                  if (key.startsWith('item')) {
                    const itemNumber = key.replace('item', '');
                    return `
                      <tr>
                        <td>${selectedRowData[key]}</td>
                        <td>${selectedRowData[`quantity${itemNumber}`]}</td>
                        <td>${'$' + selectedRowData[`pricePerUnit${itemNumber}`]}</td>
                        <td>${'$' + selectedRowData[`amount${itemNumber}`]}</td>
                      </tr>
                    `;
                  }
                  return '';
                }).join('')}
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3" style="text-align: right; font-size: 18px; font-weight: 400; border:0px solid #fff"><strong>Subtotal</strong></td>
                  <td style="border:0px solid #fff">${'$' + calculateSubtotal()}</td>
                </tr>
                <tr>
                  <td colspan="3" style="text-align: right; font-size: 18px; font-weight: 400; border:0px solid #fff"><strong>Tax 0.00%</strong></td>
                  <td style="border:0px solid #fff">${'$' + calculateTax()}</td>
                </tr>
                <tr>
                  <td colspan="3" style="text-align: right; font-size: 18px; font-weight: 400; border:0px solid #fff"><strong>Fees/Discounts</strong></td>
                  <td style="border:0px solid #fff">${'$' + calculateFees()}</td>
                </tr>
                <tr>
                  <td colspan="3" style="background-color: #fbe7d4;  font-size: 20px; font-weight: 400; font-family: 'Poppins';"><strong>TOTAL</strong></td>
                  <td style="background-color: #fbe7d4; font-size: 20px; font-weight: 600; font-family: 'Poppins';">${'$' + calculateTotal()}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="footer">
            <h2>Terms and Conditions</h2>
            <p>UltraVux Digital Agency Company issues (No refund) but we tend to buyer revision until buyer satisfaction.</p>
          </div>
        </div>
      </body>
    </html>
  `;

    // Write the content to the new window and print it
    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.print();
  };

  const companyInfo =[
    {Companyname:"Ultravux", sellerName:"Sumit", companyAddress:"New Delhi, India", phoneNumber:"+919315269100", companymail:"contactultravux@gmail.com",}
  ]

  // Sample data
  // const invoicesData = [
  //   {
  //     invoiceNumber: 'INV001',
  //     clientName: 'John Doe',
  //     service: 'Web Development',
  //     budget: '$1000',
  //     paymentStatus: 'Paid',
  //     phoneNumber: '123-456-7890',
  //     email: 'john.doe@example.com',
  //     address: '123 Main St, Cityville',
  //     invoiceDate: '2022-02-21',
  //     paymentDue:'100$',
  //   },
  //   {
  //     invoiceNumber: 'INV002',
  //     clientName: 'Mike Hery',
  //     service: 'Web Development',
  //     budget: '$8000',
  //     paymentStatus: 'Not Paid',
  //     phoneNumber: '987-654-3210',
  //     email: 'mike.hery@example.com',
  //     address: '456 Oak St, Townsville',
  //     invoiceDate: '2022-02-22',
  //     paymentDue:'$550',

  //   },
  //   // Add more rows as needed
  // ];
const [invoicesData,setinvoiceData]=useState([]);
useEffect(() => {
 

  
  fetchInvoices();
}, []); 
const fetchInvoices = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/invoices_api.php`);
    const data = response.data;

   console.log(data);
    setinvoiceData(data || []);
  } catch (error) {
    console.error('Error fetching tasks:', error);
  }
};
const updatestatus = async (taskId, updatedTask) => {
  console.log(taskId);
  console.log(updatedTask);
  try {
    await axios.put(`${BASE_URL}/invoices_api.php/?id=${taskId}`, updatedTask);
    fetchInvoices();
  } catch (error) {
    console.error(`Error updating task ${taskId}:`, error);
  }
};
  // services
  const itemsData = [
    {
      invoiceNumber: 'INV001',
      item1: 'Web Design',
      quantity1: 2,
      pricePerUnit1: 500,
      amount1: 1000,
      item2: 'Hosting',
      quantity2: 1,
      pricePerUnit2: 200,
      amount2: 200,
    },
    {
      invoiceNumber: 'INV002',
      item1: 'Custom Development',
      quantity1: 1,
      pricePerUnit1: 4000,
      amount1: 4000,
      item2: 'Maintenance',
      quantity2: 2,
      pricePerUnit2: 75,
      amount2: 150,
    },
    // Add more rows as needed
  ];
// Calculate Subtotal, Tax, Fees, and Total
const calculateSubtotal = () => {
  return itemsData.reduce((acc, item) => {
    return (
      acc + (item.amount1 || 0) + (item.amount2 || 0) // Add more items if necessary
    );
  }, 0);
};

const calculateTax = () => {
  // Example: Tax is 10% of the Subtotal
  return calculateSubtotal() * 0;
};

const calculateFees = () => {
  // Example: Fees are a fixed amount
  return 0;
};

const calculateTotal = () => {
  return calculateSubtotal() + calculateTax() + calculateFees();
};
const handleFullscreen = (file_path) => {
  console.log(`{${BASE_URL}/${file_path}}`);
  const link = document.createElement('a');
  link.href = `${BASE_URL}/${file_path}`; // Assuming invoices is an array and taking the first item
  link.target = '_blank'; 
  link.click();
};
  
  return (
    <>
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Invoice Id</th>
              <th>Client Name</th>
              <th>Payment Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {invoicesData.map((rowData, index) => (
              <tr key={index}>
                <td>{rowData.id}</td>
                <td>{rowData.name}</td>
                <td>{rowData.completed === "0" ? "Un-Paid" : "Paid"}</td>

                <td>
                  <Button variant="primary" onClick={() =>handleFullscreen(rowData.file_path)}>
                    <BsFillEyeFill />
                  </Button>{' '}
                  <Button variant="primary" onClick={() =>updatestatus(rowData.id,{
                    completed:rowData.completed  === "1"? false: true
                    })}>
                   Change Payment Status
                  </Button>
                  {/* Add additional buttons as needed */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>


      </div>
      <div className='chart-row'>
        <div className='chart1'> <Visitors/></div>
        <div className='chart2'>  <SalesChart/></div>

      </div>
    
 
    </>
  );
};

export default Reports;
