import React, { useState ,useEffect} from "react";
import BASE_URL from "./constant/constant";
import axios from "axios";
import { Modal, Button, Form, Dropdown, Row, Col } from 'react-bootstrap';


const EditForm = ({ user, onSaveEdit, onClose }) => {
  const [editedUser, setEditedUser] = useState({ ...user });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSave = () => {
    
    //console.log(editedUser.id);
     // Save or process the user data as needed
     const userDatas = {
      name:editedUser.name,
      ///email:email,
      phone:editedUser.phone,
      country:editedUser.country,
      // profile_image: profile,
    };
    //console.log(userDatas);

const formData = new FormData();

// Append the file to the FormData object
// formData.append('profile_image', newProfileImage);

// Append other user data to the FormData object
formData.append('name', editedUser.name);
//   formData.append('email', email);
formData.append('phone', editedUser.phone);
formData.append('country', editedUser.country);

// Configure the Axios request
const config = {
method: 'post',
url: `${BASE_URL}/user_api.php/?id=${editedUser.id}&_method=PATCH`,
headers: {
'Content-Type': 'multipart/form-data',
},
data: formData,
};
axios(config)
    .then((response) => {
      //console.log(JSON.stringify(response.data));
      //setNewProfileImage(null);
      // saveUserData(userDatas);
      alert("User Updated Sucessfully");
    })
    .catch((error) => {
      //console.log(error);
    });
onSaveEdit(editedUser);

// // Make the Axios request
// axios(config)
// .then((response) => {
// //console.log(JSON.stringify(response.data));
// //setNewProfileImage(null);
// saveUserData(userDatas);
// alert("User Updated Sucessfully");
// })
// .catch((error) => {
// //console.log(error);
// });
  };

  return (
    <>
      <form>
        <div className="mb-3">
          <label htmlFor="editName" className="form-label">
            Name
          </label>
          <input
            type="text"
            id="editName"
            className="form-control"
            name="name"
            value={editedUser.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="editCountry" className="form-label">
            Country
          </label>
          <input
            type="text"
            id="editCountry"
            className="form-control"
            name="country"
            value={editedUser.country}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="editMobileNumber" className="form-label">
            Mobile Number
          </label>
          <input
            type="text"
            id="editMobileNumber"
            className="form-control"
            name="mobileNumber"
            value={editedUser.phone}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="editEmail" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="editEmail"
            className="form-control"
            name="email"
            value={editedUser.email}
            onChange={handleInputChange}
            required
            readOnly
          />
        </div>

        <div className="mb-3">
          <label htmlFor="editJoinedDate" className="form-label">
            Joined Date
          </label>
          <input
            type="date"
            id="editJoinedDate"
            className="form-control"
            name="joinedDate"
            value={editedUser.joinedDate}
            onChange={handleInputChange}
            required
          />
        </div>
      </form>

      <Button variant="primary" onClick={handleSave}>
        Save Changes
      </Button>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
    </>
  );
};

const Users = () => {
  // const initialData = [
  //   { name: 'John Doe', country: 'USA', mobileNumber: '1234567890', email: 'john@example.com', joinedDate: '2022-01-01' },
  //   { name: 'Alice Smith', country: 'Canada', mobileNumber: '9876543210', email: 'alice@example.com', joinedDate: '2022-02-15' },
  //   { name: 'John Doe', country: 'USA', mobileNumber: '1234567890', email: 'john@example.com', joinedDate: '2023-04-05' },
  //   { name: 'Alice Smith', country: 'Canada', mobileNumber: '9876543210', email: 'alice@example.com', joinedDate: '2024-01-15' },
  //   // Add more data as needed
  // ];
  const [initialData, setOrdersData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/user_api.php`)
      .then(response => {
        const data = response.data;
              setOrdersData(data || []);
              setTableData(data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      // const apiUrlStarted = `${BASE_URL}/user_api.php`;
      
  
      // // const fetchOrdersData = async (apiUrl, setDataFunction) => {
      //     try {
      //          axios.get(`${BASE_URL}/user_api.php`);
      //         const data = response.data;

      //         //console.log(data);
      //         setDataFunction(data || []);
      //     } catch (error) {
      //         console.error('Error fetching orders data:', error);
      //     }
      // // };
  
      // fetchOrdersData(apiUrlStarted, setOrdersData);
     
  }, []);
  const [tableData, setTableData] = useState(initialData);
  const [sortOption, setSortOption] = useState('oldest');
  const [filterOption, setFilterOption] = useState('All');
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSort = (option) => {
    setSortOption(option);

    let sortedData = [...tableData];

    switch (option) {
      case 'latest':
        sortedData.sort((a, b) => new Date(b.joinedDate) - new Date(a.joinedDate));
        break;
      case 'oldest':
        sortedData.sort((a, b) => new Date(a.joinedDate) - new Date(b.joinedDate));
        break;
      // Add more cases as needed
      default:
        break;
    }

    setTableData(sortedData);
  };
  const handleFilter = (option) => {
    setFilterOption(option);
  
    let filteredData = [...initialData];
  
    if (option !== 'all' && option !== 'All') {
      const today = new Date(); // Get today's date
      const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  
      filteredData = filteredData.filter(user => {
        const userJoinedDate = new Date(user.joinedDate);
        const startOfUserJoinedDate = new Date(userJoinedDate.getFullYear(), userJoinedDate.getMonth(), userJoinedDate.getDate());
  
        switch (option) {
          case 'today':
            return startOfUserJoinedDate.getTime() === startOfToday.getTime();
          case 'yesterday':
            const yesterday = new Date(startOfToday);
            yesterday.setDate(yesterday.getDate() - 1);
            return startOfUserJoinedDate.getTime() === yesterday.getTime();
          case 'last 7 days':
            const sevenDaysAgo = new Date(startOfToday);
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
            return userJoinedDate >= sevenDaysAgo;
          // Add more cases as needed
          default:
            return true;
        }
      });
    }
  
    setTableData(filteredData);
  };
  
  
  // const handleFilter = (option) => {
  //   setFilterOption(option);
  
  //   let filteredData = [...initialData];
  
  //   if (option !== 'all' && option !== 'All') {
  //     filteredData = filteredData.filter(user => {
  //       const today = new Date().toISOString().split('T')[0];
  //       switch (option) {
  //         case 'today':
  //           return user.joinedDate === today;
  //         case 'yesterday':
  //           const yesterday = new Date();
  //           yesterday.setDate(yesterday.getDate() - 1);
  //           return user.joinedDate === yesterday.toISOString().split('T')[0];
  //         case 'last 7 days':
  //           const sevenDaysAgo = new Date();
  //           sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  //           const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split('T')[0];
  //           return user.joinedDate >= formattedSevenDaysAgo;
  //         // Add more cases as needed
  //         default:
  //           return true;
  //       }
  //     });
  //   }
  
  //   setTableData(filteredData);
  // };
  
  // const handleFilter = (option) => {
  //   setFilterOption(option);

  //   let filteredData = [...initialData];

  //   if (option !== 'all' || option !== 'All') {
  //     filteredData = filteredData.filter(user => {
  //       const today = new Date().toISOString().split('T')[0];
  //       // const today = new Date().toISOString().split('T')[0]; // Get today's date
  //       switch (option) {
  //         case 'today':
  //           return user.joinedDate === today;
  //         case 'yesterday':
  //           const tomorrow = new Date();
  //           tomorrow.setDate(tomorrow.getDate() - 1);
  //           return user.joinedDate === tomorrow.toISOString().split('T')[0];
  //           case 'last 7 days':
  //           const sevenDaysAgo = new Date();
  //           sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  //           const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split('T')[0];
  //           return user.joinedDate >= formattedSevenDaysAgo;
  //         // Add more cases as needed
  //         default:
  //           return true;
  //       }
  //     });
  //   }

  //   setTableData(filteredData);
  // };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedUser(null);
  };

  const handleSaveEdit = (editedUser) => {
    // Implement logic to save the edited user data
    //console.log("Edited user:", editedUser);
    // Update the tableData with the edited user
    const updatedData = tableData.map(user => (user === selectedUser ? editedUser : user));
    setTableData(updatedData);
    // Close the modal
    handleCloseEditModal();
  };

  // country Filter
  // Calculate unique countries from the initial data
  const uniqueCountries = Array.from(new Set(initialData.map((user) => user.country)));

  const [countryFilter, setCountryFilter] = useState('all');

  const handleCountryFilter = (country) => {
    setCountryFilter(country);

    let filteredData = [...initialData];

    if (country !== 'all') {
      filteredData = filteredData.filter((user) => user.country.toLowerCase() === country.toLowerCase());
    }

    setTableData(filteredData);
  };
 const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredTableData = tableData.filter(
    (row) => row.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="sortDropdown">
            <Form.Label>Sort by:</Form.Label>
            <Form.Select value={sortOption} onChange={(e) => handleSort(e.target.value)}>
              <option value="latest">Latest</option>
              <option value="oldest">Oldest</option>
              {/* Add more options as needed */}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="filterDropdown">
            <Form.Label>Filter by:</Form.Label>
            <Dropdown onSelect={handleFilter}>
              <Dropdown.Toggle style={{width:"100%",textAlign:"left"}} variant="outline-secondary" id="filterDropdownToggle">
                {filterOption === 'all' ? 'All' : `${filterOption.charAt(0).toUpperCase() + filterOption.slice(1)}`}
              </Dropdown.Toggle>
              <Dropdown.Menu >
                <Dropdown.Item eventKey="all">All</Dropdown.Item>
                <Dropdown.Item eventKey="today">Today</Dropdown.Item>
                <Dropdown.Item eventKey="yesterday">Yesterday</Dropdown.Item>
                <Dropdown.Item eventKey="last 7 day">Last 7 days</Dropdown.Item>
                {/* Add more options as needed */}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="filterByCountryDropdown">
            <Form.Label>Filter by Country:</Form.Label>
            <Dropdown onSelect={handleCountryFilter}>
              <Dropdown.Toggle style={{width:"100%",textAlign:"left"}} variant="outline-secondary" id="filterByCountryDropdownToggle">
                {countryFilter === 'all' ? 'All Countries' : `Filter: ${countryFilter}`}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="all">All Countries</Dropdown.Item>
                {uniqueCountries.map((country) => (
                  <Dropdown.Item key={country} eventKey={country}>
                    {country}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="searchInput">
            <Form.Label>Search by Client Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter client name"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form.Group>
        </Col>
      </Row>

      <div className="scrollable-table-container" style={{ maxHeight: '1100px', overflowY: 'auto' }}>
      <table className="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Country</th>
            <th>Gender</th>
            <th>Mobile Number</th>
            <th>Email</th>
            <th>Phone Verified</th>
            <th>Joined Date</th>
           
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {filteredTableData.map((row, index) => (
            <tr key={row.id}>
              <td>{row.name}</td>
              <td>{row.country}</td>
              <td>{row.gender===null ?"Not Listed":row.gender}</td>
              <td>{row.phone}</td>
              <td>{row.email}</td>
              <td>{row.verified === "0" ? 'false' : 'true'}</td>
              <td>{new Date(row.joinedDate).toLocaleString('en-US', { hour12: true })}</td>

              <td>
                <button className="btn btn-primary" onClick={() => handleEdit(row)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditForm user={selectedUser} onSaveEdit={handleSaveEdit} onClose={handleCloseEditModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Users;
