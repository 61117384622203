import React from 'react';
import { Modal } from 'react-bootstrap';

const ProgressDialog = ({ show, onHide, progress }) => {
  return (
    <Modal show={show} size="sm" onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Uploading Data...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {progress}%
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProgressDialog;
