import React, { useState, useEffect } from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { BsArrowUp } from 'react-icons/bs';
import { useSpring, animated } from "react-spring";
import BASE_URL from './constant/constant';
import axios from 'axios';




const SalesOrder = () => {
  const [data,setData] = useState([]);
useEffect(() => {
  
  const apiUrlStarted = `${BASE_URL}/api_orders.php?analytics=yes`;
 

  const fetchOrdersData = async (apiUrl, setDataFunction) => {
      try {
          const response = await axios.get(apiUrl);
          const data = response.data;

          //console.log(data);
          // const total = 0;
          setTotalAmount(data.totalSale);
          // const newBuyers = 0;
          // const returningBuyers = 0;
        
          setNewBuyersCount(data.new);
          setReturningBuyersCount(data.recurring);
          // setData(data || []);
      } catch (error) {
          console.error('Error fetching orders data:', error);
      }
  };

  fetchOrdersData(apiUrlStarted);
  
  
}, []);
useEffect(() => {
  
  const apiUrlStarted = `${BASE_URL}/api_orders.php`;
 

  const fetchOrdersData = async (apiUrl, setDataFunction) => {
      try {
          const response = await axios.get(apiUrl);
          const data = response.data;

          //console.log(data);
          setData(data || []);
      } catch (error) {
          console.error('Error fetching orders data:', error);
      }
  };

  fetchOrdersData(apiUrlStarted);
  
  
}, []);
  const [statusFilter, setStatusFilter] = useState('');
  const [timeframeFilter, setTimeframeFilter] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [newBuyersCount, setNewBuyersCount] = useState(0);
  const [returningBuyersCount, setReturningBuyersCount] = useState(0);
  const [barChartData, setBarChartData] = useState({});
  const [lineChartData, setLineChartData] = useState({});
  const [showCharts, setShowCharts] = useState(false);


  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleTimeframeFilterChange = (e) => {
    setTimeframeFilter(e.target.value);
  };
  const handleToggleCharts = () => {
    setShowCharts(!showCharts);
  };

  const isWithinTimeframe = (purchaseDate, days) => {
    const currentDate = new Date();
    const purchaseDateObj = new Date(purchaseDate);
    const differenceInDays = Math.floor((currentDate - purchaseDateObj) / (1000 * 60 * 60 * 24));
    
    if (days === 1) {
      // Check if the purchaseDate is the same as the current date (today)
      return (
        currentDate.getDate() === purchaseDateObj.getDate() &&
        currentDate.getMonth() === purchaseDateObj.getMonth() &&
        currentDate.getFullYear() === purchaseDateObj.getFullYear()
      );
    }
    
    return differenceInDays <= days;
  };


  const filteredData = data
    .filter((item) => !statusFilter || item.recurring.toLowerCase() === statusFilter.toLowerCase())
    .filter((item) => {
      if (timeframeFilter === 'Daily') {
        return isWithinTimeframe(item.starting_date, 1); // 1 day
      } else if (timeframeFilter === 'Weekly') {
        return isWithinTimeframe(item.starting_date, 7); // 7 days
      } else if (timeframeFilter === 'Monthly') {
        return isWithinTimeframe(item.starting_date, 30); // 30 days (approximate)
      }
      return true; // No timeframe filter selected
    });

  // Calculate total amount for filtered data
  const calculateTotalAmount = () => {
    // const total = 0;
    // setTotalAmount(total);
    // const newBuyers = 0;
    // const returningBuyers = 0;
  
    // setNewBuyersCount(newBuyers);
    // setReturningBuyersCount(returningBuyers);
      // Bar Chart Data
  const barData = {
    labels: ['New Buyers', 'Returning Buyers'],
    datasets: [
      {
        label: 'Number of Buyers',
        data: [newBuyersCount, returningBuyersCount],
        backgroundColor: ['#36A2EB', '#FFCE56'],
      },
    ],
  };
  setBarChartData(barData);

      // Line Chart Data
  const lineData = {
    labels: filteredData.map((item) => item.starting_date),
    datasets: [
      {
        label: 'Total Sales',
        data: filteredData.map((item) => item.price),
        borderColor: '#FFCE56',
        fill: false,
      },
    ],
  };
  setLineChartData(lineData);
  };

  // Recalculate total amount when filters change
 useEffect(() => {
    calculateTotalAmount();
  }, [filteredData]);
  const chartSpring = useSpring({
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
     config: { delay: 0, duration: 0 },
  });
//   charts
 // Bar Chart Data
 const barData = {
    labels: ['New Buyers', 'Returning Buyers'],
    datasets: [
      {
        label: 'Number of Buyers',
        data: [newBuyersCount, returningBuyersCount],
        backgroundColor: ['#36A2EB', '#FFCE56'],
      },
    ],
  };

   // Doughnut Chart Data
   const serviceSalesData = filteredData.reduce((acc, item) => {
    acc[item.service_name] = (acc[item.service_name] || 0) + item.price;
    return acc;
  }, {});

  const doughnutData = {
    labels: Object.keys(serviceSalesData),
    datasets: [
      {
        data: Object.values(serviceSalesData),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#2ecc71'],
      },
    ],
  };
 
  return (
    <div className="container mt-4">
     <ul className="box-info">
     <li>
          <i className='bx bxs-group' ></i>
          <animated.span style={chartSpring} className="text">
          <p style={{fontFamily:"'Poppins'"}}>New Buyer</p>
            <h3 style={{fontFamily:"'Poppins'"}}>{newBuyersCount}<BsArrowUp color="green" /></h3> 
          </animated.span>
        </li>
        <li>
          <i className='bx bxs-group' ></i>
          <animated.span style={chartSpring} className="text">
          <p style={{fontFamily:"'Poppins'"}}>Returning Buyers</p>
            <h3 style={{fontFamily:"'Poppins'"}}>{returningBuyersCount}<BsArrowUp color="green" /></h3> 
          </animated.span>
        </li>
        <li>
        <i class='bx bx-money' ></i>
          <animated.span style={chartSpring} className="text">
          <p style={{fontFamily:"'Poppins'"}}>Total Sales</p>
            <h3 style={{fontFamily:"'Poppins'"}}>${totalAmount}<BsArrowUp color="green" /></h3> 
          </animated.span>
        </li>
     </ul>
    <div className='row'>
        <div className='col-6'>
        <label>Status Filter:</label>
      <select className="form-control" style={{background: "#80897566"}} value={statusFilter} onChange={handleStatusFilterChange}> 
        <option value="">All </option>
        <option value="Returning">Returning</option>
        <option value="New">New</option>
      </select>
        </div>
        <div className='col-6'>
        <label className="">Timeframe Filter:</label>
      <select className="form-control"  style={{background: "#80897566"}} value={timeframeFilter} onChange={handleTimeframeFilterChange}>
        <option value="">All</option>
        <option value="Daily">Daily</option>
        <option value="Weekly">Weekly</option>
        <option value="Monthly">Monthly</option>
      </select>

        </div>
    </div>
    
      <div className="table-container mt-3 scrollable-table-container" style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <table className="table table-bordered table-responsive">
          <thead className="thead-dark">
            <tr >
              <th style={{background:"rgb(255 202 0 / 58%)",}}>Client Name</th>
              <th style={{background:"rgb(255 202 0 / 58%)",}}>Country</th>
              <th style={{background:"rgb(255 202 0 / 58%)",}}>Status</th>
              <th style={{background:"rgb(255 202 0 / 58%)",}}>Amount</th>
              <th style={{background:"rgb(255 202 0 / 58%)",}}>Service</th>
              <th style={{background:"rgb(255 202 0 / 58%)",}}>Project Starting Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                <td>{item.user_name}</td>
                <td>{item.user_country}</td>
                <td>{item.recurring}</td>
                <td>{item.price}</td>
                <td>{item.service_name}</td>
                <td>{item.starting_date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="container mt-4">
      {/* ... (previous JSX) ... */}
      <button className="btn btn-success mt-3" onClick={handleToggleCharts}>
        {showCharts ? "Hide Charts" : "Generate Reports"}
      </button>
      {showCharts && (
        <div className="charts-container">
          <div className="row">
            <div className="col-6">
              <div className="bar-chart-container">
                <Bar data={barData} />
              </div>
            </div>
            <div className="col-6">
              {/* Doughnut Chart */}
              <div className="doughnut-chart-container">
                <Doughnut data={doughnutData} />
              </div>
            </div>
          </div>
          {/* Conditional rendering of Line chart */}
          {timeframeFilter === 'Weekly' || timeframeFilter === 'Monthly' ? (
            <div className="line-chart-container mt-4">
              <Line data={lineChartData} />
            </div>
          ) : null}
        </div>
      )}
    </div>
    </div>
  );
};

export default SalesOrder;
