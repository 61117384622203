import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const Visitors = () => {
  const chartRef = useRef(null);

  // Sample data for daily, weekly, and monthly visitors
  const data = {
    labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
    datasets: [
      {
        label: 'Daily Visitors',
        data: [140, 250, 120, 180, 130, 50, 100],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 2,
      },
      {
        label: 'Weekly Visitors',
        data: [300, 500, 400, 600],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 2,
      },
      {
        label: 'Monthly Visitors',
        data: [800, 1200, 1000],
        backgroundColor: 'rgba(255, 205, 86, 0.2)',
        borderColor: 'rgba(255, 205, 86, 1)',
        borderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 2,
      },
    ],
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
        
      },
      
    };

    const myChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });

    return () => {
      myChart.destroy();
    };
  }, []);

  return (
    <>
      <br />
      <div className='visitor-chart'>
        <h1>Analytics</h1>
        <canvas ref={chartRef} />
      </div>
    </>
  );
};

export default Visitors;
