import  { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the style
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import BASE_URL from "./constant/constant";
import { format } from 'date-fns';
import { BsPencil, BsCloudUpload, BsTrash  } from 'react-icons/bs'; // Import the pencil icon

function MyVerticallyCenteredModal(props) {
  const [employeePicture, setEmployeePicture] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeDesignation, setEmployeeDesignation] = useState("");

  useEffect(() => {
    
    // If it's an edit operation, update the state with existing data
    if (props.isEditing) {
      setEmployeePicture(props.editData.picture || null);
      setEmployeeName(props.editData.name || "");
      setEmployeeDesignation(props.editData.designation || "");
      //console.log("edit :");
    } else {
      //console.log("new :");
      // If it's a new member, reset the state
      setEmployeePicture(null);
      setEmployeeName("");
      setEmployeeDesignation("");
    }
  }, [props.isEditing, props.editData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setEmployeePicture(URL.createObjectURL(file));
    }
  };
  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    const blob = new Blob([u8arr], { type: mime });
    const file = new File([blob], filename, { type: mime });
    
    return file;
  }
  async function convertBlobUrlToFile(blobUrl, filename) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
  
    // Create a File from the Blob
    const file = new File([blob], filename, { type: blob.type });
  
    return file;
  }
  const handleSave = async () => {
    try {
      if (props.editData === null) {
        const timestamp = format(new Date(), 'yyyyMMddHHmmss');
        const filename = `teams-${timestamp}.png`;
        const imageFile = await convertBlobUrlToFile(employeePicture, filename);
  
        const formData = new FormData();
  
        formData.append('image', imageFile);
        formData.append('name', employeeName);
        formData.append('designation', employeeDesignation);
  
        const config = {
          method: 'post',
          url: `${BASE_URL}/teams_api.php`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        };
  
        const response = await axios(config);
        //console.log(JSON.stringify(response.data));
        alert('Team Member added successfully');
      } else {
        const formData = new FormData();
        const timestamp = format(new Date(), 'yyyyMMddHHmmss');
        const filename = `teams-${timestamp}.png`;
        const imageFile = await convertBlobUrlToFile(employeePicture, filename);
  
        formData.append('image', imageFile);
        formData.append('name', employeeName);
        formData.append('designation', employeeDesignation);
  
        const config = {
          method: 'post',
          url: `${BASE_URL}/teams_api.php/?id=${props.editData.id}&_method=PATCH`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        };
  
        const response = await axios(config);
        //console.log(JSON.stringify(response.data));
        alert('Team Member updated successfully');
      }
  
      props.onHide();
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form. Please check the console for details.');
    }
  };
  
  
  return (
    
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.isEditing ? "Edit Employee Details" : "Add a New Team Member"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center" }}>

          
          {employeePicture ? (
  // If an image is selected, display the image preview
  <img
    src={employeePicture}
    alt="Employee Preview"
    style={{
      maxWidth: "100%",
      maxHeight: "150px",
      borderRadius: "50%",
      margin: "auto",
      display: "block",
    }}
  />
) : (
  // If no image is selected, display a placeholder avatar
  <div
    style={{
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      backgroundColor: "#e0e0e0",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <span style={{ fontSize: "24px", color: "#757575" }}>
      Picture
    </span>
   
  </div>
)}
<label htmlFor="upload" style={{ cursor: "pointer" }}>
            <BsCloudUpload size={32} />
            &nbsp; Upload Employee Picture 1121 × 806
          </label>
          <input
            id="upload"
            type="file"
            accept="image/*"
            className="form-control"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />

        </div>
        <br />
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          value={employeeName}
          onChange={(e) => setEmployeeName(e.target.value)}
        />
        <br />
        <label>Designation</label>
        <input
          type="text"
          className="form-control"
          value={employeeDesignation}
          onChange={(e) => setEmployeeDesignation(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSave}>
          Save
        </Button>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
    
  );
}
const Team = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalShow, setModalShow] = useState(false);
 // const [selectedBlog, setSelectedBlog] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [editData, setEditData] = useState(null);
  const[founder,setFounder]=useState([]);
  const [founderName, setFounderName] = useState("");
  const [founderDetails, setFounderDetails] = useState("");
  const [founderSocial, setFounderSocial] = useState("");
  const [founderLinkedIn, setFounderLinkedIn] = useState("");
  const [content, setContent] = useState("");
  useEffect(() => {
    axios
      .get(`${BASE_URL}/founder_api.php`)
      .then(response => {
        const data = response.data;

              //console.log(data);
              if(data!=null){
                // selectedImage(data.founder_image);
setFounderName(data.founder_name);
setFounderDetails(data.founder_description);
setFounderSocial(data.founder_social);
setFounderLinkedIn(data.founder_linkedin);
setSelectedImage(data.founder_image);
              }
              // setFounder(data || []);
              // selectedImage(founder.founder_image);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });},[]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/teams_api.php`)
      .then(response => {
        const data = response.data;

              //console.log(data);
              setTeamMembers(data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });},[]);

      function dataURLtoFile(dataUrl, filename) {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
      
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
      
        const blob = new Blob([u8arr], { type: mime });
        const file = new File([blob], filename, { type: mime });
        
        return file;
      }
  const handleupdate = async () => {
    // //console.log(selectedImage);
    try {
      const formData = new FormData();
      if (founderName) {
        formData.append('founder_name',founderName);
      }
  
      if (founderDetails) {
        formData.append('founder_description', founderDetails);
      }
  
      if (founderSocial) {
        formData.append('founder_social', founderSocial);
      }
  
      if (founderLinkedIn) {
        formData.append('founder_linkedin',founderLinkedIn);
      }
      
      if (selectedImage) {
        if (!(selectedImage.startsWith('http://') || selectedImage.startsWith('https://'))) {
          const timestamp = format(new Date(), 'yyyyMMddHHmmss');
          const filename = `blog${timestamp}.png`;
          const imageFile = dataURLtoFile(selectedImage, filename);
          //console.log(imageFile);
          formData.append('founder_image', imageFile);
        }
        // formData.append('founder_image',selectedImage);
      }
  
      // If you have a file input field for the image and a file is selected
      // const imageFileInput = document.getElementById('imageInput');
      // if (imageFileInput.files && imageFileInput.files.length > 0) {
      //   formData.append('founder_image', imageFileInput.files[0]);
      // }
  
      const config = {
        method: 'post',
        url: `${BASE_URL}/founder_api.php`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };
  
      const response = await axios(config);
      //console.log(JSON.stringify(response.data));
  
      alert('Founder Data updated successfully');
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form. Please check the console for details.');
    }
  };
  
  const handleChange = (value) => {
    setContent(value);
  };
  const handleAddNewMember = () => {
   
    setIsEditing(false);
    setEditData(null);
    setModalShow(true);
    
  };

  const handleEditMember = (member) => {
   
    setIsEditing(true);
    setEditData(member);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
    setIsEditing(false);
    setEditData(null);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const handleRemoveClick = (id) => {
    axios.delete(`${BASE_URL}/teams_api.php/?id=${id}`)
    .then(response => {
      // Handle success, e.g., update the state or show a success message
      //console.log('Team Member Deleted Successfully:', response.data);
      alert('Team Member Deleted Successfully');
      // Perform any additional actions you need
    })
    .catch(error => {
      // Handle error, e.g., show an error message
      console.error('Error deleting blog:', error);
      // Perform any additional error handling or actions you need
    });
    setTeamMembers((prevMembers) => prevMembers.filter(member => member.id !== id));
  };
  return (
    <>
      <h1>Founder Info</h1>
      <div className="">
      {/* Input for image upload */}
      <label htmlFor="imageInput" style={{ display: 'block', cursor: 'pointer' }}>
        <BsCloudUpload style={{ marginRight: '8px' }} />
        Upload Image
      </label>
      <p>Image Size must be 1800x1140px</p>
      <input
        id="imageInput"
        type="file"
        accept="image/*"
        className="form-control"
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />

      {/* Preview of the uploaded image */}
    
        <div>
          <img src={selectedImage} alt="Uploaded Preview" style={{ maxWidth: '100px' }} />
        </div>
    
    </div>
    <br></br>
<label>Founder Name</label>
<input
   type="text"
   className="form-control"
   value={founderName}
   onChange={(e) => setFounderName(e.target.value)}
/>
<br></br>
<h2>Description</h2>
<input
   type="text"
   className="form-control"
   value={founderDetails}
   onChange={(e) => setFounderDetails(e.target.value)}
/>
<br></br>
<label>Instagram</label>
<input
   type="text"
   className="form-control"
   value={founderSocial}
   onChange={(e) => setFounderSocial(e.target.value)}
/>
<label>LinkedIn</label>
<input
   type="text"
   className="form-control"
   value={founderLinkedIn}
   onChange={(e) => setFounderLinkedIn(e.target.value)}
/>
          <Button variant="success" onClick={()=>{handleupdate();}}>Save</Button>
      <br></br>
      <br></br>
      <div className="row">
      <div className="col-10">
      <h1>Team Info</h1>
      </div>
      <div className="col-2">
      <Button variant="primary" onClick={() => { 
        handleEditMember();
        handleCloseModal();
         handleAddNewMember(); }}>
        Add New Member  
      </Button>
      </div>
      </div>
      <div className="table-data">
        <div className="order">
          <div className="head">
            <h3>Team Members</h3>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Designation</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {teamMembers.map((member) => (
                <tr key={member.id}>
                  <td>
                    <img src={member.image} alt="user" />
                    <p>{member.name}</p>
                  </td>
                  <td>{member.designation}</td>
                  <td>
                  <Button variant="success" onClick={() => {
handleEditMember(member);
                    // setModalShow(true);
                  
                  }}>
                      <BsPencil />
                    </Button>
                    <button
                      className="btn btn-danger ml-4"
                      style={{ marginLeft: "8px" }}
                      onClick={() => handleRemoveClick(member.id)}
                    >
                      <BsTrash className="mr-2" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    
      <MyVerticallyCenteredModal
   
        show={modalShow}
        onHide={() => setModalShow(false)}
        isEditing={isEditing??false}
        editData={editData??null}
      />
    </>
  );
};

export default Team;
