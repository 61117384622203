import React, { useState,useEffect ,useRef} from 'react';
import { BsChatDots, BsPersonFill } from 'react-icons/bs';
import { firestore,storage } from './firebase';
import {  addDoc, serverTimestamp , onSnapshot, collection, query, limit, orderBy, getDocs} from "firebase/firestore";
import { Button, Row, Col, Container, ListGroup, Card, Modal } from 'react-bootstrap';
import "./App.css";
import {  FaFile } from 'react-icons/fa';
import axios from 'axios';
import BASE_URL from './constant/constant';
import { getStorage, ref, uploadString, getDownloadURL ,uploadBytesResumable } from 'firebase/storage';
import { IoDocuments } from "react-icons/io5";
import { FaImages } from "react-icons/fa";

const Messages = () => {
  const [selectedUser, setSelectedUser] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState('');
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
const[ordersHistory,setOrdersData]=useState([]);

  const [imagePreview, setImagePreview] = useState(null);
  const [document, setDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);

const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    //console.log(file);
};
  const handleModalShow = () => {
    setShowModal(true);
  };
  
  const handleModalClose = () => {
    setShowModal(false);
  };

  // const usersData = [
  //   {
  //     id: 1,
  //     name: 'You',
  //     date: 'Now',
  //     message: 'Hello! How can I help you?',
  //     email: 'you@example.com',
  //     phone: '123-456-7890',
  //     country:"India",
  //     ordersHistory: [],
  //   },
  //   {
  //     id: 2,
  //     name: 'Client',
  //     date: '25 Dec',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.',
  //     email: 'client@example.com',
  //     phone: '987-654-3210',
  //     country:"India",
  //     ordersHistory: ['Website Design', 'Website Development', 'Graphic Design'],
  //   },
  //   {
  //     id: 3,
  //     name: 'Jane Doe',
  //     date: '23 Dec',
  //     message: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  //     email: 'jane@example.com',
  //     phone: '555-123-4567',
  //     country:"Canada",
  //     ordersHistory: [],
  //   },
  //   // Add more user data as needed
  // ];
  const handleClose = () => setShowModal(false);
  const sendMsg = async (e) => {
    e.preventDefault();
    
  
    // if (image) {
    //     //Create a unique image name
    //   const date = new Date().getTime();
    //   const storageRef = ref(storage, `chat_images${+ date}`);

    //   await uploadBytesResumable(storageRef, image).then(() => {
    //     getDownloadURL(storageRef).then(async (downloadURL) => {
    //       try {

    //         addDoc(collection(firestore, 'messages', `${usersData1[selectedUser].fb_id}-Admin`,"chats"), {
    //             text: msg,
    //             createdAt: serverTimestamp(),
    //             uid: "Admin",
    //             // photoURL: downloadURL,
    //             image: downloadURL,
    //           });
            
    //       } catch (err) {
    //         //console.log(err);
           
    //       }
    //     });
    //   });
       
      
    
  
     

    // } 
    if (image || document) {
      let file = image || document;
      let fileName = file.name;
      let fileRef = ref(storage, fileName);
      
      await uploadBytesResumable(fileRef, file).then(async (snapshot) => {
        const downloadURL = await getDownloadURL(fileRef);
        try {
          addDoc(collection(firestore, 'messages', `${usersData1[selectedUser].fb_id}-Admin`,"chats"), {
                        text: msg,
                        createdAt: serverTimestamp(),
                        uid: "Admin",
            image: image ? downloadURL : null,
            document: document ? { name: fileName, url: downloadURL } : null
          });
          // addDataToFirestore(`${name} Sent ${image ? 'an Image' : 'a Document'}`,);
        } catch (err) {
          console.log(err);
        }
      });
    } 
    else {
      const trimmedMsg = msg.trim();

      // Check if the trimmed message is empty
      if (!trimmedMsg) {
          // If the trimmed message is empty, return early
          return;
      }
       else{
        await addDoc(collection(firestore, 'messages', `${usersData1[selectedUser].fb_id}-Admin`,"chats"), {
          text: msg,
          createdAt: serverTimestamp(),
          uid: "Admin"
        });
        // addDataToFirestore(`${name} Sent a New Message.`);
      }
      // await addDoc(collection(firestore, 'messages', `${usersData1[selectedUser].fb_id}-Admin`,"chats"), {
      //   text: msg,
      //   createdAt: serverTimestamp(),
      //   uid: "Admin",
       
      // });
    }
  
    setMsg('');
    setImage(null);
    setImagePreview(null);
    setDocument(null);
    setDocumentName(null);
  
    // if (scroll) {
    //   scroll.current.scrollIntoView({ behavior: 'smooth' });
    // }
  };
  const [usersData1,setUserData] = useState([]);
  const [items, setItems] = useState([]);
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const [formValues, setFormValues] = useState({
    service: '',
    category: '',
    budget: '',
    orderStartDate: '',
    orderEndDate: '',
    orderDetails: '',
    status:"In Progress"
  });
  const [service,setServices]=useState("");
  const [service1,setServices1]=useState([]);
  
  const [service2,setServices2]=useState([]);
  const [service3,setServices3]=useState([]);
  const [service4,setServices4]=useState([]);
  const [initialData, setsersData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/user_api.php`)
      .then(response => {
        const data = response.data;

              //console.log(data);
              setsersData(data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
     
      
  
      // // const fetchOrdersData = async (apiUrl, setDataFunction) => {
      //     try {
      //          axios.get(`${BASE_URL}/user_api.php`);
      //         const data = response.data;

      //         //console.log(data);
      //         setDataFunction(data || []);
      //     } catch (error) {
      //         console.error('Error fetching orders data:', error);
      //     }
      // // };
  
      // fetchOrdersData(apiUrlStarted, setOrdersData);
     
  }, []);
  
useEffect(() => {
  //console.log(BASE_URL);
  axios.get(`${BASE_URL}/services_api.php?categories_id=1`)
    .then(res => {
      //console.log(res.data);
      setServices1(res.data); // Assuming the response has a services array
    })
    .catch(err => console.log(err));
}, []); 
useEffect(() => {
  //console.log(BASE_URL);
  axios.get(`${BASE_URL}/services_api.php?categories_id=2`)
    .then(res => {
      //console.log(res.data);
      setServices2(res.data); // Assuming the response has a services array
    })
    .catch(err => console.log(err));
}, []); 
useEffect(() => {
  //console.log(BASE_URL);
  axios.get(`${BASE_URL}/services_api.php?categories_id=3`)
    .then(res => {
      //console.log(res.data);
      setServices3(res.data); // Assuming the response has a services array
    })
    .catch(err => console.log(err));
}, []); 
useEffect(() => {
  //console.log(BASE_URL);
  axios.get(`${BASE_URL}/services_api.php?categories_id=4`)
    .then(res => {
      //console.log(res.data);
      setServices4(res.data); // Assuming the response has a services array
    })
    .catch(err => console.log(err));
}, []); 
const onSOptionChangeHandler = (event) => {
  setFormValues({ ...formValues, service_name: event.target.value });
  // setSData(event.target.value);
  // //console.log(
  //     "User Selected Value - ",
  //     event.target.value
  // );
 
};
const [selectedCategory, setSelectedCategory] = useState('all');
useEffect(() => {
  const fetchMessages = async () => {
    try {
      const usersRef = collection(firestore, 'users');
      const usersSnapshot = await getDocs(usersRef);

      const uniqueMessagesData = [];

      await Promise.all(usersSnapshot.docs.map(async (userDoc) => {
        const userUid = userDoc.data().uid;

        const messagesRef = collection(firestore, 'messages', `${userUid}-Admin`, 'chats');
        const messagesSnapshot = await getDocs(messagesRef);

        if (!messagesSnapshot.empty) {
          // Find the latest message based on createdAt
          const latestMessage = messagesSnapshot.docs.reduce((prev, current) => {
            return prev.data().createdAt.toMillis() > current.data().createdAt.toMillis() ? prev : current;
          });

          // Add the latest message to uniqueMessagesData
          uniqueMessagesData.push({
            user: `${userUid}-Admin`,
            ...latestMessage.data()
          });
        }
      }));

      // Sort uniqueMessagesData based on the 'createdAt' property in descending order
      uniqueMessagesData.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());

      // Log the sorted data
      console.log(uniqueMessagesData);

      // Set the data in state
      setItems(uniqueMessagesData); 
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  fetchMessages();
}, []);




  // useEffect(() => {
  //   const fetchMessages = async () => {
  //     try {
  //       const usersRef = collection(firestore, 'users');
  //       const usersSnapshot = await getDocs(usersRef);
  
  //       const allMessagesData = [];
  
  //       await Promise.all(usersSnapshot.docs.map(async (userDoc) => {
  //         const userUid = userDoc.data().uid;
  //         //console.log(allMessagesData)
  
  //         // Check if the user ID exists in the 'messages' collection
  //         const messagesRef = collection(firestore, 'messages', `${userUid}-Admin`, 'chats');
  //         const messagesSnapshot = await getDocs(messagesRef);
  
  //         if (!messagesSnapshot.empty) {
  //           const messagesData = messagesSnapshot.docs.map((doc) => ({
  //             user: `${userUid}-Admin`,
  //           }));
  
  //           allMessagesData.push(...messagesData);
  //         }
  //       }));
  
  //       // Remove duplicate entries based on the 'user' property
  //       const uniqueMessagesData = Array.from(new Set(allMessagesData.map(JSON.stringify))).map(JSON.parse);

  
  //       //console.log(uniqueMessagesData); // Log the fetched data
  //       setItems(uniqueMessagesData); 
  //       // uniqueMessagesData.forEach((item)=>{
          
  //       // })
  //       // Set the data in state
  //     } catch (error) {
  //       console.error('Error fetching messages:', error);
  //     }
  //   };
  
  //   fetchMessages();
  // }, []);

  useEffect(() => {
    const fetchDataForUsers = async () => {
      try {
        // Extract unique user IDs from 'items'
        const userIds = items.map(item => item.user.split('-')[0]);
  
        // Use Axios to make requests for each user
        const axiosRequests = userIds.map(userId => (
          axios.get(`${BASE_URL}/user_api.php/?fb_id=${userId}`)
            .then(response => response.data)  // Assuming your API response is the data you need
            .catch(error => {
              console.error(`Error fetching data for user ${userId}:`, error);
              return null;  // Handle errors gracefully
            })
        ));
  
        // Wait for all Axios requests to complete
        const userDataArray = await Promise.all(axiosRequests);
        const filteredUserDataArray = userDataArray.filter(userData => userData && !userData.message);

        // Now you have an array of data for each user, you can handle it as needed
        setUserData(filteredUserDataArray);
        //console.log(filteredUserDataArray);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    if (items.length > 0) {
      fetchDataForUsers();
    }
  }, [items]);  // Make sure to include 'items' in the dependency array
  
  const [messages, setMessages] = useState([]);
// const userId = auth.currentUser.uid;

useEffect(() => {
  const fetchMessages = async () => {
    try {
      if (usersData1[selectedUser]) {
        const userId = usersData1[selectedUser].fb_id;
        const q = query(
          collection(firestore, 'messages', `${userId}-Admin`, "chats"),
          orderBy("createdAt"),
          limit(100)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let messages = [];
          querySnapshot.forEach((doc) => {
            messages.push({ ...doc.data(), id: doc.id });
          });
          setMessages(messages);
        });

        return () => unsubscribe();
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  fetchMessages();
}, [selectedUser, usersData1]);



const messagesEndRef = useRef(null);

const scrollToBottom = () => {
  messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
};

useEffect(() => {
  scrollToBottom();
}, [messages]);
// useEffect(() => {
//   const fetchDataForUsers = async () => {
//     try {
//       // Your existing code to fetch data for each user

//       // After fetching all data, organize it into a structure that makes it easy to identify the latest message for each user
//       const userDataWithLatestMessage = items.map(item => {
//         const userMessages = messages.filter(message => message.user === `${item.user}`);
//         const latestMessage = userMessages.length > 0 ? userMessages[userMessages.length - 1] : null;
//         return { ...item, latestMessage };
//       });

//       // Sort the users based on the timestamp of their latest message
//       userDataWithLatestMessage.sort((a, b) => {
//         if (!a.latestMessage) return 1;
//         if (!b.latestMessage) return -1;
//         return b.latestMessage.createdAt - a.latestMessage.createdAt;
//       });

//       // Update the state with the sorted user data
//       setUserData(userDataWithLatestMessage);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   if (items.length > 0 && messages.length > 0) {
//     fetchDataForUsers();
//   }
// }, [items, messages]);

const getCountryById = (id) => {


  const matchingCountry = initialData.find(country => country.id === id);

  return matchingCountry ? matchingCountry.country : 'select Client First ';
};
useEffect(() => {
  if (usersData1[selectedUser]) {
    const userId = usersData1[selectedUser].fb_id;
  // Replace this with your actual API endpoint
  const apiUrl = `${BASE_URL}/api_orders.php?client_id=${usersData1[selectedUser].id}`;

  const fetchOrdersData = async () => {
    try {
      const response = await axios.get(apiUrl);
      const data = response.data;

      // Assuming your API response has an array of orders
      return data || [];
    } catch (error) {
      throw new Error('Error fetching orders data');
    }
  };

  fetchOrdersData()
    .then((apiData) => {
      setOrdersData(apiData);
    })
    .catch((error) => {
      console.error('Error fetching orders data:', error);
    });}
}, [usersData1, selectedUser]);
  const [previewImage, setPreviewImage] = useState(null);
  const handleUserClick = (userId) => {
    setSelectedUser(userId);
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setImage(selectedImage);

      // Create a temporary URL for image preview
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };
  const handleDocumentChange = (e) => {
    const selectedDocument = e.target.files[0];

    if (selectedDocument) {
      setDocument(selectedDocument);
      setDocumentName(selectedDocument.name);
      const nameParts = selectedDocument.name.split('.');
      const nameWithoutExtension = nameParts.slice(0, -1).join('.');
      const firstThreeCharacters = nameWithoutExtension.slice(0, 3); // Limit to 3 characters
      const extension = nameParts[nameParts.length - 1];
      setDocumentName(`${firstThreeCharacters}...${extension}`);
    }
  };
  const handleSaveOrder = async() => {
    //console.log(formValues);
    try {
      // Create order API call
      const orderConfig = {
          method: 'post',
          url: `${BASE_URL}/api_orders.php`,
          headers: {
              'Content-Type': 'application/json',
          },
          data: formValues,  // Adjust this data based on your requirements
      };
  
      // Make the Axios request for creating an order
      const orderResponse = await axios(orderConfig);
  
      // Check if the order creation response indicates success
      if (orderResponse.data) {
        //console.log(orderResponse.data);
          //console.log('Order Created successfully');
          
          const formData = new FormData();
          const currentDate = new Date();
          const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");

 
          formData.append('client_id', formValues.client_id);
          formData.append('created_datetime', formattedDate);
          formData.append('order_name', formValues.name);   
          formData.append('file', selectedFile);
          const invoiceConfig = {
              method: 'post',
              url: `${BASE_URL}/invoices_api.php`,  // Replace with your actual API endpoint for inserting an invoice
              headers: {
                  'Content-Type': 'multipart/form-data',  // Adjust the content type for file uploads
              },
              data: formData,  // Adjust this data based on your requirements
          };
  
          // Make the Axios request for inserting an invoice
          const invoiceResponse = await axios(invoiceConfig);
  
          // Check if the invoice insertion response indicates success
          if (invoiceResponse.data) {
              //console.log(invoiceResponse.data);
              alert('Order and Invoice created successfully');
          } else {
              console.error('Error inserting invoice:', invoiceResponse.data);
              alert('Error inserting invoice. Please check the console for details.');
          }
      } else {
          console.error('Error creating order:', orderResponse.data);
          alert('Error creating order. Please check the console for details.');
      }
  } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form. Please check the console for details.');
  }
  
    // try {
    //   const config = {
    //     method: 'post',
    //     url: `${BASE_URL}/api_orders.php`,
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     data: formValues,
    //   };
  
    //   // Make the Axios request
    //   const response = await axios(config);
    //   //console.log('Response:', response.data);
  
    //   // Check if the response indicates success
    //   if (response.data) {
    //     // Optionally, update UI or redirect the user
    //     //console.log('Social Links Updated successfully');
    //     alert('Order Created successfully');
    //     // props.onHide();
    //   } 
    //   // else {
    //   //   console.error('Error adding testimonial:', response.data);
    //   //   alert('Error adding testimonial. Please check the console for details.');
    //   //   props.onHide();
    //   // }
    // } catch (error) {
    //   console.error('Error submitting the form:', error);
    //   alert('Error submitting the form. Please check the console for details.');
    //   // props.onHide();
    // }
   
   
    //
    //console.log('Order saved:', formValues);
    setFormValues({
      service: '',
      category: '',
      budget: '',
      orderStartDate: '',
      orderEndDate: '',
      orderDetails: '',
      status:"In Progress"
    });
    // Close the modal after saving the order
    handleModalClose();
  };
  const formatTimestamp = (timestamp) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Set to true to display in AM/PM format
    };
  
    return new Date(timestamp.toMillis()).toLocaleString(undefined, options);
  };
  const servicesArray = [
    "Programming & Tech",
    "Writing & Translation",
    "Video & Animation",
    "Graphics & Design",
];
 
  
 
  return (
    <>
      <div className="container py-2 px-4">
        <div className="row rounded-lg overflow-hidden  shadow">
          {/* Users box */}
          <div className="col-2 px-0">
            <div className="bg-white">
              <div className="bg-gray px-4 py-2 bg-light">
                <p className="h5 mb-0 py-1">Recent</p>
              </div>

              <div className="messages-box scrollable-table-container">
                <div className="list-group rounded-0">
                {usersData1.map((user,index) => (
                    <a
                      key={user.id}
                      className={`list-group-item list-group-item-action ${user.id === selectedUser ? 'active text-white ylwbg' : 'list-group-item-light rounded-0'}`}
                      href="#"
                      onClick={() => handleUserClick(index)}
                    >
                      <div className="media">
                        <img
                          src={`${BASE_URL}/${user.profile_image}`}
                          alt="user"
                          width="30"
                          className="rounded-circle"
                        />
                        <div className="media-body ml-4">
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <h6 className="mb-0">{user.name}</h6>
                            <small className="small font-weight-bold">{user.date}</small>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Chat Box */}
          <div className="col-7 px-0">
           {/* Client name at the top */}
           <div className='box-bg'>
    <h5 className="text-center">
      {usersData1[selectedUser] && usersData1[selectedUser].name}
    </h5>
    </div>
  <div className="px-4 py-0 chat-box scrollable-table-container bg-white">
   
  {/* {messages && messages.map((message, id) => (
              <div key={id} className={`msg ${message.uid === usersData1[selectedUser].fb_id ? 'sent' : 'received'}`}>
               
                {message.uid === usersData1[selectedUser].fb_id ? (
                  <>
                    <div className="cursor-pointer leading-[normal] min-w-[202px] mt-1 rounded-br-[25px] rounded-tl-[25px] rounded-tr-[25px] text-center text-xs  p-5 bg-gray-200_04 text-gray-800_02">
                  
                      
                     
                      {message.image == null ? (
                        <p className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
                      ) : (
                        <img className="h-[100px]   w-[100px]" src={message.image} alt="User Image" />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="cursor-pointer leading-[normal] min-w-[202px] mt-1 rounded-br-[25px] rounded-tl-[25px] rounded-tr-[25px] text-right text-xs  p-5 bg-gray-200_04 text-gray-800_02">
                   
                      {message.image == null ? (
                        <p className="text-right text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
                      ) : (
                        <img  className="h-[100px]   w-[100px]" src={message.image} alt="Other User Image" />
                      )}
                    </div>
                  </>
                )}
              </div>
            ))} */}
    {/* Chat messages */}
    {/* {messages.map((message) => (
        <div
          key={message.id}
          style={{ height: "500px" }}
          className={`media w-full mb-3 ${
            message.uid === usersData1[selectedUser].fb_id ? '' : 'ml-auto clinet'
          }`}
        >
          <img
            src={
              message.uid === usersData1[selectedUser].fb_id
                ? `${BASE_URL}/${usersData1[selectedUser].profile_image}`
                : "img_rectangle171.png"
            }
            alt="user"
            width="50"
            height="50"
            className="rounded-circle"
          />
          <div className="media-body ml-3">
            <div className={`bg-light rounded py-2 px-3 mb-2`}>
              {message.image == null ? (
                <p className="text-small mb-0 text-muted">{message.text}</p>
              ) : (
                <img
                  width="150px"
                  height="150px"
                  src={message.image}
                  alt="Message Image"
                  onClick={() => setPreviewImage(message.image)}
                />
              )}
              <p className="text-small mb-0 text-muted">
                {message.createdAt &&
                  formatTimestamp(message.createdAt)}
              </p>
            </div>
          </div>
        </div>
      ))} */}
      {messages.map((message) => (
  <div
    key={message.id}
    style={{ height: "500px" }}
    className={`media w-full mb-3 ${message.uid === usersData1[selectedUser].fb_id ? '' : 'ml-auto clinet'}`}
  >
    <img
      src={message.uid === usersData1[selectedUser].fb_id ? `${BASE_URL}/${usersData1[selectedUser].profile_image}` : "img_rectangle171.png"}
      alt="user"
      width="50"
      height="50"
      className="rounded-circle"
    />
    <div className="media-body ml-3">
      <div className={`bg-light rounded py-2 px-3 mb-2`}>
        {message.document ? (
          <div>
            <FaFile style={{ marginRight: '5px' }} /> {/* Document icon */}
            <a href={message.document.url} target="_blank" rel="noopener noreferrer">{message.document.name}</a>
          </div>
        ) : (
          <>
            {message.image == null ? (
              <p className="text-small mb-0 text-muted">{message.text}</p>
            ) : (
              <img
                width="150px"
                height="150px"
                src={message.image}
                alt="Message Image"
                onClick={() => setPreviewImage(message.image)}
              />
            )}
          </>
        )}
        <p className="text-small mb-0 text-muted">
          {message.createdAt && formatTimestamp(message.createdAt)}
        </p>
      </div>
    </div>
  </div>
))}

      <div ref={messagesEndRef} />
</div>
    {/* Typing area */}
    <form action="#" className="bg-light">
      <div className="input-group" >
        <input
          type="text"
          placeholder="Type a message"
          aria-describedby="button-addon2"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          className="form-control rounded-0 border-0 py-4 bg-light"
        />
          {/* document Button */}
          <div className="input-group-append" 
          style={{position: "absolute",
    right: "44px",
    top: "18px",     zIndex: ""}}>
      <label htmlFor="fileUpload">
        <IoDocuments  style={{ height: '24px', width: '24px', marginTop: '5px', marginLeft: '5px', marginRight: '5px' }} />
      </label>
      
      <input
        type="file"
        id="fileUpload"
        accept=".pdf,.doc,.docx"
        onChange={handleDocumentChange}
        style={{ display: 'none' }}
      />
    </div>
    <div className="input-group-append" 
          style={{position: "absolute",
    right: "140px",
    top: "20px",     zIndex: ""}}>
{documentName && <p className='mt-[10px] ml-[2px]'>{documentName}</p>}
    </div>
    {/* image Btn */}
    <div className="input-group-append" 
          style={{position: "absolute",
    right: "80px",
    top: "10px",     zIndex: ""}}>
     
  <label htmlFor="imageUpload">
          <FaImages style={{ height: '24px', width: '24px', marginTop: '15px', marginLeft: '10px' }} />
        </label>
        <input
          type="file"
          id="imageUpload"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
    </div>

<div className="input-group-append" 
          style={{position: "absolute",
    right: "110px",
    top: "20px",     zIndex: ""}}>
{imagePreview && <img class=""
     style={{height:"25px", width:"20px"}}
      src={imagePreview} alt="Image Preview" />}
</div>
{/* Send Btn */}
      <label htmlFor="fileUpload"></label>
        <div className="input-group-append" style={{position: "absolute",
    right: "0",
    top: "13px",     zIndex: ""}}>
          <button type="submit" className="btn btn-link" style={{fontSize:"28px"}} onClick={sendMsg}>
          <i class='bx bx-send'></i>
          </button>
          
        </div>
      </div>
    </form>

</div>
<div className='col-md-3 px-0'>
<div className='box-bg'>
  <h5 className="text-center">Details:</h5>
</div>

{/* Client details */}
<div className='px-2' style={{fontFamily:"'Poppins'"}}>
  <p><strong>Name:</strong> {usersData1[selectedUser] && usersData1[selectedUser].name}</p>
  <p><strong>Email:</strong> {usersData1[selectedUser] && usersData1[selectedUser].email}</p>
  <p><strong>Phone Number:</strong> {usersData1[selectedUser] && usersData1[selectedUser].phone}</p>
  <p><strong>Country:</strong> {usersData1[selectedUser] && usersData1[selectedUser].country}</p>
  <p style={{ paddingLeft: "0px" }}><strong>Orders History:</strong>
  {Array.isArray(ordersHistory) && ordersHistory.length > 0 ? (
    ordersHistory.map((order, index) => (
      <span key={index}>
        {index > 0 && ", "} {order.service_name}
      </span>
    ))
  ) : (
    <span>
       No Orders Yet
    </span>
  )}
  {/* {ordersHistory.map((order, index) => (
      <span key={index}>
        {index > 0 && ", "} {order.service_name}
      </span>
    ))} */}
</p>
  <Button variant='danger' onClick={()=>{
    setFormValues({ ...formValues, client_id: usersData1[selectedUser].id,country:usersData1[selectedUser].country });
    handleModalShow();}}>Create Order</Button>
</div>
</div>
<Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <form>
    <div className="mb-3">
    <div className='col-6'>
        <div className="mb-3">
      <label htmlFor="payment" className="form-label">Order Name</label>
      <input
        type="text"
        className="form-control"
        id="ordername"
        value={formValues.name}
        onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
      />
    </div>
        </div>
      <label htmlFor="services" className="form-label">Services List</label>
      <select
    className="form-select"
    id="service"
    value={formValues.category_id!=null?service:""}
    onChange={(e) => {setFormValues({ ...formValues, category_id: e.target.value=="Programming & Tech"?1:e.target.value=="Writing & Translation"?2:e.target.value=="Video & Animation"?3:4 });
    setServices(e.target.value);
  }}
  >
    <option value="">Select a Service</option>
    {servicesArray.map((service) => (
      <option key={service} value={service}>{service}</option>
    ))}
  </select>
    </div>
    <div className="mb-3">
      <label htmlFor="category" className="form-label">SubService List</label>
      {/* <select
    className="form-select"
    id="category"
    value={formValues.category}
    onChange={(e) => setFormValues({ ...formValues, category: e.target.value })}
  > */}
      {service === "Programming & Tech" && (
  <select
  className="form-select"
  id="category"
  value={formValues.service_name}
    // className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
    onChange={onSOptionChangeHandler}
  >
    <option>Sub-Service</option>
    {service1.map((option) => (
      <option key={option.title}>{option.title}</option>
    ))}
  </select>
)}

{service === "Writing & Translation" && (
  <select
  className="form-select"
  id="category"
    value={formValues.service_name}
    // className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
    onChange={onSOptionChangeHandler}
  >
    <option>Sub-Service</option>
    {service2.map((option) => (
      <option key={option.title}>{option.title}</option>
    ))}
  </select>
)}

{service  === "Video & Animation" && (
  <select
  className="form-select"
  id="category"
  value={formValues.service_name}
    // className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
    onChange={onSOptionChangeHandler}
  >
    <option>Sub-Service</option>
    {service3.map((option) => (
      <option key={option.title}>{option.title}</option>
    ))}
  </select>
)}

{ service  === "Graphics & Design" && (
  <select
  className="form-select"
  id="category"
  value={formValues.service_name}
    // className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
    onChange={onSOptionChangeHandler}
  >
    <option>Sub-Service</option>
    {service4.map((option) => (
      <option key={option.title}>{option.title}</option>
    ))}
  </select>
)}

    </div>
    <div className='row'>
      <div className='col-6'>
      <div className="mb-3">
      <label htmlFor="clientName" className="form-label">Client's Name</label>
      <select
    className="form-select"
    id="clientName"
    value={formValues.client_id ?? " "}
    onChange={(e) => setFormValues({ ...formValues, client_id: e.target.value })}
  >
    <option value="">Client Name</option>
    {initialData.map((service) => (
      <option key={service.name} value={service.id}>{service.name}</option>
    ))}
  </select>
      {/* <input
        type="text"
        className="form-control"
        id="clientName"
        value={""}
        
      /> */}
    </div>
      </div>
      <div className='col-6'>
      <div className="mb-3">
      <label htmlFor="budget" className="form-label">Amount</label>
      <input
        type="text"
        className="form-control"
        id="budget"
        value={formValues.price}
        onChange={(e) => setFormValues({ ...formValues, price: e.target.value })}
      />
    </div>
      </div>

    </div>
      <div className='row'>
        <div className='col-6'>
        <div className="mb-3">
      <label htmlFor="country" className="form-label">Country</label>
      <input
        type="text"
        className="form-control"
        id="country"
        value={formValues.country}
        onChange={(e) => setFormValues({ ...formValues, country: e.target.value })}
      />
    </div>
        </div>
        <div className='col-6'>
        <div className="mb-3">
      <label htmlFor="payment" className="form-label">Payment Method</label>
      <input
        type="text"
        className="form-control"
        id="payment"
        value={formValues.payment_mode}
        onChange={(e) => setFormValues({ ...formValues, payment_mode: e.target.value })}
      />
    </div>
        </div>
      </div>
    
    <div className='row'>
      <div className='col-6'>
      <div className="mb-3">
  <label htmlFor="orderStartDate" className="form-label">Order Start Date & Time</label>
  <input
    type="date"
    className="form-control"
    id="orderStartDate"
    value={formValues.starting_date}
    onChange={(e) => setFormValues({ ...formValues, starting_date: e.target.value })}
  />
</div>
      </div>
      <div className='col-6'>
        

<div className="mb-3">
  <label htmlFor="orderEndDate" className="form-label">Order End Date & Time</label>
  <input
    type="date"
    className="form-control"
    id="orderEndDate"
    value={formValues.delivering_date}
    onChange={(e) => setFormValues({ ...formValues,delivering_date: e.target.value })}
  />
</div>
      </div>

    </div>

    <div className="mb-3">
      <label htmlFor="orderDetails" className="form-label">Order Details</label>
      <textarea
        className="form-control"
        id="orderDetails"
        rows="3"
        value={formValues.add_on}
        onChange={(e) => setFormValues({ ...formValues, add_on: e.target.value })}
      ></textarea>
    </div>
   
  </form>
</Modal.Body>
<Modal.Footer>
  <label>Upload Invoice</label>
  <input
            id="upload"
            type="file"
            accept=".pdf"
            className="form-control" 
            onChange={handleFileChange}           
          />
    <Button variant="secondary" onClick={handleModalClose}>
      Close
    </Button>
    {/* Add your order creation action button here */}
    <Button variant="primary" onClick={handleSaveOrder}>
      Save Order
    </Button>
  </Modal.Footer>
      </Modal>

<Modal show={previewImage !== null} onHide={() => setPreviewImage(null)}>
        <Modal.Body className="text-center">
          <img
            src={previewImage}
            alt="Preview"
            className="img-fluid"
          />
        </Modal.Body>
      </Modal>
        </div>
      </div>
    </>
  );
};

export default Messages;
