import React, { useState ,useEffect} from "react";
import axios from "axios";
import BASE_URL from "./constant/constant";
import { Button, Modal } from 'react-bootstrap';
// import ReactQuill from "react-quill";
// import ImageAndVideoUpload from "./Addmedia";
import ProgressDialog from "./dialog";
import { BsCloudUpload, BsX } from 'react-icons/bs';

const AddCategory = () => {
  const [videoProgress, setVideoProgress] = useState(0);
  const [showProgressModal, setShowProgressModal] = useState(false);
  // useEffect(() => {
  //   axios
  //     .get(`${BASE_URL}/categories_api.php/`)
  //     .then(response => {
  //       const data = response.data;

  //             //console.log(data);
  //             setCategories(data || []);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });},[]);
      const fetchData = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/categories_api.php/`);
          const data = response.data;
          setCategories(data || []);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      
      useEffect(() => {
        fetchData();
      }, []); 


  const [categories, setCategories] = useState([]);
  const [editedCategory, setEditedCategory] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [newImages,  setNewImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const handleEditClick = (category) => {
    const images = category.images.filter(url => !url.endsWith('.mp4') && !url.endsWith('.mkv') && !url.endsWith('.avi'));
    const videos = category.images.filter(url => url.endsWith('.mp4') || url.endsWith('.mkv') || url.endsWith('.avi'));
  
    setEditedCategory({ ...category });
    // const images = editedCategory.images.filter(url => !url.endsWith('.mp4') && !url.endsWith('.mkv') && !url.endsWith('.avi'));
    //     const videos = editedCategory.images.filter(url => url.endsWith('.mp4') || url.endsWith('.mkv') || url.endsWith('.avi'));
    
        setSelectedImages(images);
        setSelectedVideos(videos);
    setShowModal(true);
  };

  const handleSaveEdit = () => {
     //console.log('Edited Category:', editedCategory);
     //console.log(selectedImages);
     //console.log(selectedVideos);
    

     if (editedCategory.id == null) {
      //console.log(' Category:', editedCategory);
      setShowProgressModal(true);
      const formData = new FormData();
    
      // Append images without specifying indices
      // selectedImages.forEach(file => {
      //   formData.append('images[]', file);
      // });
      selectedImages.forEach((dataUrl, index) => {
        const now = new Date();
        const timestamp = now.toISOString().replace(/:/g, '-').replace(/\..+/, '');
        const filename = `Category_image${index}_${timestamp}.png`;
        const imageFile = dataURLtoFile(dataUrl, filename);
        //console.log(imageFile);
        formData.append('images[]', imageFile);
      });
    
      // Append other user data to the FormData object
      formData.append('name', editedCategory.name);
      formData.append('details', editedCategory.details);
      selectedVideos.forEach((dataUrl, index) => {
        // const filename = `video${index}.mp4`;
        // const imageFile = dataURLtoFile(dataUrl, filename);
        // //console.log(imageFile);
        formData.append('video[]', dataUrl.file);
      });
    
      // Configure the Axios request
      const config = {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setVideoProgress(percentCompleted);
        },
        method: 'post',
        url: `${BASE_URL}/categories_api.php`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };
    
      // Make the Axios request
      axios(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          alert('Category added successfully');
          setShowProgressModal(false);
    setVideoProgress(0);
   fetchData()
        })
        .catch((error) => {
          console.error('Error submitting the form:', error);
          alert('Error submitting the form. Please check the console for details.');
          setShowProgressModal(false);
    setVideoProgress(0);
   fetchData()
        });
    }
    else{
  //console.log('Edited Category:', editedCategory);
  setShowProgressModal(true);
  const formData = new FormData();

    // Append edited category data
    formData.append('name', editedCategory.name);
    formData.append('details', editedCategory.details);

    // Append images
    selectedImages.forEach((dataUrl, index) => {
      if (!(dataUrl.startsWith('http://') || dataUrl.startsWith('https://'))) {
        // If the data URL doesn't start with http:// or https://, it's considered a local file
        const now = new Date();
        const timestamp = now.toISOString().replace(/:/g, '-').replace(/\..+/, '');
        const filename = `Category_image${index}_${timestamp}.png`;
        // Convert the data URL to a file
        const imageFile = dataURLtoFile(dataUrl, filename);
        // Append the file to the form data as 'images[]'
        formData.append('images[]', imageFile);
        console.log(imageFile); // Log the file object
      } else {
        // If the data URL starts with http:// or https://, it's considered an external URL
        // Append the URL to the form data as 'image_urls[]'
        formData.append('image_urls[]', dataUrl);
        console.log(dataUrl); // Log the URL
      }
    });
    
      selectedVideos.forEach((dataa ,index) => {
        // //console.log('This dataUrl contains http or https:', dataUrl);
        if (dataa.src!=null) {
        
        formData.append('video[]', dataa.file);
      }else {
        // If the data URL starts with http:// or https://, it's considered an external URL
        // Append the URL to the form data as 'image_urls[]'
        formData.append('video_urls[]', dataa);
        console.log(dataa); // Log the URL
      }
      
        });
   
    // Axios request configuration
    const config = {
       onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setVideoProgress(percentCompleted);
    },
      method: 'post',  // Use 'post' for update
      url: `${BASE_URL}/categories_api.php/?id=${editedCategory.id}&_method=PATCH`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };

    // Make the Axios request
    axios(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        alert('Category Updated successfully');
        setShowProgressModal(false);
        setVideoProgress(0);
       fetchData()
      })
      .catch((error) => {
        console.error('Error updating category:', error);
        alert('Error updating category. Please check the console for details.');
        setShowProgressModal(false);
        setVideoProgress(0);
       fetchData()
      });
  };
  // const formData = new FormData();

  // selectedImages.forEach((dataUrl, index) => {
  //   if (dataUrl.includes('http://') || dataUrl.includes('https://')) {
  //     // Handle the case where the dataUrl contains 'http://' or 'https://'
  //     //console.log('This dataUrl contains http or https:', dataUrl);
  //     formData.append('images[]', dataUrl);
  // }else{
  //   const filename = `image${index}.png`;
  //   const imageFile = dataURLtoFile(dataUrl, filename);
  //   //console.log(imageFile);
  //   formData.append('images[]', imageFile);
  // }
   
  // });
  // selectedVideos.forEach((dataUrl) => {
  //   formData.append('video[]', dataUrl.file);
  // });

  // // Append other user data to the FormData object
  // formData.append('name', editedCategory.name);
  // formData.append('details', editedCategory.details);
 

  // // Configure the Axios request
  // const config = {
  //   method: 'post',
  //   url: `${BASE_URL}/categories_api.php/?id=${editedCategory.id}&_method=PATCH`,
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //   },
  //   data: formData,
  // };

  // // Make the Axios request
  // axios(config)
  //   .then((response) => {
  //     //console.log(JSON.stringify(response.data));
  //     alert('Category Updated successfully');
  //   })
  //   .catch((error) => {
  //     console.error('Error submitting the form:', error);
  //     alert('Error submitting the form. Please check the console for details.');
  //   });



    setShowModal(false);
  };
  function dataURLtoVideoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
  
    // For videos, directly create a Blob from the data URL
    const blob = new Blob([dataUrl], { type: mime });
    return new File([blob], filename, { type: mime });
  }
  
  const handleAddNewCategory = () => {
    setEditedCategory({});
    setSelectedImages([]);
  setSelectedVideos([]);
    setShowModal(true);
  };

 
  const handleImageChange = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const updatedImages = [...selectedImages];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          updatedImages.push(e.target.result);
          if (i === files.length - 1) {
            // Set state after all files are processed
            setSelectedImages(updatedImages);
          }
        };
        reader.readAsDataURL(files[i]);
      }
    }
  };
  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    const blob = new Blob([u8arr], { type: mime });
    const file = new File([blob], filename, { type: mime });
    
    return file;
  }
  // function dataURLtoFile(dataUrl, filename) {
  //   const arr = dataUrl.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);
  
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  
  //   const blob = new Blob([u8arr], { type: mime });
  //   const file = new File([blob], filename, { type: mime });
    
  //   return file;
  // }
  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
    
  };

  const handleVideoChange = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      const updatedVideos = [...selectedVideos];

      for (const file of files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          updatedVideos.push({ src: e.target.result, file });
          setSelectedVideos([...updatedVideos]);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleRemoveVideo = (index) => {
    const updatedVideos = [...selectedVideos];
    updatedVideos.splice(index, 1);
    setSelectedVideos([...updatedVideos]);
  };

  
  return (
    <div>
      <h2>Category Table</h2>
      {/* <Button variant="primary" onClick={handleAddNewCategory}>
        Add New Category
      </Button> */}

      <table className="table mt-3">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Category Name</th>
            <th scope="col">Description</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <th scope="row">{category.id}</th>
              <td>{category.name}</td>
              <td>{category.details}</td>
              <td>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleEditClick(category)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editedCategory.id ? 'Edit Category' : 'Add New Category'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="editName">Category Name</label>
              <input
                type="text"
                className="form-control"
                id="editName"
                value={editedCategory.name || ''}
                onChange={(e) => setEditedCategory({ ...editedCategory, name: e.target.value })}
              />
            </div>
            <div className="form-group">
            <label  className="form-label">Description</label>
            <input
                type="text"
                className="form-control"
                id="editdetails"
                value={editedCategory.details || ''}
                onChange={(e) => setEditedCategory({ ...editedCategory, details: e.target.value })}
              />
                  {/* <ReactQuill
                    value={editedCategory.details}
                    onChange={handleChange}
                    className="py-3.5"
                    theme="snow"
                  /> */}
            </div>
          </form>
          <br></br>
          <div className="form-group">
          {/* <ImageAndVideoUpload someProp={editedCategory.images || []} onSelectedDataChange={handleSelectedDataChange}/> */}
          <div>
      <label
        htmlFor="imageInput"
        style={{ display: 'block', cursor: 'pointer', marginBottom: '8px' }}
      >
        <BsCloudUpload style={{ marginRight: '8px' }} />
        Upload Image 2352 × 1248 || 1752 × 981
      </label>
      <div className="">
        {[...Array(selectedImages.length + 1)].map((_, index) => (
          <div key={index} className="">
            <input
              id="imageInput"
              type="file"
              accept="image/*"
              className="form-control"
              style={{ display: 'none' }}
              onChange={handleImageChange}
              multiple={index === 0} // Allow selecting multiple files only for the first input
            />
          </div>
        ))}
      </div>

      {/* Preview of the uploaded images */}
      {selectedImages.length > 0 && (
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-4">
          {selectedImages.map((image, index) => (
            <div key={index} className="col">
            <div className="card">
              <img
                src={image}
                alt={`Uploaded Image Preview ${index + 1}`}
                style={{ maxWidth: '100%', height: '100px', marginBottom: '10px' }}
              />
                <div className="card-body">
              <button
                className="btn btn-danger btn-sm"
                onClick={() => handleRemoveImage(index)}
              >
                <BsX style={{ marginRight: '4px' }} />
                Remove
              </button>
              </div>
            </div>
            </div>
          ))}
        </div>
      )}
      <br />

       <div className="row">
        <label
          htmlFor="videoInput"
          className="col-12"
          style={{ cursor: 'pointer', marginBottom: '10px' }}
        >
          <BsCloudUpload style={{ marginRight: '8px' }} />
          Upload Video 2352 × 1248 || 1752 × 981
        </label>
        <input
          id="videoInput"
          type="file"
          accept="video/*"
          className="form-control col-12"
          style={{ display: 'none' }}
          onChange={handleVideoChange}
          multiple
        />
      </div>

      {/* Previews of the uploaded videos */}
      <div className="row">
        {selectedVideos.map((video, index) => (
          <div key={index} className="col-md-3 mb-3">
            <video
              controls
              src={video.src}
              alt={`Uploaded Video Preview ${index + 1}`}
              className="w-100"
              style={{ maxHeight: '120px', marginBottom: '5px' }}
            />
            <button
              onClick={() => handleRemoveVideo(index)}
              className="btn btn-danger btn-sm w-100"
            >
              <BsX /> Remove
            </button>
          </div>
        ))}
      </div>
    </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <ProgressDialog show={showProgressModal} onHide={() => setShowProgressModal(false)} progress={videoProgress} />
    </div>
  );
};

export default AddCategory;
