import React, { useState,useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import BASE_URL from './constant/constant';

const ServicesSalesChart = ({sale}) => {
 
  const categories = sale.map(item => item.category);
  const counts = sale.map(item => item.orderCount);
  // Sample data for services sales
  const data = {
     labels: categories,
    datasets: [
      {
        data:  counts,
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(255, 205, 86, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(153, 102, 255, 0.7)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 205, 86, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'right',
      },
    },
    height: 200, // Set the height of the chart
  };

  return (
    <div className='visitor-chart box-bg' style={{width:"100%", height:"100%"}}>
      <h3>Services Sales</h3>
      <div className='' >
      <Doughnut data={data} options={options}   style={{maxHeight:"300px"}}/>
      </div>
    </div>
  );
};

export default ServicesSalesChart;
