import React, { useState ,useEffect} from "react";
import BASE_URL from "./constant/constant";
import axios from "axios";
import { Form, Row, Col,Button } from "react-bootstrap";
import MeetingDetailsPopup from "./meetingpop";
import { format } from 'date-fns';
const Meeting = () => {
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  const [meetings, setMeetings] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/meetings_api.php/`)
      .then(response => {
        const data = response.data;

              //console.log(data);
              setMeetings(data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });});
  const [sortBy, setSortBy] = useState('latest'); // Default sorting by latest
  const [filterByService, setFilterByService] = useState('');

  const handleToggle = (id) => {
    setMeetings((prevMeetings) =>
      prevMeetings.map((meeting) =>
        meeting.id === id
          ? { ...meeting, meetingStatus: meeting.meetingStatus === 'Scheduled' ? 'Completed' : 'Scheduled' }
          : meeting
      )
    );
  };
  function formatTime(timeString) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':');
  
    // Create a new Date object with today's date and the provided time
    const dateTime = new Date();
    dateTime.setHours(hours);
    dateTime.setMinutes(minutes);
    dateTime.setSeconds(seconds);
  
    // Return the formatted time using toLocaleString() with appropriate options
    return dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }
  
  const sortMeetings = (meetings) => {
    console.log(sortBy);
   
  switch (sortBy) {
    case 'latest':
      return meetings.sort((a, b) => new Date(b.scheduled_date) - new Date(a.scheduled_date));
    case 'oldest':
      return meetings.sort((a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date));
      case 'today':
        const today = new Date().toISOString().split('T')[0];
        return meetings.filter((meeting) => meeting.scheduled_date && meeting.scheduled_date.split(' ')[0] === today);
    case 'tomorrow':
      const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0];
      return meetings.filter((meeting) => meeting.scheduled_date.split(' ')[0] === tomorrow);
    default:
      return meetings;
  }
  };
  const handleOpenPopup = (meeting) => {
    setSelectedMeeting(meeting);
  };

  const handleClosePopup = () => {
    setSelectedMeeting(null);
  };
  function formatUtcToLocalTime( scheduled_time,scheduled_date) {
    // console.log
    const dateParts = scheduled_date.split('-').map(Number);
    const timeParts = scheduled_time.split(':').map(Number);

    // Validate date and time parts
    // if (dateParts.length !== 3 || timeParts.length !== 3) {
    //     console.error('Invalid date or time format');
    //     return null;
    // }

    const [year, month, day] = dateParts;
    const [hours, minutes, seconds] = timeParts;
    const utcDateTime = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    // Format the UTC date to the local time zone
    const localTime = utcDateTime.toLocaleString(undefined, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    return localTime;


    // if (isNaN(year) || isNaN(month) || isNaN(day) || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    //     console.error('Invalid date or time values');
    //     return null;
    // }

    // // Create UTC date time
    // const utcDateTime = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    // // Format local time
    // const localTime = utcDateTime.toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true });

    // return localTime;
}


  
  const handleUpdateStatus = (meetingId, newStatus) => {
    // Implement the logic to update the meeting status on the server
    //console.log(`Update status of meeting ${meetingId} to ${newStatus}`);
    // After updating the status, you can close the popup
    handleClosePopup();
  };
  const uniqueServices = Array.from(new Set(meetings.map((meeting) => meeting.service_name))); // Get unique services

  const filteredMeetings = sortMeetings(meetings).filter(
    (meeting) => filterByService === '' || meeting.service_name.toLowerCase().includes(filterByService.toLowerCase())
  );

  return (
    <>
     <div className="ww80 mt-10">
        <div>
          <Row className="mb-3">
            <Col>
              <Form.Label className="me-2">Sort by:</Form.Label>
              <Form.Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                <option value="latest">Latest</option>
                <option value="oldest">Oldest</option>
                <option value="today">Today</option>
                {/* <option value="tomorrow">Tomorrow</option> */}
              </Form.Select>
            </Col>
            <Col>
              <Form.Label className="me-2">Filter by Category:</Form.Label>
              <Form.Select
                value={filterByService}
                onChange={(e) => setFilterByService(e.target.value)}
              >
                <option value="">All Services</option>
                {uniqueServices.map((service) => (
                  <option key={service} value={service}>
                    {service}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <table className="data-table">
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Meeting Time</th>
              <th>Meeting Date</th>
              <th>Service</th>
              <th>Meeting Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredMeetings.map((meeting) => (
              <tr key={meeting.id}>
                <td>{meeting.client_name}</td>
                <td>{meeting.client_email}</td>
                <td>{meeting.phone}</td>
                <td>{formatUtcToLocalTime(meeting.scheduled_time ,meeting.scheduled_date)}</td> 
                {/* <td>{formatTime(meeting.scheduled_time)}</td> */}


                <td>{meeting.scheduled_date}</td>
                <td>{meeting.service_name}</td>
                <td>{meeting.meetingStatus}</td>
                <td>
                  <label className="switch">
                  <Button onClick={() => handleOpenPopup(meeting)}>View Details</Button>
                    {/* <input
                      type="checkbox"
                      checked={meeting.meetingStatus === 'Completed'}
                      onChange={() => handleToggle(meeting.id)}
                    /> */}
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedMeeting && (
        <MeetingDetailsPopup
          meetingData={selectedMeeting}
          onClose={handleClosePopup}
          onUpdateStatus={handleUpdateStatus}
        />
      )}
    </>
  );
};

export default Meeting;
