import React, { useState, useEffect } from 'react';
import BASE_URL from './constant/constant';
import axios from 'axios';
import { Dropdown, DropdownButton, Modal, Button } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { format } from 'date-fns';

const NewChat = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [messageLength, setMessageLength] = useState(50); // Change message length as needed
  const [filterType, setFilterType] = useState('all');
  const [selectedMessage, setSelectedMessage] = useState('');

  useEffect(() => {
    axios
      .get(`${BASE_URL}/chat_simple.php/`)
      .then((response) => {
        const data = response.data;
        setTableData(data || []);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleAddNewMember = () => {
    setModalData(null);
    setIsEditing(false);
    setModalShow(true);
  };

  const handleEditMember = (id) => {
    if (id) {
      setModalData(id);
      setIsEditing(true);
      setModalShow(true);
    }
  };

  const handleRemoveClick = (id) => {
    axios
      .delete(`${BASE_URL}/chat_simple.php`, {
        data: {
          id: id,
        },
      })
      .then((response) => {
        alert('Chat deleted successfully:', response.data.message);
      })
      .catch((error) => {
        alert('Error deleting chat:', error.response.data.message);
      });

    setTableData((prevData) => prevData.filter((member) => member.id !== id));
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
    // Filter data based on selected filter type
    let filteredData = [...tableData];
    switch (type) {
      case 'latest':
        filteredData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        break;
      case 'oldest':
        filteredData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        break;
      case 'today':
        const today = new Date().toISOString().split('T')[0];
        filteredData = filteredData.filter((item) => item.created_at.split(' ')[0] === today);
        break;
      default:
        // 'all' or other cases
        break;
    }
    setTableData(filteredData);
  };

  const handleShowMore = (message) => {
    setSelectedMessage(message);
    setModalShow(true);
  };

  return (
    <>
      <div className="table-data">
        <div className="order">
          <div className="head">
            <h3>5 Min Chat </h3>
            <DropdownButton id="dropdown-filter" title={`Filter: ${filterType}`}>
              <Dropdown.Item onClick={() => handleFilterChange('all')}>All</Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange('latest')}>Latest</Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange('oldest')}>Oldest</Dropdown.Item>
              {/* <Dropdown.Item onClick={() => handleFilterChange('today')}>Today</Dropdown.Item> */}
            </DropdownButton>
          </div>
          <table>
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Customer Email</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((customer) => (
                <tr key={customer.id}>
                  <td>{customer.client_name}</td>
                  <td>{customer.client_email}</td>
                  <td>
                    <p>
                      {customer.message.length > messageLength
                        ? `${customer.message.slice(0, messageLength)}...`
                        : customer.message}
                    </p>
                    {customer.message.length > messageLength && (
                      <button onClick={() => handleShowMore(customer.message)}>Show More</button>
                    )}
                  </td>
                  <td>
                    
          <Button variant="danger" style={{marginLeft:"4px"}} onClick={() => handleRemoveClick(customer.id)}>
            <BsTrash />
          </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Message Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>{selectedMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewChat;
