import React, { useState,useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { Button, Modal } from 'react-bootstrap';
import AddService from "./AddService";
import axios from 'axios';
import BASE_URL from './constant/constant';

const Categories = () => {


  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);

  // State to manage whether in edit mode or not
  const [isEditMode, setIsEditMode] = useState(false);
  const[ editServiceData,setEditServiceData] = useState([]);



  const[ Service ,setServices]= useState([]);
  useEffect(() => {
    //console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php`)
      .then(res => {
        //console.log(res.data);
        setServices(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, []); 
  

  const handleEditClick = (row) => {
    setEditServiceData(row);
    setIsEditMode(true);
    setShowModal(true);
    // handleShow();
  };
  

  // Handler to open the modal for inserting new data
  const handleInsertNewService = () => {
    setEditServiceData([]);
    setIsEditMode(false);
    setShowModal(true);
  };

  const handleRemoveService = (id) => {
    // Send DELETE request to remove the service
    axios.delete(`${BASE_URL}/services_api.php/?id=${id}` )
      .then(res => {
        // Remove the service from state upon successful deletion
        setServices(prevServices => prevServices.filter(service => service.id !== id));
        alert('Service Deleted successfully');
      })
      .catch(err => console.log(err));
  };

  

  return (
    <>
      <div className="text-center ww80 mt-10">
        <div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-6">
                <h1>Service Table</h1>
              </div>
              <div className="col-md-6">
                <Button variant="primary" onClick={handleInsertNewService}>
                  Add Service 
                </Button>
              </div>
            </div>
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                <td>Title</td>
                <td >Name</td>
                      
                      <td >Category</td>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
            
              <tbody>
  {Array.isArray(Service) && Service.length > 0 ? (
    Service.map((row, index) => (
      <tr key={row.id}>
        <td>{row.title}</td>
        <td>{row.name}</td>
        <td>{row.category.name}</td>
        <td>
          <Button variant="success" onClick={() => handleEditClick(row)}>Edit</Button>
        </td>
        <td>
        <Button variant="danger" onClick={() => handleRemoveService(row.id)}>Delete</Button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="4">No services found</td>
    </tr>
  )}
</tbody>


            </table>
          </div>
          <Modal show={showModal} size="lg" onHide={handleClose}>
            <AddService
             showModal={showModal}
             setShowModal={setShowModal}
             isEditMode={isEditMode}
             editServiceData={editServiceData}
            />
            </Modal>
        </div>
      </div>
    </>
  );
};

export default Categories;
