import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const SalesChart = () => {
  const chartRef = useRef(null);

  // Sample data for total sales
  const totalSalesData = [500, 700, 900, 1200, 800, 1100, 1500, 1000, 1300, 1600, 1200, 1800];

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const data = {
      labels: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December',
      ],
      datasets: [
        {
          label: 'Total Sales',
          data: totalSalesData,
          backgroundColor: [
            'rgba(18, 27, 65, 0.7)',
            'rgb(252, 5, 190, 0.7)',
            'rgba(76, 175, 80, 0.7)',
            'rgba(244, 67, 54, 0.7)',
            'rgba(119, 216, 28, 0.7)',
            'rgba(255, 162, 158, 0.7)',
            'rgba(121, 85, 72, 0.7)',
            'rgba(33, 150, 243, 0.7)',
            'rgba(255, 193, 7, 0.7)',
            'rgba(76, 175, 80, 0.7)',
            'rgba(244, 67, 54, 0.7)',
            'rgba(103, 58, 183, 0.7)',
          ],
          borderColor: [
            'rgba(18, 27, 65, 1)',
            'rgba(252, 5, 190, 1)',
            'rgba(76, 175, 80, 1)',
            'rgba(244, 67, 54, 1)',
            'rgba(119, 216, 28, 1)',
            'rgba(255, 162, 158, 1)',
            'rgba(121, 85, 72, 1)',
            'rgba(33, 150, 243, 1)',
            'rgba(255, 193, 7, 1)',
            'rgba(76, 175, 80, 1)',
            'rgba(244, 67, 54, 1)',
            'rgba(103, 58, 183, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'start',
          offset: 4,
          formatter: (value, context) => {
            return `${context.chart.data.labels[context.dataIndex]}: ${value}`;
          },
        },
      },
    };

    const myChart = new Chart(ctx, {
      type: 'pie',
      data: data,
      options: options,
    });

    return () => {
      myChart.destroy();
    };
  }, [totalSalesData]);

  return (
    <>
      <br/>
      <div className='visitor-chart'>
        <h3>Total Sales</h3>
        <canvas ref={chartRef} />
      </div>
    </>
  );
};

export default SalesChart;
