import React, { useState ,useEffect} from 'react';

import axios from 'axios';
import BASE_URL from './constant/constant';
import { BsCloudUpload, BsX } from 'react-icons/bs';
import ProgressDialog from './dialog';
const SlideVideos = () => {
  //const [sectionVideos, setSectionVideos] = useState(Array.from({ length: 5 }, () => null));
  const [videoProgress, setVideoProgress] = useState(0);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const handleVideoChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setVideo({ src: e.target.result, file });
      };
      reader.readAsDataURL(file);
    }
  };
  const [data, setData] = useState([
    // Add more data as needed
]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/home_video_api.php`);
      const data = response.data;
      setData(data || {});
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  



  const [video, setVideo] = useState({
  });
  const [selectedValue, setSelectedValue] = useState('');

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);
  };

  const [description, setDescription] = useState('');

  // Event handlers
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const [urls, setUrls] = useState('');

  // Event handlers
  const handleUrlsChange = (event) => {
    setUrls(event.target.value);
  };

  const handleRemoveVideo = () => {
   
    setVideo({}); // Assuming setVideo is the state updater for video
    setDescription('');
    setSelectedValue(''); // Resetting the description if needed
    
  };
  

  const handleSaveVideo = async() => {
    try {
      setShowProgressModal(true);
      const formData = new FormData(); 
      formData.append('id', selectedValue);
      formData.append('name', description);
      formData.append('video_url', urls);

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setVideoProgress(percentCompleted);
      },
      method: 'post',
      url: `${BASE_URL}/home_video_api.php`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };

    // Make the Axios request
    const response = await axios(config);
    //console.log('Response:', response.data);

    // Check if the response indicates success
    if (response.data) {
      
      alert('Review Video Added successfully');
      
    } 
   
  } catch (error) {
    console.error('Error submitting the form:', error);
    alert('Error submitting the form. Please check the console for details.');
    
    // props.onHide();
  }finally{
    setShowProgressModal(false);
    setVideoProgress(0);
    fetchData();
    setVideo({}); // Assuming setVideo is the state updater for video
    setDescription('');
    setSelectedValue(''); 
  }
    // Implement the logic to save the video with the description
    //console.log('Video saved :', {
    //   id: selectedValue,
    //   name: description,
    //   video: video.file
    // });
  };
  
// useEffect(() => {
//   axios
//     .get(`${BASE_URL}/home_video_api.php`)
//     .then(response => {
//       const data = response.data;
//       //console.log(data );
//       // Update the formData state with the fetched data
//       setData(data || {});
//     })
//     .catch(error => {
//       console.error('Error fetching data:', error);
//     });
// }, []);
const handleDeleteRow = (id) => {
  axios.delete(`${BASE_URL}/home_video_api.php/?id=${id}`)
  .then(response => {
    // Handle success, e.g., update the state or show a success message
    //console.log('Team Member Deleted Successfully:', response.data);
    alert(' Video Deleted Successfully');
    // Perform any additional actions you need
  })
  .catch(error => {
    // Handle error, e.g., show an error message
    console.error('Error deleting blog:', error);
    // Perform any additional error handling or actions you need
  });
  const updatedData = data.filter(item => item.id !== id);
  setData(updatedData);
};
  


  return <div>
   <div className="col-md-3 mb-3">



      {/* Description Textarea */}
      <div>
     <textarea
      placeholder="Enter Your Youtube video Url"
      value={urls}
      onChange={handleUrlsChange}
      className="form-control mb-2"
    />
    {/* <video
      controls
      src={video.src}
      alt="Uploaded Video Preview"
      className="w-100"
      style={{ maxHeight: '120px', marginBottom: '5px' }}
    /> */}

    {/* Description Textarea */}
    <textarea
      placeholder="Add Name"
      value={description}
      onChange={handleDescriptionChange}
      className="form-control mb-2"
    />

    {/* Integer Selector */}
    <label htmlFor="integerSelector">Select a Slider id:</label>
    <select
      id="integerSelector"
      value={selectedValue}
      onChange={handleSelectChange}
      className="form-control mb-2"
    >
      <option value="">Select...</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      {/* Add more options as needed */}
    </select>

    {/* Remove Video Button */}
    <button
      onClick={handleRemoveVideo}
      className="btn btn-danger btn-sm w-100"
    >
      Remove
    </button>

    {/* Save Video Button */}
    <div className="py-2">
      <button
        className="btn btn-primary l p-3"
        onClick={handleSaveVideo}
        disabled={urls===''}
        // disabled={!video.src}
      >
        Save
      </button>
    </div>
  </div>
    </div>

   
    <table className="table table-bordered">
      <thead className="thead-dark">
        <tr>
        <th>Slide Id</th>
          <th>Slide Name</th>
          <th>Video Preview</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {Array.isArray(data) && data.length > 0 ? (   data.map((item) => (
          <tr key={item.id}>
            <td><p>{item.id}</p></td>
            <td><p>{item.name}</p></td>
            <td>
              <video
                src={item.video}
                alt={`Preview for slide ${item.slideNumber}`}
                className="img-fluid"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            </td>
            <td>
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteRow(item.id)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))): (
          <tr>
            <td colSpan="6">No Video available.</td>
          </tr>
        )}
      </tbody>
    </table>
  <button className="btn btn-primary l p-3">Save</button>
  <ProgressDialog show={showProgressModal} onHide={() => setShowProgressModal(false)} progress={videoProgress} />
  {/* <DialogBox show={showProgressModal} onClose={handleCloseProgressModal} progress={videoProgress} /> */}
  </div>;
};

export default SlideVideos;
