import React, { useState,useEffect } from 'react';
import { BsTrash } from 'react-icons/bs';
import axios from 'axios';
import BASE_URL from './constant/constant';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

const Todo = ({ todo, onDelete, onToggleComplete }) => {
  return (
    <tr>
      <td>
        <span style={{ textDecoration: todo.completed ? 'line-through' : 'none' }}>{todo.task_name}</span>
      </td>
      <td>
        <span>{todo.start_date}</span>
      </td>
      <td>
        <span>{todo.end_time}</span>
      </td>
      <td>
        <button className="btn btn-success" onClick={onToggleComplete}>
          {todo.completed ? 'Incomplete' : 'Complete'}
        </button>
      </td>
      <td>
        <BsTrash className="delete-icon" onClick={onDelete} />
      </td>
    </tr>
  );
};

const TodoList = ({ todos, onDelete, onToggleComplete }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Task</th>
          <th>Start Date</th>
          <th>End Time</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
    
      <tbody>
  {Array.isArray(todos) && todos.length > 0 ? (
    todos.map((todo, index) => (
      <Todo
        key={index}
        todo={todo}
        onDelete={() => onDelete(index)}
        onToggleComplete={() => onToggleComplete(index)}
      />
    ))
  ) : (
    <tr>
      <td colSpan="3">No todos available</td>
    </tr>
  )}
</tbody>


    </table>
  );
};

const TodoForm = ({ onAdd }) => {
  const [input, setInput] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endTime, setEndTime] = useState('');
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() !== '' && startDate.trim() !== '' && endTime.trim() !== '') {
      onAdd(
          {
              task_name: input,
              start_date: startDate,
              end_time: endTime,
              completed: false,
            
       });
     
      setInput('');
      setStartDate('');
      setEndTime('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-3">
    <div className="mb-3">
      <label htmlFor="task" className="form-label">
        Task
      </label>
      <input
        type="text"
        id="task"
        className="form-control"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Enter your task"
        required
      />
    </div>
  
    <div className="mb-3">
      <label htmlFor="startDate" className="form-label">
        Start Date
      </label>
      <input
        type="date"
        id="startDate"
        className="form-control"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        required
      />
    </div>
  
    <div className="mb-3">
      <label htmlFor="endTime" className="form-label">
        End Time
      </label>
      <input
        type="time"
        id="endTime"
        className="form-control"
        value={endTime}
        onChange={(e) => setEndTime(e.target.value)}
        required
      />
    </div>
  
    <button type="submit" className="btn btn-primary">
      Add Task
    </button>
  </form>
  
  );
};

const TodoApp = () => {
  const [todos, setTodos] = useState([ ]);
  const [newTask, setNewTask] = useState({
    task_name: '',
    start_date: '',
    end_time: '',
    completed: false,
  });
  const apiUrl = `${BASE_URL}/task_api.php`;
  useEffect(() => {
    // Replace 'YOUR_API_ENDPOINT' with the actual endpoint for fetching tasks
   

    
    fetchTasks();
  }, []); 
  const [showModal, setShowModal] = useState(false);
  const fetchTasks = async () => {
    try {
      const response = await axios.get(apiUrl);
      const data = response.data;

      // Assuming your API response has an array of tasks
      setTodos(data || []);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const createTask = async (newTask) => {
    //console.log(newTask);
    try {
      await axios.post(`${BASE_URL}/task_api.php`, newTask);
      fetchTasks();
      // Reset the newTask state for the next task
      // setNewTask({
      //   task_name: '',
      //   start_date: '',
      //   end_time: '',
      //   completed: false,
      // });
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  const updateTask = async (taskId, updatedTask) => {
    //console.log(taskId);
    //console.log(updatedTask);
    try {
      await axios.put(`${apiUrl}/?id=${taskId}`, updatedTask);
      fetchTasks();
    } catch (error) {
      console.error(`Error updating task ${taskId}:`, error);
    }
  };

  const deleteTask = async (taskId) => {
    //console.log(taskId);
    try {
      await axios.delete(`${apiUrl}/?id=${taskId}`);
      fetchTasks();
    } catch (error) {
      console.error(`Error deleting task ${taskId}:`, error);
    }
  };
  const addTodo = (todo) => {
    createTask(todo);
    setTodos([...todos, todo]);
    setShowModal(false);
  };

  const deleteTodo = (index) => {

    const newTodos = [...todos];
    deleteTask(todos[index].id);
    newTodos.splice(index, 1);
    setTodos(newTodos);
  };

  const toggleComplete = (index) => {
    console.log(index);
    console.log (todos[index].id);
    // const newTodos = [...todos];
    // updateTask((todos[index].id),{
    //  completed: !todos[index].completed
    // })
    // newTodos[index].completed = !newTodos[index].completed;
    // setTodos(newTodos);
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="todo-app">
    <br></br>
    <div className='row'>
    <div className='col-md-10'>
      <h2>Todo List</h2>
      </div>
      <div className='col-md-2'>
      <Button variant="primary" onClick={handleShow}>
        Add Todo
      </Button>
      </div>
</div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new Todo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TodoForm onAdd={addTodo} />
        </Modal.Body>
      </Modal>

      <TodoList todos={todos} onDelete={deleteTodo} onToggleComplete={toggleComplete} />
    </div>
  );
};

export default TodoApp;
