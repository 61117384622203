import React, { useState,useEffect } from 'react';
import BASE_URL from './constant/constant';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { BsPencil, BsCloudUpload, BsTrash } from 'react-icons/bs';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';

// Import the styles
function MyVerticallyCenteredModal(props) {
  const [customerPicture, setCustomerPicture] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [customerReview, setCustomerReview] = useState("");
  useEffect(() => {
    // Update the modal state when modalData prop changes
    if (props.isEditing) {
      const { client_image, client_name, review } = props.modalData;
      setCustomerPicture(client_image);
      setCustomerName(client_name);
      setCustomerReview(review);


 
   
  } else {
    // If it's a new member, reset the state
    setCustomerPicture(null);
    setCustomerName("");
    setCustomerReview("");
  }
}, [props.isEditing, props.modalData]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setCustomerPicture(URL.createObjectURL(file));
    }
  };
  async function convertBlobUrlToFile(blobUrl, filename) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
  
    // Create a File from the Blob
    const file = new File([blob], filename, { type: blob.type });
  
    return file;
  }
  const handleSave = async () => {
    try {
      const formData = new FormData();
      const timestamp = format(new Date(), 'yyyyMMddHHmmss');
      const filename = `review-${timestamp}.png`;
      const imageFile = await convertBlobUrlToFile(customerPicture, filename);
  
      //console.log("Converted Image File:", imageFile);
  
      formData.append('client_image', imageFile);
      formData.append('client_name', customerName);
      formData.append('review', customerReview);
  
      // Configure the Axios request
      const config = {
        method: 'post',
        url: `${BASE_URL}/testimonials_api.php`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };
  
      // Make the Axios request
      const response = await axios(config);
      //console.log('Response:', response.data);
  
      // Check if the response indicates success
      if (response.data) {
        // Optionally, update UI or redirect the user
        //console.log('Testemonial added successfully');
        alert('Testemonial added successfully');
        props.onHide();
      } 
      // else {
      //   console.error('Error adding testimonial:', response.data);
      //   alert('Error adding testimonial. Please check the console for details.');
      //   props.onHide();
      // }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form. Please check the console for details.');
      props.onHide();
    }
    
  };
  

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.isEditing ? "Edit Customer Details" : "Add a New Customer"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          {customerPicture ? (
            // If an image is selected, display the image preview
            <img
              src={customerPicture}
              alt="Customer Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "150px",
                borderRadius: "50%",
                margin: "auto",
                display: "block",
              }}
            />
          ) : (
            // If no image is selected, display a placeholder avatar
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: "#e0e0e0",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "24px", color: "#757575" }}>
                Picture
              </span>
            </div>
          )}
          <label htmlFor="upload" style={{ cursor: "pointer" }}>
            <BsCloudUpload size={32} />
            &nbsp; Upload Customer Picture
          </label>
          <input
            id="upload"
            type="file"
            accept="image/*"
            className="form-control"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
        <br />
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
        <br />
        <label>Review</label>
        <textarea
          className="form-control"
          value={customerReview}
          onChange={(e) => setCustomerReview(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSave}>
          Save
        </Button>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Review = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/testimonials_api.php//`)
      .then(response => {
        const data = response.data;

              //console.log(data);
              setTableData(data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });},[]);

  const handleAddNewMember = () => {
    setModalData(null);
    setIsEditing(false);
    setModalShow(true);
  };

  const handleEditMember = (id) => {
  
    if (id) {
      setModalData(id);
      setIsEditing(true);
      setModalShow(true);
    }
  };
  const handleRemoveClick = (id) => {
    axios.delete(`${BASE_URL}/testimonials_api.php/?id=${id}`)
    .then(response => {
      // Handle success, e.g., update the state or show a success message
      //console.log('Team Member Deleted Successfully:', response.data);
      alert('Testimonial Deleted Successfully');
      // Perform any additional actions you need
    })
    .catch(error => {
      // Handle error, e.g., show an error message
      console.error('Error deleting blog:', error);
      // Perform any additional error handling or actions you need
    });
    setTableData((prevData) => prevData.filter((member) => member.id !== id));
  };


  return (
    <>
      <div className="table-data">
        <div className="order">
          <div className="head">
            <h3>Customer Information</h3>
              {/* Add new Member button */}
      <Button variant="primary" onClick={handleAddNewMember}>
        Add New Member
      </Button>

          </div>
          
          <table>
            <thead>
              <tr>
                <th>Customer Picture</th>
                <th>Customer Name</th>
                <th>Customer Review</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((customer) => (
                <tr key={customer.id}>
                  <td>
                    <img src={customer.client_image} alt="user" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                  </td>
                  <td>{customer.client_name}</td>
                  <td>
                    <p>{customer.review}</p>
                  </td>
                  <td>
                    {/* <Button variant="primary" onClick={() => handleEditMember(customer)}>
                      <BsPencil />
                    </Button> */}
                    <Button variant="danger" style={{marginLeft:"4px"}} onClick={() => handleRemoveClick(customer.id)}>
                      <BsTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    
      <MyVerticallyCenteredModal

        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setIsEditing(false);
        }}
        isEditing={isEditing}
        modalData={modalData} 
      />
    </>
  );
};

export default Review;
