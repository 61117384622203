import React, { useState, useEffect } from 'react';
import "./App.css";
import { BrowserRouter , Route, Routes } from "react-router-dom";
import Main from "./Main";
import Profile from "./Profile";
import Settings from "./Settings";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css'; 
import { auth } from './firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const history = useNavigate();
  
function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    const lastLoginTimestamp = localStorage.getItem('lastLoginTimestamp');
    
    // Check if user was logged in within the last 5 minutes
    if (storedIsLoggedIn === 'true' && lastLoginTimestamp) {
      const currentTime = new Date().getTime();
      const sessionTimeout = 5 * 60 * 1000; // 5 minutes in milliseconds
      
      if (currentTime - parseInt(lastLoginTimestamp) < sessionTimeout) {
        setLoggedIn(true);
      } else {
        // Session expired, log out the user
        setLoggedIn(false);
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('lastLoginTimestamp');
      }
    } else {
      // No valid login session, log out the user
      setLoggedIn(false);
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('lastLoginTimestamp');
    }
  }, []);
   

  
  //Function to handle login
  const handleLogin = async () => {
    try {
      if (username === 'admiultravux@gmail.com') {
        const userCredential = await signInWithEmailAndPassword(auth, username, password);
        const user = userCredential.user;
  
         const loginTimestamp = new Date().getTime(); // Get current timestamp
      localStorage.setItem('lastLoginTimestamp', loginTimestamp);
      // Save isLoggedIn to local storage
      localStorage.setItem('isLoggedIn', true);
      console.log(new Date(loginTimestamp).toLocaleString()); // Log the login date and time
      alert('Login successful!');
      setLoggedIn(true);
      } else {
        setError('Kindly Enter Admin Id ');
      }
    } catch (error) {
      setError('Error Occurred. Please try again later.');
    }
  };
  
  // const handleLogin = () => {
  //   // Simple login logic (replace it with your authentication logic)
  //   if (username === 'demo' && password === 'password') {
  //     // Successful login
  //     const loginTimestamp = new Date().getTime(); // Get current timestamp
  //     localStorage.setItem('lastLoginTimestamp', loginTimestamp);
  //     // Save isLoggedIn to local storage
  //     localStorage.setItem('isLoggedIn', true);
  //     console.log(new Date(loginTimestamp).toLocaleString()); // Log the login date and time
  //     alert('Login successful!');
  //     setLoggedIn(true);
  //   } else {
  //     setError('Invalid username or password');
  //   }
  // };
  // Function to handle logout
  const handleLogout = () => {
    setLoggedIn(false);
    // Remove isLoggedIn from local storage
    localStorage.removeItem('isLoggedIn');
  };

 
  return (
    <>
      {!isLoggedIn ? (
        <div className="login-container">
          <div className="login-form">
            <img src="logo.png" style={{ height: "150px", margin: "auto" }} />
            <h2>Login</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="form-group">
              <label htmlFor="username">Email:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
      ) : (
        <Main isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      )}
    </>
  );
}

export default App;
