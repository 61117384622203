import React, { useState,useEffect } from 'react';

import { BsCloudUpload, BsX, BsImage  } from 'react-icons/bs';
import axios from 'axios';
import BASE_URL from './constant/constant';
import ProgressDialog from './dialog';

const VideoTestimonial = () => {
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [videoProgress, setVideoProgress] = useState(0);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const handleVideoChange = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      const updatedVideos = [...selectedVideos];
      const updatedDescriptions = [...descriptions];

      for (const file of files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          updatedVideos.push({ src: e.target.result, file });
          updatedDescriptions.push(''); // Add an empty description initially
          setSelectedVideos([...updatedVideos]);
          setDescriptions([...updatedDescriptions]);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const [data, setData] = useState([
      // Add more data as needed
    ]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/clients_videoreview_api.php`);
      const data = response.data;
      setData(data || {});
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
 const handleSaveVideo= async (index)=>{
  const updatedVideos = [...selectedVideos];
  const updatedDescriptions = [...descriptions];
  // //console.log({
  //   video:updatedVideos[index],
  //   details: updatedDescriptions[index]
  // })
  try {
    setShowProgressModal(true);
      const formData = new FormData(); 
      formData.append('Detail', updatedDescriptions[index]);
      formData.append('video', updatedVideos[index].file);

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setVideoProgress(percentCompleted);
      },
      method: 'post',
      url: `${BASE_URL}/clients_videoreview_api.php`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };

    // Make the Axios request
    const response = await axios(config);
    //console.log('Response:', response.data);

    // Check if the response indicates success
    if (response.data) {
      
      alert('Review Video Added successfully');
      
    } 
   
  } catch (error) {
    console.error('Error submitting the form:', error);
    alert('Error submitting the form. Please check the console for details.');
    // props.onHide();
  }finally{
    setShowProgressModal(false);
    setVideoProgress(0);
   fetchData();
  }

 };
  const handleRemoveVideo = (index,id) => {
   
   
    const updatedVideos = [...selectedVideos];
    const updatedDescriptions = [...descriptions];

    updatedVideos.splice(index, 1);
    updatedDescriptions.splice(index, 1);

    setSelectedVideos([...updatedVideos]);
    setDescriptions([...updatedDescriptions]);
  };

  const handleDescriptionChange = (index, event) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = event.target.value;
    setDescriptions([...updatedDescriptions]);
  };
  //  table
  // const [data, setData] = useState([
  //   // Add more data as needed
  // ]);
  // useEffect(() => {
  //   axios
  //     .get(`${BASE_URL}/clients_videoreview_api.php/`)
  //     .then(response => {
  //       const data = response.data;
  //       //console.log(data );
  //       // Update the formData state with the fetched data
  //       setData(data || {});
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);
  const handleDeleteRow = (id) => {
    axios.delete(`${BASE_URL}/clients_videoreview_api.php/?id=${id}`)
    .then(response => {
      // Handle success, e.g., update the state or show a success message
      //console.log('Team Member Deleted Successfully:', response.data);
      alert('Review Video Deleted Successfully');
      // Perform any additional actions you need
    })
    .catch(error => {
      // Handle error, e.g., show an error message
      console.error('Error deleting blog:', error);
      // Perform any additional error handling or actions you need
    });
    const updatedData = data.filter(item => item.id !== id);
    setData(updatedData);
  };

  return (
    <div>
      <br />
      <div className="row">
        <label
          htmlFor="videoInput"
          className="col-3 btn btn-success"
          style={{ cursor: 'pointer', marginBottom: '10px' }}
        >
          <span style={{ fontFamily: "'Poppins'", fontSize: "22px" }}>Upload Videos</span>
          <span className='' style={{ marginLeft: "8px" }}><BsCloudUpload style={{ marginRight: '8px' }} /></span>
        </label>
        {selectedVideos.length == 0 && (
          <div className="image-placeholder text-center">
          
        {/* <p>(Select Multiple Videos at Once)</p> */}
      </div>

        )}
         <input
          id="videoInput"
          type="file"
          accept="video/*"
          className="form-control col-12"
          style={{ display: 'none' }}
          onChange={handleVideoChange}
          multiple
        />
      </div>

      {/* Previews of the uploaded videos with descriptions */}
      <div className="row">
        {selectedVideos.map((video, index) => (
          <div key={index} className="col-md-3 mb-3">
            <video
              controls
              src={video.src}
              alt={`Uploaded Video Preview ${index + 1}`}
              className="w-100"
              style={{ maxHeight: '120px', marginBottom: '5px' }}
            />
            <textarea
              placeholder="Add description..."
              value={descriptions[index]}
              onChange={(event) => handleDescriptionChange(index, event)}
              className="form-control mb-2"
            />
           
            <button
              onClick={() => handleRemoveVideo(index)}
              className="btn btn-danger btn-sm w-100"
            >
              <BsX /> Remove
            </button>
            <div className='py-2'>
          <button className="btn btn-primary l p-3" onClick={() => handleSaveVideo(index)}>Save</button>
        </div>
          </div>
        ))}
      </div>
      {/* {selectedVideos.length > 0 && (
       
      )} */}
      <table className="table table-bordered">
      <thead className="thead-dark">
        <tr>
          <th>Description</th>
          <th>Video Preview</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {Array.isArray(data) && data.length > 0 ? (   data.map((item) => (
          <tr key={item.id}>
            {/* <td><p>{item.id}</p></td> */}
            <td><p>{item.Detail}</p></td>
            <td>
              <video
                src={item.video}
                alt={`Preview for slide ${item.slideNumber}`}
                className="img-fluid"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            </td>
            <td>
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteRow(item.id)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))): (
          <tr>
            <td colSpan="6">No Video available.</td>
          </tr>
        )}
        {/* {data.map((item) => (
          <tr key={item.id}>
            <td>  <p>{item.Detail}</p></td>
            <td>
              <video
                src={item.video}
                alt={`Preview for slide ${item.slideNumber}`}
                className="img-fluid"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            </td>
            <td>
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteRow(item.id)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))} */}
      </tbody>
    </table>
    <ProgressDialog show={showProgressModal} onHide={() => setShowProgressModal(false)} progress={videoProgress} />
    </div>

  );
};

export default VideoTestimonial;
