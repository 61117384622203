import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles

const Terms =()=>{
    // Text Editor
  const [content, setContent] = useState("");

  const handleChange = (value) => {
    setContent(value);
  };
  // Text Editor
return(
    <>
    <h2>Terms & Conditions</h2>
     <ReactQuill
              value={content}
              onChange={handleChange}
              className="py-3.5"
              theme="snow"
            />
    </>
)
}
export default Terms;