import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import axios from 'axios';
import BASE_URL from './constant/constant';

const NewUserTimelineChart = () => {
  const chartRef = useRef(null);
  const [registeredUsersData, setRegisteredUsersData] = useState([]);
  const [unregisteredUsersData, setunRegisteredUsersData] = useState([]);

  useEffect(() => {
    const fetchRegisteredUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user_api.php`);
        setRegisteredUsersData(response.data);
      } catch (error) {
        console.error('Error fetching registered users:', error);
      }
    };

    fetchRegisteredUsers();
  }, []);

  useEffect(() => {
    const fetchUnRegisteredUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/visitor.php`);
        setunRegisteredUsersData(response.data);
      } catch (error) {
        console.error('Error fetching unregistered users:', error);
      }
    };

    fetchUnRegisteredUsers();
  }, []);

  useEffect(() => {
    if (registeredUsersData.length === 0 || unregisteredUsersData.length === 0) return;

    const ctx = chartRef.current.getContext('2d');

    const joinDates = registeredUsersData.map((user) => {
      const date = new Date(user.joinedDate);
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    });

    const userCountByDay = joinDates.reduce((acc, date) => {
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const joinDates1 = unregisteredUsersData.map((user) => {
      const date = new Date(user.visit_date);
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    });

    const userCountByDay1 = joinDates1.reduce((acc, date) => {
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    let mergedLabels = [...new Set([...Object.keys(userCountByDay), ...Object.keys(userCountByDay1)])].sort();

    const mergedData = mergedLabels.map(date => ({
      registered: userCountByDay[date] || 0,
      unregistered: userCountByDay1[date] || 0
    }));

    const chartData = {
      labels: mergedLabels,
      datasets: [
        {
          label: 'Registered Users',
          data: mergedData.map(data => data.registered),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 2,
        },
        {
          label: 'Unregistered Users',
          data: mergedData.map(data => data.unregistered),
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 2,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    const myChart = new Chart(ctx, {
      type: 'line',
      data: chartData,
      options: options,
    });

    return () => {
      myChart.destroy();
    };
  }, [registeredUsersData, unregisteredUsersData]);

  return (
    <>
      <br />
      <div className='user-chart box-bg'>
        <h3>User Analytics</h3>
        <canvas ref={chartRef} />
      </div>
    </>
  );
};

export default NewUserTimelineChart;
