import React, { useState,useEffect } from "react";
import "./App.css";
import { BrowserRouter , Routes, Route, Link } from "react-router-dom";
import Profile from "./Profile";
import Settings from "./Settings"
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from "./Dashboard";
import Users from "./Users";
import Meeting from "./Meetings";
import Messages from "./Messages";
import Categories from "./ServicesPage";
import Support from "./Support";
import AddBlog from "./Blogs";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Team from "./Founder";
import Review from "./Review";
import Employee from "./Employee";
import Oders from "./Orders";
import Reports from "./Reports";
import SocialLinks from "./SocialLinks";
import AddService from "./AddService";
import VideoTestimonial from "./VideoTestimonial";
import SlideVideos from "./SlideVideos";
import TopClients from "./TopClients";
import TopRated from "./TopRated";
import SalesOrder from "./SalesOrders";
import AddCategory from "./AddCategory";
import { Button, Toast } from 'react-bootstrap';
import { BsBell } from 'react-icons/bs';
import { RiLogoutBoxRFill } from "react-icons/ri";
import { Dropdown } from 'react-bootstrap';
import { firestore,storage } from './firebase';
import { ToastContainer ,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {  addDoc, serverTimestamp , onSnapshot, collection, query, limit, orderBy, getDocs, deleteDoc } from "firebase/firestore";
import NewChat from "./NewChat";
// import Login from "./Login";


function Main({isLoggedIn,handleLogout }) {
  const [isActive, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  // const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  // useEffect(() => {
  //   const q = query(collection(firestore, "notifications"));

  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     snapshot.docChanges().forEach((change) => {
  //       if (change.type === "added") {
  //         const addedDoc = change.doc;
  //         const { message } = addedDoc.data();

  //         setNotificationMessage(message);
  //         setShowNotification(true);

  //         setTimeout(() => setShowNotification(false), 5000);
  //       }
  //       // Add additional logic for other types of changes if needed
  //     });
  //   });

  //   return () => unsubscribe();
  // }, []);
  useEffect(() => {
    const q = query(collection(firestore, "notifications"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added") {
         
          const addedDoc = change.doc;
          const { message } = addedDoc.data();
          console.log(addedDoc.data().message);
          // setNotificationMessage(addedDoc.data().message); 
          toast.success(addedDoc.data().message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setShowNotification(true);

          setTimeout(async () => {
            // setShowNotification(false);
            await deleteDoc(addedDoc.ref); // Delete the document after showing the notification
          }, 5000);
        }
        // Add additional logic for other types of changes if needed
      });
    });

    return () => unsubscribe();
  }, []);

  const toggleClass = () => {
    setActive(!isActive);
  };
  // const [userLoggedIn,setuserLogIn] = useState(isLoggedIn());
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const [showNotification, setShowNotification] = useState(false);

  const Notification = ({ show, onClose, message, variant }) => {
    return (
      <Toast show={show} onClose={onClose} delay={30000} autohide>
      <Toast.Header></Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
    );
  };

  const handleShowNotification = () => {
    toast.warn("No Notification for now", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });// Auto hide after 30 seconds
  };

  
  return (
    <div className="App">
      <section id="sidebar" className={isActive ? 'hide' : null}>
        <a href="/" className="brand">
         <img src="logo.png" style={{height:"50px", margin:"auto"}}/>
        </a>
        <ul className="side-menu top" style={{paddingLeft:"0px", marginTop:"10px"}}>
          <li className={activeTab === 1 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(1)}>
              <i className='bx bxs-dashboard' ></i>
              <span className="text">Dashboard</span>
            </a>
          </li>
          
          <li className={activeTab === 2 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(2)}>
              <i class='bx bxs-user'></i>
              <span className="text">Users</span>
            </a>
          </li>
          <li className={activeTab === 22 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(22)}>
            <i class='bx bx-bar-chart-square'></i>
              <span className="text">Sales & Orders</span>
            </a>
          </li>
          <li className={activeTab === 3 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(3)}>
            <i class='bx bxs-timer'></i>
              <span className="text">Meetings</span>
            </a>
          </li>
          <li className={activeTab === 4 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(4)}>
            <i class='bx bxs-inbox' ></i>
              <span className="text">Messages</span>
            </a>
          </li>
          <li className={activeTab === 16 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(16)}>
            <i class='bx bxs-category-alt'></i>
              <span className="text">Category</span>
            </a>
          </li>
          <li className={activeTab === 5 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(5)}>
            <i class='bx bx-category' ></i>
              <span className="text">Services</span>
            </a>
          </li>
          <li className={activeTab === 7 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(7)}>
            <i class='bx bxl-blogger' ></i>
              <span className="text">Blogs</span>
            </a>
          </li>
          <li className={activeTab === 8 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(8)}>
            <i class='bx bxs-comment'></i>
              <span className="text">Terms & Conditions</span>
            </a>
          </li>
          <li className={activeTab === 9 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(9)}>
            <i class='bx bx-book-open'></i>
              <span className="text">Privacy Policy</span>
            </a>
          </li>
          <li className={activeTab === 10 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(10)}>
            <i class='bx bxl-microsoft-teams' ></i>
              <span className="text">Team & Founder</span>
            </a>
          </li>
          <li className={activeTab === 11 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(11)}>
            <i class='bx bxs-comment-dots'></i>
              <span className="text">Testimonials</span>
            </a>
          </li>
          <li className={activeTab === 23 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(23)}>
            <i class='bx bxs-comment-dots'></i>
              <span className="text">5 Min Chat</span>
            </a>
          </li>
          <li className={activeTab === 12 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(12)}>
            <i class='bx bxs-user-circle'></i>
              <span className="text">Employee List</span>
            </a>
          </li>
          <li className={activeTab === 13 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(13)}>
            <i class='bx bx-book-open'></i>
              <span className="text">Order</span>
            </a>
          </li>
          <li className={activeTab === 14 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(14)}>
            <i class='bx bx-link-alt' ></i>
              <span className="text">Social Links</span>
            </a>
          </li>
          <li className={activeTab === 20 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(20)}>
            <i class='bx bxs-videos' ></i>
              <span className="text">Testmonial Video</span>
            </a>
          </li>
          <li className={activeTab === 17 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(17)}>
            <i class='bx bxs-video-recording' ></i>
              <span className="text">Slide Videos</span>
            </a>
          </li>
          <li className={activeTab === 18 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(18)}>
            <i class='bx bxs-user-badge' ></i>
              <span className="text">Client's</span>
            </a>
          </li>
          <li className={activeTab === 19 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(19)}>
            <i class='bx bx-right-top-arrow-circle' ></i>
              <span className="text">Top Rated</span>
            </a>
          </li>
          <li className={activeTab === 15 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(15)}>
            <i class='bx bx-shape-triangle'></i>
                          <span className="text">Reports</span>
            </a>
          </li>
          <li className={activeTab === 6 ? 'active' : ''}>
            <a href="#" onClick={() => handleTabClick(6)}>
            <i class='bx bxs-help-circle' ></i>
              <span className="text">Support & Help</span>
            </a>
          </li>
          {/* Add similar modifications for other tabs */}
        </ul>
        <ul className="side-menu">
          
          <li>
            <a href="#" className="logout"onClick={() => handleLogout(false) }>
              <i className='bx bxs-log-out-circle' ></i>
              <span className="text">Logout</span>
            </a>
          </li>
        </ul>
      </section>
       
      <section id="content">
        <nav>
          <i className='bx bx-menu' onClick={toggleClass}></i>
          <form action="#">
          </form>
          {/* <Notification
        show={showNotification}
        onClose={() => setShowNotification(false)}
        message={notificationMessage}
        variant="success"
      /> */}
          <Button variant="primary" onClick={handleShowNotification}>
        <BsBell size={20} className="mr-2" />
      </Button>
    
      <a href="#" className="profile">
            <img src="img_rectangle171.png" alt="profile" />
          </a>
          <Button variant="danger" onClick={() => handleLogout(false) }><RiLogoutBoxRFill /><span>Logout</span>
</Button>
        </nav>
        <ToastContainer />
        {/* <Notification
        show={showNotification}
        onClose={() => setShowNotification(false)}
        message="New messages"
        variant="success" // Change to 'danger', 'warning', or 'info' as needed
      /> */}
        <main>
        {activeTab === 1 && 
        <Dashboard/>
      }
      {activeTab === 2 && 
        <Users/>
      }
      {activeTab === 3 && 
        <Meeting/>
      }
      {activeTab === 4 && 
        <Messages/>
      }
      {activeTab === 5 && 
        <Categories/>
      }
      {activeTab === 6 && 
        <Support/>
      }
      {activeTab === 7 && 
        <AddBlog/>
      }
      {activeTab === 8 && 
        <Terms/>
      }
      {activeTab === 9 && 
        <Privacy/>
      }
      {activeTab === 10 && 
        <Team/>
      }
      {activeTab === 11 && 
        <Review/>
      }
      {activeTab === 12 && 
        <Employee/>
      }
      {activeTab === 13 && 
        <Oders/>
      }
      {activeTab === 14 && 
        <SocialLinks/>
      }
      {activeTab === 15 && 
        <Reports/>
      }
      {activeTab === 16 && 
        <AddCategory/>
      }
      {activeTab === 17 && 
        <SlideVideos/>
      }
      {activeTab === 18 && 
        <TopClients/>
      }
      {activeTab === 19 && 
        <TopRated/>
      }
      {activeTab === 20 && 
        <VideoTestimonial/>
      }
      {activeTab === 22 && 
        <SalesOrder/>
      }
      {activeTab === 23 && 
        <NewChat/>
      }
      {/* {activeTab === 30 && 
        <Login/>
      } */}
        </main>
      </section>
     

      <div>
       
      </div>

    </div>
    
  );
}

export default Main;
