import React, { useState,useEffect } from 'react';
import BASE_URL from './constant/constant';
import axios from 'axios';
import { Button, Modal, Form, Table, Dropdown,Row, Col } from 'react-bootstrap';

const Orders = () => {
  const [selectedCategory, setSelectedCategory] = useState('all'); // Initial category selection
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

   const [ordersData, setOrdersData] = useState([]);
   const [clientName, setclientName] = useState();
   const [selectedOrderId, setSelectedOrderId] = useState(null);
   const [status, setStatus] = useState('In Progress');
   const [searchTerm, setSearchTerm] = useState('');
   const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      // Reset ordersData to its original state if search term is empty
      fetchOrdersData(apiUrlStarted, setOrdersData);
    } else {
      // Filter ordersData based on user_email or user_name
      const filteredData = ordersData.filter(order => 
        order.user_email.toLowerCase().includes(searchTerm) || 
        order.user_name.toLowerCase().includes(searchTerm)
      );
      setOrdersData(filteredData);
    }
  };
  
  //  const handleSearch = (e) => {
  //   const searchTerm = e.target.value.toLowerCase();
  //   setSearchTerm(searchTerm);
  //   if (searchTerm === '') {
  //     // Reset ordersData to its original state if search term is empty
  //     fetchOrdersData(apiUrlStarted, setOrdersData);
  //   } else {
  //     // Filter ordersData based on user_email
  //     const filteredData = ordersData.filter(order => order.user_email.toLowerCase().includes(searchTerm));
  //     setOrdersData(filteredData);
  //   }
  // };
  
   const tableStyle = {
    border: '1px solid #828282',
    borderCollapse: 'collapse',
    width: '100%',
    borderRadius: '4px 0 0 0',
  };

  const cellStyle = {
    border: '1px solid #828282',
    padding: '20px',
  };

  const firstRowStyle = {
    backgroundColor: '#E5E5E5',
    border: '1px solid #828282',
    padding: '20px',
  };
  const [categories, setCategories] = useState([]);
 
  useEffect(() => {
  
     
  
      fetchOrdersData(apiUrlStarted, setOrdersData);
      
      
  }, []);
  const apiUrlStarted = `${BASE_URL}/api_orders.php`;
     
  
  const fetchOrdersData = async (apiUrl, setDataFunction) => {
      try {
          const response = await axios.get(apiUrl);
          const data = response.data;

          //console.log(data);
          setDataFunction(data || []);
      } catch (error) {
          console.error('Error fetching orders data:', error);
      }
  };
  useEffect(() => {
    // Assuming you have a service_name field in your data
    const allCategories = [...new Set(ordersData.map(order => order.service_name.toLowerCase()))];
    setCategories([...allCategories]);
  }, [ordersData]);
  const filteredTableData = ordersData.filter(
    (row) => row.user_email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  //   axios
  //     .get(`${BASE_URL}/api_orders.php/`)
  //     .then(response => {
  //       const data = response.data;
  //       //console.log(data );
  //       // Update the formData state with the fetched data
  //       setData(data || {});
  //       setCompleteData(data || {});
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);
  const handleEdit = (orderId) => {
    console.log(orderId);
    // const selectedOrder = ordersData.find((order) => order.id === orderId);
    if (orderId) {
      console.log("done");
      setFormValues(orderId);
      setSelectedOrderId(orderId.id);
      setShowModal(true);
    }
  };
  const handleNew = () => {
    // console.log(orderId);
    // const selectedOrder = ordersData.find((order) => order.id === orderId);
    // if (orderId) {
     
      setFormValues({
        service: '',
        category: '',
        budget: '',
        orderStartDate: '',
        orderEndDate: '',
        orderDetails: '',
        status: 'In Progress',
      });
      setSelectedOrderId(null);
      setShowModal(true);
    // }
  };
  const generateTableRows = (data) => {
    const keysToDisplay = ['name', 'user_name', 'user_email', 'user_phone', 'service_name', 'starting_date', 'delivering_date', 'price', 'status'];

    const defaultHeaderRow = ['Order Name', 'Client Name', 'Email', 'Phone', 'Service', 'Starting Date', 'Delivery Date', 'Price', 'Status', 'Actions'];
    
    // Extract the keys you want to display
    if (!Array.isArray(data)) {
        // If data is not an array, return an empty array
        return (
            <>
                {/* Default header row */}
                <tr>
                    {defaultHeaderRow.map((cellData, colIndex) => (
                        <th key={colIndex} style={firstRowStyle}>
                            {cellData}
                        </th>
                    ))}
                </tr>
                <tr key="mm">
                    {defaultHeaderRow.map((cellData, colIndex) => (
                        <th key={colIndex}>
                            No data available
                        </th>
                    ))}
                </tr>
            </>
        );
    }

    const filteredData = selectedCategory === 'all'
        ? data.filter(order => order.status === status)
        : data.filter(order => order.service_name.toLowerCase() === selectedCategory.toLowerCase() && order.status === status);

    return (
        <>
            {/* Default header row */}
            <tr>
                {defaultHeaderRow.map((cellData, colIndex) => (
                    <th key={colIndex} style={firstRowStyle}>
                        {cellData}
                    </th>
                ))}
            </tr>

            {/* Dynamic data rows */}
            {filteredData.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                    {keysToDisplay.map((key, colIndex) => (
                        <td key={colIndex} style={cellStyle}>
                            {rowData[key]}
                        </td>
                    ))}
                    <td>
                        <button onClick={() => handleEdit(rowData)} className="btn btn-primary">Edit</button>
                    </td>
                </tr>
            ))}
        </>
    );
};


  const handleButtonClick = (divToShow) => {

    let newStatus;
    switch (divToShow) {
      // case 'first':

      //   newStatus = 'Started';
      //   break;
      case 'first':

        newStatus = 'In Progress';
        break;
      case 'second':

        newStatus = 'Completed';
        break;
      default:

      newStatus = 'In Progress';
        break;
    }
    setStatus(newStatus);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    //console.log(file);
};
const [selectedFile, setSelectedFile] = useState(null);
  const [formValues, setFormValues] = useState({
    service: '',
    category: '',
    budget: '',
    orderStartDate: '',
    orderEndDate: '',
    orderDetails: '',
    status:"In Progress"
  });
  const [service,setServices]=useState("");
  const [service1,setServices1]=useState([]);
  
  const [service2,setServices2]=useState([]);
  const [service3,setServices3]=useState([]);
  const [service4,setServices4]=useState([]);
  const [initialData, setsersData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/user_api.php`)
      .then(response => {
        const data = response.data;

              //console.log(data);
              setsersData(data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
     
     
  }, []);
  const servicesArray = [
    "Programming & Tech",
    "Writing & Translation",
    "Video & Animation",
    "Graphics & Design",
];
useEffect(() => {
  //console.log(BASE_URL);
  axios.get(`${BASE_URL}/services_api.php?categories_id=1`)
    .then(res => {
      //console.log(res.data);
      setServices1(res.data); // Assuming the response has a services array
    })
    .catch(err => console.log(err));
}, []); 
useEffect(() => {
  //console.log(BASE_URL);
  axios.get(`${BASE_URL}/services_api.php?categories_id=2`)
    .then(res => {
      //console.log(res.data);
      setServices2(res.data); // Assuming the response has a services array
    })
    .catch(err => console.log(err));
}, []); 
useEffect(() => {
  //console.log(BASE_URL);
  axios.get(`${BASE_URL}/services_api.php?categories_id=3`)
    .then(res => {
      //console.log(res.data);
      setServices3(res.data); // Assuming the response has a services array
    })
    .catch(err =>console.log(err));
}, []); 
useEffect(() => {
  //console.log(BASE_URL);
  axios.get(`${BASE_URL}/services_api.php?categories_id=4`)
    .then(res => {
      //console.log(res.data);
      setServices4(res.data); // Assuming the response has a services array
    })
    .catch(err => console.log(err));
}, []); 
const onSOptionChangeHandler = (event) => {
  setFormValues({ ...formValues, service_name: event.target.value });
 
 
};
const handleSave = async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let response;
    if (selectedOrderId) {
      // Update existing order
      config.method = 'put';
      config.url = `${BASE_URL}/api_orders.php?id=${selectedOrderId}`;
      config.data = formValues;
      response = await axios(config);
    } else {
      // Create new order
      config.method = 'post';
      config.url = `${BASE_URL}/api_orders.php`;
      config.data = formValues;
      response = await axios(config);
    }

    if (response.data) {
      const formData = new FormData();
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");


      formData.append('client_id', formValues.client_id);
      formData.append('created_datetime', formattedDate);

      formData.append('order_name', formValues.name);   
       formData.append('file', selectedFile);
      const invoiceConfig = {
          method: 'post',
          url: `${BASE_URL}/invoices_api.php`,  // Replace with your actual API endpoint for inserting an invoice
          headers: {
              'Content-Type': 'multipart/form-data',  // Adjust the content type for file uploads
          },
          data: formData,  // Adjust this data based on your requirements
      };

      // Make the Axios request for inserting an invoice
      const invoiceResponse = await axios(invoiceConfig);

      // Check if the invoice insertion response indicates success
      if (invoiceResponse.data) {
          //console.log(invoiceResponse.data);
          // alert('Order and Invoice created successfully');
      } else {
          console.error('Error inserting invoice:', invoiceResponse.data);
          // alert('Error inserting invoice. Please check the console for details.');
      }
      alert(selectedOrderId ? 'Order Updated successfully' : 'Order Created successfully');
    }
  } catch (error) {
    console.error('Error submitting the form:', error);
    alert('Error submitting the form. Please check the console for details.');
  }

  setShowModal(false);
  setFormValues({
    service: '',
    category: '',
    budget: '',
    orderStartDate: '',
    orderEndDate: '',
    orderDetails: '',
    status: 'In Progress',
  });
  setSelectedOrderId(null);
  fetchOrdersData();
};

// const handleSave =async () => {
  
//   //console.log(formValues);
//   try {


//     const config = {
//       method: 'post',
//       url: `${BASE_URL}/api_orders.php`,
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       data: formValues,
//     };

//     // Make the Axios request
//     const response = await axios(config);
//     //console.log('Response:', response.data);

//     // Check if the response indicates success
//     if (response.data) {
//       // Optionally, update UI or redirect the user
//       //console.log('Social Links Updated successfully');
//       alert('Order Created successfully');
//       // props.onHide();
//     } 
//     // else {
//     //   console.error('Error adding testimonial:', response.data);
//     //   alert('Error adding testimonial. Please check the console for details.');
//     //   props.onHide();
//     // }
//   } catch (error) {
//     console.error('Error submitting the form:', error);
//     alert('Error submitting the form. Please check the console for details.');
//     // props.onHide();
//   }
//   setShowModal(false);
//   setFormValues({
//     service: '',
//     category: '',
//     budget: '',
//     orderStartDate: '',
//     orderEndDate: '',
//     orderDetails: '',
//     status:"In Progress"
//   });
//   //
// };
  
const getCountryById = (id) => {


  const matchingCountry = initialData.find(country => country.id === id);

  return matchingCountry ? matchingCountry.country : 'select Client First ';
};
  return (
    <>
      <div className="orders">
      
       
         <Row className="mb-3">
          <Col>
        <Button
          variant="success"
          className={`mr-5 mb-10 font-semibold min-w-[128px] rounded-[25px] text-center text-xl p-3 ${
            status === "In Progress"? 'bg-success text-white' : 'bg-white text-black'
          }`}
          onClick={() => handleButtonClick('first')}
        >
          In Progress
        </Button>
        <span>{'  '}</span>
        <span>{'  '}</span>
        <span>{'  '}</span>
        <span>{'  '}</span>
        <Button
          variant="success"
          className={`mb-10 font-semibold min-w-[128px] rounded-[25px] text-center text-xl p-3 ${
            status === "Completed" ? 'bg-success text-white' : 'bg-white text-black'
          }`}
          onClick={() => handleButtonClick('second')}
        >
          Completed
        </Button>
        
        <span>{'  '}</span>
        <span>{'  '}</span>
        <span>{'  '}</span>
        <span>{'  '}</span>
        <Button variant="danger" className='mb-10 font-semibold min-w-[128px] rounded-[25px] text-center text-xl p-3' onClick={handleNew}>
        Create Order
      </Button>
      <span>{'  '}</span>
        <span>{'  '}</span>
        <span>{'  '}</span>
        <span>{'  '}</span>
      </Col>
      <Col>
          <Form.Group controlId="searchInput">
            <Form.Label>Search by Client Name or Email:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter client name or email"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form.Group>
        </Col>
        <Col>
        <Dropdown style={{float:"right"}} onSelect={(category) => handleCategoryChange(category)}>
          <Dropdown.Toggle variant="success" id="category-dropdown">
            {selectedCategory === 'all' ? 'All Categories' : selectedCategory}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="all">All Categories</Dropdown.Item>
            {categories.map((category) => (
              <Dropdown.Item key={category} eventKey={category}>
                {category}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        </Col>
        </Row>
        <br></br>
        {/* Conditional rendering based on the state */}
        <div className='table-data' style={{ marginTop: '20px' }}>
          <Table bordered style={tableStyle}>
          <tbody>
              { generateTableRows(ordersData)}
            
            </tbody>
          </Table>
        </div>
        <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedOrderId ? "Update Order":"Create Order"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <form>
    <div className="mb-3">
    <div className='col-6'>
        <div className="mb-3">
      <label htmlFor="payment" className="form-label">Order Name</label>
      <input
        type="text"
        className="form-control"
        id="ordername"
        value={formValues.name}
        onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
      />
    </div>
        </div>
      <label htmlFor="services" className="form-label">Services List</label>
      <select
    className="form-select"
    id="service"
    value={service}
    onChange={(e) => {setFormValues({ ...formValues, category_id: e.target.value=="Programming & Tech"?1:e.target.value=="Writing & Translation"?2:e.target.value=="Video & Animation"?3:4 });
    setServices(e.target.value);
  }}
  >
    <option value="">Select a Service</option>
    {servicesArray.map((service) => (
      <option key={service} value={service}>{service}</option>
    ))}
  </select>
    </div>
    <div className="mb-3">
      <label htmlFor="category" className="form-label">SubService List</label>
      {/* <select
    className="form-select"
    id="category"
    value={formValues.category}
    onChange={(e) => setFormValues({ ...formValues, category: e.target.value })}
  > */}
      {service === "Programming & Tech" && (
  <select
  className="form-select"
  id="category"
  value={formValues.service_name}
    // className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
    onChange={onSOptionChangeHandler}
  >
    <option>Sub-Service</option>
    {service1.map((option) => (
      <option key={option.title}>{option.title}</option>
    ))}
  </select>
)}

{service === "Writing & Translation" && (
  <select
  className="form-select"
  id="category"
    value={formValues.service_name}
    // className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
    onChange={onSOptionChangeHandler}
  >
    <option>Sub-Service</option>
    {service2.map((option) => (
      <option key={option.title}>{option.title}</option>
    ))}
  </select>
)}

{service  === "Video & Animation" && (
  <select
  className="form-select"
  id="category"
  value={formValues.service_name}
    // className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
    onChange={onSOptionChangeHandler}
  >
    <option>Sub-Service</option>
    {service3.map((option) => (
      <option key={option.title}>{option.title}</option>
    ))}
  </select>
)}

{ service  === "Graphics & Design" && (
  <select
  className="form-select"
  id="category"
  value={formValues.service_name}
    // className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
    onChange={onSOptionChangeHandler}
  >
    <option>Sub-Service</option>
    {service4.map((option) => (
      <option key={option.title}>{option.title}</option>
    ))}
  </select>
)}

    </div>
    <div className='row'>
      <div className='col-6'>
      <div className="mb-3">
      <label htmlFor="clientName" className="form-label">Client's Name</label>
      <select
    className="form-select"
    id="clientName"
    value={formValues.client_id ?? " "}
    onChange={(e) => setFormValues({ ...formValues, client_id: e.target.value })}
  >
    <option value="">Client Name</option>
    {initialData.map((service) => (
      <option key={service.name} value={service.id}>{service.name}</option>
    ))}
  </select>
      {/* <input
        type="text"
        className="form-control"
        id="clientName"
        value={""}
        
      /> */}
    </div>
      </div>
      <div className='col-6'>
      <div className="mb-3">
      <label htmlFor="budget" className="form-label">Amount</label>
      <input
        type="text"
        className="form-control"
        id="budget"
        value={formValues.price}
        onChange={(e) => setFormValues({ ...formValues, price: e.target.value })}
      />
    </div>
      </div>

    </div>
      <div className='row'>
        <div className='col-6'>
        <div className="mb-3">
      <label htmlFor="country" className="form-label">Country</label>
      <input
        type="text"
        className="form-control"
        id="country"
        value={getCountryById(formValues.client_id)}
        onChange={(e) => setFormValues({ ...formValues, country: e.target.value })}
      />
    </div>
        </div>
        <div className='col-6'>
        <div className="mb-3">
      <label htmlFor="payment" className="form-label">Payment Method</label>
      <input
        type="text"
        className="form-control"
        id="payment"
        value={formValues.payment_mode}
        onChange={(e) => setFormValues({ ...formValues, payment_mode: e.target.value })}
      />
    </div>
        </div>
      </div>
    
    <div className='row'>
      <div className='col-6'>
      <div className="mb-3">
  <label htmlFor="orderStartDate" className="form-label">Order Start Date & Time</label>
  <input
    type="date"
    className="form-control"
    id="orderStartDate"
    value={formValues.starting_date}
    onChange={(e) => setFormValues({ ...formValues, starting_date: e.target.value })}
  />
</div>
      </div>
      <div className='col-6'>
        

<div className="mb-3">
  <label htmlFor="orderEndDate" className="form-label">Order End Date & Time</label>
  <input
    type="date"
    className="form-control"
    id="orderEndDate"
    value={formValues.delivering_date}
    onChange={(e) => setFormValues({ ...formValues,delivering_date: e.target.value })}
  />
</div>
      </div>

    </div>

    <div className="mb-3">
      <label htmlFor="orderDetails" className="form-label">Order Details</label>
      <textarea
        className="form-control"
        id="orderDetails"
        rows="3"
        value={formValues.add_on}
        onChange={(e) => setFormValues({ ...formValues, add_on: e.target.value })}
      ></textarea>
    </div>

    {selectedOrderId && (
  <div className="mb-3">
   <label htmlFor="orderStatus" className="form-label">Change Status</label>
<select
  className="form-select"
  id="orderStatus"
  value={formValues.status}
  onChange={(e) => setFormValues({ ...formValues, status: e.target.value })}
>
  <option value="In Progress">In Progress</option>
  <option value="Completed">Completed</option>
</select>
  </div>
)}
 <label>Upload Invoice</label>
  <input
            id="upload"
            type="file"
            accept=".pdf"
            className="form-control" 
            onChange={handleFileChange}           
          />
  </form>
</Modal.Body>
        <Modal.Footer>
        <Button variant="primarary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleSave}>
          {selectedOrderId ? "Update":"Create"}
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </>
  );
};

export default Orders;
