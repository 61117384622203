import React, { useState,useEffect } from 'react';
import { BsChatDots, BsPersonFill } from 'react-icons/bs';
import { firestore,storage } from './firebase';
import {  addDoc, serverTimestamp , onSnapshot, collection, query, limit, orderBy, getDocs} from "firebase/firestore";
import { Button, Row, Col, Container, ListGroup, Card, Modal } from 'react-bootstrap';
import "./App.css";
import axios from 'axios';
import BASE_URL from './constant/constant';
import { getStorage, ref, uploadString, getDownloadURL ,uploadBytesResumable } from 'firebase/storage';
import { IoDocuments } from "react-icons/io5";
import { FaImages } from "react-icons/fa";
import {  FaFile } from 'react-icons/fa';

const Support = () => {
  const [selectedUser, setSelectedUser] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState('');
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [document, setDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    //console.log(file);
};
  const handleModalShow = () => {
    setShowModal(true);
  };
  
  const handleModalClose = () => {
    setShowModal(false);
  };

  const sendMsg = async (e) => {
    e.preventDefault();
    if (image || document) {
      let file = image || document;
      let fileName = file.name;
      let fileRef = ref(storage, fileName);
      
      await uploadBytesResumable(fileRef, file).then(async (snapshot) => {
        const downloadURL = await getDownloadURL(fileRef);
        try {
          addDoc(collection(firestore, 'Support&Help', `${usersData1[selectedUser].fb_id}-Admin`,"chats"), {
                        text: msg,
                        createdAt: serverTimestamp(),
                        uid: "Admin",
            image: image ? downloadURL : null,
            document: document ? { name: fileName, url: downloadURL } : null
          });
         
        } catch (err) {
          console.log(err);
        }
      });
    } 
    else {
      const trimmedMsg = msg.trim();

      // Check if the trimmed message is empty
      if (!trimmedMsg) {
          // If the trimmed message is empty, return early
          return;
      }
       else{
        await addDoc(collection(firestore, 'Support&Help', `${usersData1[selectedUser].fb_id}-Admin`,"chats"), {
          text: msg,
          createdAt: serverTimestamp(),
          uid: "Admin"
        });
       
      }
    
    }
  
    setMsg('');
    setImage(null);
    setImagePreview(null);
    setDocument(null);
    setDocumentName(null);
  
   
  };
  
  const [usersData1,setUserData] = useState([]);
  const [items, setItems] = useState([]);
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const usersRef = collection(firestore, 'users');
        const usersSnapshot = await getDocs(usersRef);
  
        const uniqueMessagesData = [];
  
        await Promise.all(usersSnapshot.docs.map(async (userDoc) => {
          const userUid = userDoc.data().uid;
  
          const messagesRef = collection(firestore, 'Support&Help', `${userUid}-Admin`, 'chats');
          const messagesSnapshot = await getDocs(messagesRef);
  
          if (!messagesSnapshot.empty) {
            // Find the latest message based on createdAt
            const latestMessage = messagesSnapshot.docs.reduce((prev, current) => {
              return prev.data().createdAt.toMillis() > current.data().createdAt.toMillis() ? prev : current;
            });
  
            // Add the latest message to uniqueMessagesData
            uniqueMessagesData.push({
              user: `${userUid}-Admin`,
              ...latestMessage.data()
            });
          }
        }));
  
        // Sort uniqueMessagesData based on the 'createdAt' property in descending order
        uniqueMessagesData.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());
  
        // Log the sorted data
        console.log(uniqueMessagesData);
  
        // Set the data in state
        setItems(uniqueMessagesData); 
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
  
    fetchMessages();
  }, []);
  


  
  useEffect(() => {
    const fetchDataForUsers = async () => {
      try {
        // Extract unique user IDs from 'items'
        const userIds = items.map(item => item.user.split('-')[0]);
  
        // Use Axios to make requests for each user
        const axiosRequests = userIds.map(userId => (
          axios.get(`${BASE_URL}/user_api.php/?fb_id=${userId}`)
            .then(response => response.data)  // Assuming your API response is the data you need
            .catch(error => {
              console.error(`Error fetching data for user ${userId}:`, error);
              return null;  // Handle errors gracefully
            })
        ));
  
        // Wait for all Axios requests to complete
        const userDataArray = await Promise.all(axiosRequests);
        const filteredUserDataArray = userDataArray.filter(userData => userData && !userData.message);

        // Now you have an array of data for each user, you can handle it as needed
        setUserData(filteredUserDataArray);
        //console.log(filteredUserDataArray);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    if (items.length > 0) {
      fetchDataForUsers();
    }
  }, [items]);  // Make sure to include 'items' in the dependency array
  
  const [messages, setMessages] = useState([]);
// const userId = auth.currentUser.uid;

useEffect(() => {
  const fetchMessages = async () => {
    try {
      if (usersData1[selectedUser]) {
        const userId = usersData1[selectedUser].fb_id;
        const q = query(
          collection(firestore, 'Support&Help', `${userId}-Admin`, "chats"),
          orderBy("createdAt"),
          limit(100)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let messages = [];
          querySnapshot.forEach((doc) => {
            messages.push({ ...doc.data(), id: doc.id });
          });
          setMessages(messages);
        });

        return () => unsubscribe();
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  fetchMessages();
}, [selectedUser, usersData1]);

  
   

  const [previewImage, setPreviewImage] = useState(null);
  const handleUserClick = (userId) => {
    setSelectedUser(userId);
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setImage(selectedImage);

      // Create a temporary URL for image preview
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };
  const handleDocumentChange = (e) => {
    const selectedDocument = e.target.files[0];

    if (selectedDocument) {
      setDocument(selectedDocument);
      setDocumentName(selectedDocument.name);
      const nameParts = selectedDocument.name.split('.');
      const nameWithoutExtension = nameParts.slice(0, -1).join('.');
      const firstThreeCharacters = nameWithoutExtension.slice(0, 3); // Limit to 3 characters
      const extension = nameParts[nameParts.length - 1];
      setDocumentName(`${firstThreeCharacters}...${extension}`);
    }
  };
  const formatTimestamp = (timestamp) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Set to true to display in AM/PM format
    };
  
    return new Date(timestamp.toMillis()).toLocaleString(undefined, options);
  };

  
 
  return (
    <>
      <div className="container py-2 px-4">
        <div className="row rounded-lg overflow-hidden  shadow">
          {/* Users box */}
          <div className="col-2 px-0">
            <div className="bg-white">
              <div className="bg-gray px-4 py-2 bg-light">
                <p className="h5 mb-0 py-1">Recent</p>
              </div>

              <div className="messages-box scrollable-table-container">
                <div className="list-group rounded-0">
                {usersData1.map((user,index) => (
                    <a
                      key={user.id}
                      className={`list-group-item list-group-item-action ${user.id === selectedUser ? 'active text-white ylwbg' : 'list-group-item-light rounded-0'}`}
                      href="#"
                      onClick={() => handleUserClick(index)}
                    >
                      <div className="media">
                        <img
                          src={`${BASE_URL}/${user.profile_image}`}
                          alt="user"
                          width="30"
                          className="rounded-circle"
                        />
                        <div className="media-body ml-4">
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <h6 className="mb-0">{user.name}</h6>
                            <small className="small font-weight-bold">{user.date}</small>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Chat Box */}
          <div className="col-7 px-0">
           {/* Client name at the top */}
           <div className='box-bg'>
    <h5 className="text-center">
      {usersData1[selectedUser] && usersData1[selectedUser].name}
    </h5>
    </div>
  <div className="px-4 py-0 chat-box scrollable-table-container bg-white">
   
  {/* {messages && messages.map((message, id) => (
              <div key={id} className={`msg ${message.uid === usersData1[selectedUser].fb_id ? 'sent' : 'received'}`}>
               
                {message.uid === usersData1[selectedUser].fb_id ? (
                  <>
                    <div className="cursor-pointer leading-[normal] min-w-[202px] mt-1 rounded-br-[25px] rounded-tl-[25px] rounded-tr-[25px] text-center text-xs  p-5 bg-gray-200_04 text-gray-800_02">
                  
                      
                     
                      {message.image == null ? (
                        <p className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
                      ) : (
                        <img className="h-[100px]   w-[100px]" src={message.image} alt="User Image" />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="cursor-pointer leading-[normal] min-w-[202px] mt-1 rounded-br-[25px] rounded-tl-[25px] rounded-tr-[25px] text-right text-xs  p-5 bg-gray-200_04 text-gray-800_02">
                   
                      {message.image == null ? (
                        <p className="text-right text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
                      ) : (
                        <img  className="h-[100px]   w-[100px]" src={message.image} alt="Other User Image" />
                      )}
                    </div>
                  </>
                )}
              </div>
            ))} */}
    {/* Chat messages */}
    {messages.map((message) => (
  <div
    key={message.id}
    style={{ height: "500px" }}
    className={`media w-full mb-3 ${message.uid === usersData1[selectedUser].fb_id ? '' : 'ml-auto clinet'}`}
  >
    <img
      src={message.uid === usersData1[selectedUser].fb_id ? `${BASE_URL}/${usersData1[selectedUser].profile_image}` : "img_rectangle171.png"}
      alt="user"
      width="50"
      height="50"
      className="rounded-circle"
    />
    <div className="media-body ml-3">
      <div className={`bg-light rounded py-2 px-3 mb-2`}>
        {message.document ? (
          <div>
            <FaFile style={{ marginRight: '5px' }} /> {/* Document icon */}
            <a href={message.document.url} target="_blank" rel="noopener noreferrer">{message.document.name}</a>
          </div>
        ) : (
          <>
            {message.image == null ? (
              <p className="text-small mb-0 text-muted">{message.text}</p>
            ) : (
              <img
                width="150px"
                height="150px"
                src={message.image}
                alt="Message Image"
                onClick={() => setPreviewImage(message.image)}
              />
            )}
          </>
        )}
        <p className="text-small mb-0 text-muted">
          {message.createdAt && formatTimestamp(message.createdAt)}
        </p>
      </div>
    </div>
  </div>
))}
</div>
    {/* Typing area */}
    <form action="#" className="bg-light">
      <div className="input-group" >
        <input
          type="text"
          placeholder="Type a message"
          aria-describedby="button-addon2"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          className="form-control rounded-0 border-0 py-4 bg-light"
        />
          {/* document Button */}
          <div className="input-group-append" 
          style={{position: "absolute",
    right: "44px",
    top: "18px",     zIndex: "19999999"}}>
      <label htmlFor="fileUpload">
        <IoDocuments  style={{ height: '24px', width: '24px', marginTop: '5px', marginLeft: '5px', marginRight: '5px' }} />
      </label>
      
      <input
        type="file"
        id="fileUpload"
        accept=".pdf,.doc,.docx"
        onChange={handleDocumentChange}
        style={{ display: 'none' }}
      />
    </div>
    <div className="input-group-append" 
          style={{position: "absolute",
    right: "140px",
    top: "20px",     zIndex: "19999999"}}>
{documentName && <p className='mt-[10px] ml-[2px]'>{documentName}</p>}
    </div>
    {/* image Btn */}
    <div className="input-group-append" 
          style={{position: "absolute",
    right: "80px",
    top: "10px",     zIndex: "19999999"}}>
     
  <label htmlFor="imageUpload">
          <FaImages style={{ height: '24px', width: '24px', marginTop: '15px', marginLeft: '10px' }} />
        </label>
        <input
          type="file"
          id="imageUpload"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
    </div>

<div className="input-group-append" 
          style={{position: "absolute",
    right: "110px",
    top: "20px",     zIndex: "19999999"}}>
{imagePreview && <img class=""
     style={{height:"25px", width:"20px"}}
      src={imagePreview} alt="Image Preview" />}
</div>
{/* Send Btn */}
      <label htmlFor="fileUpload"></label>
        <div className="input-group-append" style={{position: "absolute",
    right: "0",
    top: "13px",     zIndex: "19999999"}}>
          <button type="submit" className="btn btn-link" style={{fontSize:"28px"}} onClick={sendMsg}>
          <i class='bx bx-send'></i>
          </button>
          
        </div>
      </div>
    </form>

</div>
<div className='col-md-3 px-0'>
<div className='box-bg'>
  <h5 className="text-center">Details:</h5>
</div>

{/* Client details */}
<div className='px-2' style={{fontFamily:"'Poppins'"}}>
  <p><strong>Name:</strong> {usersData1[selectedUser] && usersData1[selectedUser].name}</p>
  <p><strong>Email:</strong> {usersData1[selectedUser] && usersData1[selectedUser].email}</p>
  <p><strong>Phone Number:</strong> {usersData1[selectedUser] && usersData1[selectedUser].phone}</p>
  <p><strong>Country:</strong> {usersData1[selectedUser] && usersData1[selectedUser].country}</p>
  
</div>
</div>

<Modal show={previewImage !== null} onHide={() => setPreviewImage(null)}>
        <Modal.Body className="text-center">
          <img
            src={previewImage}
            alt="Preview"
            className="img-fluid"
          />
        </Modal.Body>
      </Modal>
        </div>
      </div>
    </>
  );
};

export default Support;
