import React, { useState ,useEffect} from 'react';
import { BsCloudUpload, BsX, BsImage } from 'react-icons/bs';
import axios from 'axios';
import BASE_URL from './constant/constant';
import { format } from 'date-fns';
const TopClients = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState('');
 
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage({ src: e.target.result, file });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };
 
  const handleSaveImage = async() => {
    const timestamp = format(new Date(), 'yyyyMMddHHmmss');
    const filename = `image-client-${timestamp}.png`;
    const imageFile = dataURLtoFile(selectedImage.src, filename);
    try {
   
      const formData = new FormData(); 
     
      formData.append('name', description);
      formData.append('image',imageFile );

    const config = {
      method: 'post',
      url: `${BASE_URL}/clients_api.php`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };

    // Make the Axios request
    const response = await axios(config);
    //console.log('Response:', response.data);

    // Check if the response indicates success
    if (response.data) {
      
      alert('Client Added successfully');
      setSelectedImage(null);
    } 
   
  } catch (error) {
    console.error('Error submitting the form:', error);
    alert('Error submitting the form. Please check the console for details.');
    setSelectedImage(null);
    // props.onHide();

  }
 
    // Implement the logic to save the video with the description
    //console.log('Video saved :', {
    //   // id: selectedValue,
    //   name: description,
    //   video: imageFile
    // });
  };
  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    const blob = new Blob([u8arr], { type: mime });
    const file = new File([blob], filename, { type: mime });
    
    return file;
  }
//  table
const [data, setData] = useState([

]);
useEffect(() => {
  axios
    .get(`${BASE_URL}/clients_api.php/`)
    .then(response => {
      const data = response.data;
      //console.log(data );
      // Update the formData state with the fetched data
      setData(data || {});
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
}, []);
const handleDeleteRow = (id) => {
 
    axios.delete(`${BASE_URL}/clients_api.php/?id=${id}`)
    .then(response => {
      // Handle success, e.g., update the state or show a success message
      //console.log('Team Member Deleted Successfully:', response.data);
      alert('Client Deleted Successfully');
      // Perform any additional actions you need
    })
    .catch(error => {
      // Handle error, e.g., show an error message
      console.error('Error deleting blog:', error);
      // Perform any additional error handling or actions you need
    });
  const updatedData = data.filter(item => item.id !== id);
  setData(updatedData);
};
 
  return (
    <div>
    <div className="col-md-3 mb-3">
      {selectedImage && (
        <div>
          <img
            src={selectedImage.src}
            alt="Uploaded Image Preview"
            className="w-100"
            style={{ maxHeight: '120px', marginBottom: '5px' }}
          />

          {/* Description Textarea */}
          <textarea
            placeholder="Add Name"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="form-control mb-2"
          />

          {/* Remove Image Button */}
          <button
            onClick={handleRemoveImage}
            className="btn btn-danger btn-sm w-100"
          >
            Remove
          </button>

          {/* Save Image Button */}
          <div className="py-2">
            <button
              className="btn btn-primary l p-3"
              onClick={handleSaveImage}
              disabled={!selectedImage}
            >
              Save
            </button>
          </div>
        </div>
      )}

      {/* Hidden input for selecting image */}
      <input
        type="file"
        id="imageInput"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />

      {/* Add Image Button */}
      {!selectedImage && (
        <button
          onClick={() => document.getElementById('imageInput').click()}
          className="btn btn-danger btn-sm w-100"
        >
          Add Image
        </button>
      )}
    </div>
 
  
       {/* table */}
       <table className="table table-bordered">
      <thead className="thead-dark">
        <tr>
          <th>Client Name</th>
          <th>Image Preview</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>
              <img
                src={item.image}
                alt={`Preview for ${item.name}`}
                className="img-fluid"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            </td>
            <td>
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteRow(item.id)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default TopClients;
