import React, { useState,useEffect } from 'react';
import { useSpring, animated } from "react-spring";
import WebsiteSessionsChart from "./SessionChart";
import ServicesSalesChart from "./ServiceSale";
import NewUserTimelineChart from "./UsersChart";
import ReviewsChart from "./ReviewsChart";
import TodoApp from "./Todo";
import BASE_URL from "./constant/constant";
import axios from "axios";
import { firestore,storage } from './firebase';
import { Table } from 'react-bootstrap';
import {  addDoc, serverTimestamp , onSnapshot, collection, query, limit, orderBy, getDocs} from "firebase/firestore";
import Image from 'react-bootstrap/Image';
import { BsArrowUp, BsArrowDown, BsCalendarCheck, BsGroup, BsDollarCircle } from 'react-icons/bs';
import { Bar } from 'react-chartjs-2';
// import { getToken, totalPageView } from './TrackingServices';
const Dashboard = () => {
  const [item,setItems]=useState({});
  


  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const usersRef = collection(firestore, 'users');
        const usersSnapshot = await getDocs(usersRef);
  
        const uniqueMessagesData = [];
  
        await Promise.all(usersSnapshot.docs.map(async (userDoc) => {
          const userUid = userDoc.data().uid;
  
          const messagesRef = collection(firestore, 'messages', `${userUid}-Admin`, 'chats');
          const messagesSnapshot = await getDocs(messagesRef);
  
          if (!messagesSnapshot.empty) {
            // Find the latest message based on createdAt
            const latestMessage = messagesSnapshot.docs.reduce((prev, current) => {
              return prev.data().createdAt.toMillis() > current.data().createdAt.toMillis() ? prev : current;
            });
  
            // Add the latest message to uniqueMessagesData
            uniqueMessagesData.push({
              user: `${userUid}-Admin`,
              // ...latestMessage.data()
            });
          }
        }));
  
        // Sort uniqueMessagesData based on the 'createdAt' property in descending order
        // uniqueMessagesData.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());
  
        // Log the sorted data
        console.log(uniqueMessagesData);
  
        // Set the data in state
        setItems(uniqueMessagesData); 
        setOrderData(prevOrderData => ({
          ...prevOrderData,
          totalLeads: uniqueMessagesData.length
        }));
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
  
    fetchMessages();
  }, []);
  

  const [ordersData,setOrdersData] = useState([
  ]);
  const [sale,setSale]=useState([]);
  useEffect(() => {
    const apiUrlStarted = `${BASE_URL}/api_orders.php`;
  
    const fetchOrdersData = async (apiUrl) => {
      try {
        const response = await axios.get(apiUrl);
        const data = response.data;
  
        // Get number of orders in each category_name
        const ordersByCategory = data.reduce((acc, order) => {
          const category = order.category_name;
  
          // Use the category as a key in the accumulator object
          if (!acc[category]) {
            acc[category] = 0;
          }
  
          // Increment the order count for the category
          acc[category]++;
  
          return acc;
        }, {});
  
        // Convert the result to an array of objects
        const ordersByCategoryArray = Object.entries(ordersByCategory).map(([category, orderCount]) => {
          //console.log(`Category: ${category}, Count: ${orderCount}`);
          return {
            category,
            orderCount,
          };
        });
  
        //console.log(ordersByCategoryArray);
       
        setSale(ordersByCategoryArray || []);
      } catch (error) {
        console.error('Error fetching orders data:', error);
      }
    };
  
    fetchOrdersData(apiUrlStarted);
  }, []);
  
  useEffect(() => {
  
    const apiUrlStarted = `${BASE_URL}/api_orders.php`;
   

    const fetchOrdersData = async (apiUrl, setDataFunction) => {
        try {
            const response = await axios.get(apiUrl);
            const data = response.data;

             // Filter and get the latest starting_date
             const latestStartingDate = data.reduce((latestDate, order) => {
              const orderStartingDate = new Date(order.starting_date);

              if (!latestDate || orderStartingDate > latestDate) {
                  return orderStartingDate;
              }

              return latestDate;
          }, null);

          // Only include orders with the latest starting_date
          const latestOrders = data.filter(order => new Date(order.starting_date).getTime() === latestStartingDate.getTime());

          //console.log(latestOrders);
          setDataFunction(latestOrders || []);
        } catch (error) {
            console.error('Error fetching orders data:', error);
        }
    };

    fetchOrdersData(apiUrlStarted, setOrdersData);
    
    
}, []);
// useEffect(() => {
//   const apiUrlOrders = `${BASE_URL}/api_orders.php`;

//   const fetchOrdersData = async (apiUrl, setDataFunction) => {
//       try {
//           const response = await axios.get(apiUrl);
//           const ordersData = response.data;

//           // Group orders by country
//           const ordersByCountry = ordersData.reduce((acc, order) => {
//               const country = order.user_country;

//               if (!acc[country]) {
//                   acc[country] = {
//                       users: new Set(),
//                       sales: 0,
//                       orderCount: 0,
//                   };
//               }

//               // Assuming there is a unique identifier for users, such as user ID
//               acc[country].users.add(order.client_id);

//               // Sum up sales
//               acc[country].sales += order.price;

//               // Count orders
//               acc[country].orderCount++;

//               return acc;
//           }, {});

//           // Calculate conversion rate
//           const dataWithConversionRate = Object.entries(ordersByCountry).map(([country, metrics]) => {
//               const conversionRate = (metrics.orderCount / metrics.users.size) * 100 || 0;

//               return {
//                   country,
//                   users: metrics.users.size,
//                   sales: metrics.sales,
//                   conversionRate: conversionRate.toFixed(2), // Convert to string with 2 decimal places
//               };
//           });

//           //console.log(dataWithConversionRate);
//           setDataFunction(dataWithConversionRate || []);
//       } catch (error) {
//           console.error('Error fetching orders data:', error);
//       }
//   };

//   fetchOrdersData(apiUrlOrders);

// }, []);

  const [recentLogins,setRecent] = useState([
  ]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/user_api.php//`)
      .then(response => {
        const data = response.data;

        const sortedMeetings = data.sort((a, b) => new Date(b.joinedDate) - new Date(a.joinedDate));

        // Take only the specified number of latest meetings
        const latestMeetings = sortedMeetings.slice(0, 3);
  
        //console.log(latestMeetings);
        setRecent(latestMeetings || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });}, []);

      const [orderData, setOrderData] = useState([]);

      useEffect(() => {
        const apiUrl = `${BASE_URL}/api_orders.php`;
    
        const fetchOrderData = async () => {
          try {
            const response = await axios.get(apiUrl);
            const data = response.data;
    
            // Calculate Total Leads
            // const totalLeads = item.length;
    
            // Calculate Monthly Orders and Monthly Sales
            const currentDate = new Date();
            const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    
            const monthlyOrders = data.filter(order => {
              const orderDate = new Date(order.starting_date);
              return orderDate >= startOfMonth && orderDate <= endOfMonth;
            }).length;
    
            const monthlySales = data.reduce((totalSales, order) => {
              const orderDate = new Date(order.starting_date);
              if (orderDate >= startOfMonth && orderDate <= endOfMonth) {
                return totalSales + parseFloat(order.price.replace(/\$/g, '') || 0);
              }
              return totalSales;
            }, 0);
            setOrderData(prevOrderData => ({
              ...prevOrderData,
              monthlyOrders,
              monthlySales: `$${monthlySales.toFixed(0)}`,
            }));
           
          } catch (error) {
            console.error('Error fetching order data:', error);
          }
        };
    
        fetchOrderData();
      }, []);
    
  // Chart animations
  const chartSpring = useSpring({
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { delay: 500, duration: 800 },
  });
  const [meetings, setMeetings] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/meetings_api.php`)
      .then(response => {
        const data = response.data;

        const sortedMeetings = data.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Take only the specified number of latest meetings
        const latestMeetings = sortedMeetings.slice(0, 3);
  
        //console.log(latestMeetings);
        setMeetings(latestMeetings || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });},[]);
// Sample data, replace it with your actual data

 const [todayVisitorCount, setTodayVisitorCount] = useState(0);
 const [VisitorCount, setVisitorCount] = useState(0);

 useEffect(() => {
   const today = new Date();
   const formattedDate = today.toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

   const apiUrl = `${BASE_URL}/visitor.php`;

   const fetchVisitorCount = async (apiUrl) => {
     try {
       const response = await axios.get(apiUrl);
     console.log(response.data);// Assuming your API response contains a key 'count' for today's visitor count
     
    setVisitorCount(response.data.length);
     
     const today = new Date();
     const todayDateString = today.toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
     
     const todayVisitors = response.data.filter(visitor => visitor.visit_date.startsWith(todayDateString));
     const todayVisitorCount = todayVisitors.length;
     setTodayVisitorCount(todayVisitorCount);
     } catch (error) {
       console.error('Error fetching visitor count:', error);
     }
   };

   fetchVisitorCount(apiUrl);
 }, []); //
 
 const [data, setDataFunction] = useState([]);

 useEffect(() => {
   const apiUrlOrders = `${BASE_URL}/api_orders.php`;
 
   const fetchOrdersData = async (apiUrl) => {
     try {
       const response = await axios.get(apiUrl);
       const ordersData = response.data;
 
       // Group orders by country
       const ordersByCountry = ordersData.reduce((acc, order) => {
         const country = order.user_country;
 
         if (!acc[country]) {
           acc[country] = {
             users: new Set(),
             sales: 0,
             orderCount: 0,
           };
         }
 
         // Assuming there is a unique identifier for users, such as user ID
         acc[country].users.add(order.client_id);
 
         // Sum up sales
         acc[country].sales += parseInt(order.price.replace(/\$/g, ''), 10) || 0;
 
         // Count orders
         acc[country].orderCount++;
 
         return acc;
       }, {});
 
       // Calculate conversion rate
       const dataWithConversionRate = Object.entries(ordersByCountry).map(([country, metrics]) => {
         const conversionRate = (metrics.orderCount / todayVisitorCount) * 100 || 0;
 
         return {
           country,
           users: metrics.users.size,
           sales: metrics.sales,
           conversionRate: conversionRate.toFixed(2), // Convert to string with 2 decimal places
         };
       });
 
       //console.log(dataWithConversionRate);
       setDataFunction(dataWithConversionRate || []);
     } catch (error) {
       console.error('Error fetching orders data:', error);
     }
   };
 
   fetchOrdersData(apiUrlOrders);
 }, [todayVisitorCount]); // Add todayVisitorCount as a dependency
 

 // Extract labels and conversion rates for chart
 const chartLabels = data.map((row) => row.country);
 const chartData = data.map((row) => row.conversionRate);

 const chartOptions = {
   scales: {
     y: {
       beginAtZero: true,
       max: 2, // Assuming conversion rate is between 0 and 1
     },
   },
 };
  return (
    <>
      <div className="head-title">
        <div className="left">
          <h1>Dashboard</h1>
        </div>
      </div>

      <ul className="box-info">
      <li>
          <i className='bx bxs-group' ></i>
          <animated.span style={chartSpring} className="text">
          <p style={{fontFamily:"'Poppins'"}}>Today Visitors</p>
            <h3 style={{fontFamily:"'Poppins'"}}>{todayVisitorCount}<BsArrowUp color="green" /></h3> 
          </animated.span>
        </li>
        <li>
          <i className='bx bxs-group' ></i>
          <animated.span style={chartSpring} className="text">
          <p style={{fontFamily:"'Poppins'"}}>Total Leads</p>
            <h3 style={{fontFamily:"'Poppins'"}}>{orderData.totalLeads}<BsArrowUp color="green" /></h3> 
          </animated.span>
        </li>
        <li>
          <i className='bx bxs-calendar-check' ></i>
          <div className="dashstat">
          <animated.span style={chartSpring} className="text">
          <p>Monthly Orders</p>
            <h3>{orderData.monthlyOrders} <BsArrowUp color="green" /></h3>
          </animated.span>
          </div>
        </li>      
        <li>
          <i className='bx bxs-dollar-circle'  style={{background:"rgb(152, 242, 182)"}}></i>
          <animated.span style={chartSpring} className="text">
          <p style={{fontFamily:"'Poppins'"}}>Monthly Sales </p>
          <h3 style={{fontFamily:"'Poppins'"}}>{orderData.monthlySales} <BsArrowDown color="red" /></h3>    
          </animated.span>
        </li>
      </ul>

      <div>
      <div className="">
        <div className="col-md-12">
        <NewUserTimelineChart
         registeredUserData={[50, 80, 60 ]}
         unregisteredUserData={[90, 120, 80]} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 mt-3" >
        <div className="head">
      <h3 style={{ fontFamily: "'Poppins'" }}>Recent Users</h3>
      <div className="scrollable-table-container" style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <Table className="table" striped bordered hover responsive style={{ backgroundColor: 'white' }}>
          <thead>
            <tr>
              <th style={{ backgroundColor: 'green', color: 'white' }}>Profile Pic</th>
              <th style={{ backgroundColor: 'green', color: 'white' }}>Username</th>
              <th style={{ backgroundColor: 'green', color: 'white' }}>Country</th>
              <th style={{ backgroundColor: 'green', color: 'white' }}>Registerd Time</th>
            </tr>
          </thead>
          <tbody>
            {recentLogins.map((login) => (
              <tr key={login.id}>
                <td>
                  <Image src={`${BASE_URL}/${login.profile_image}`} roundedCircle style={{ width: '30px', height: '30px' }} />
                </td>
                <td>{login.name}</td>
                <td>{login.country}</td>
                <td>{new Date(login.joinedDate).toLocaleString('en-US', { hour12: true })}</td>

              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
        </div>
        <div className="col-md-5 mt-5">
        <WebsiteSessionsChart /> 
        </div>
      </div>
     
      </div>
      <div className="row box-bg" style={{ marginTop:"10px", marginTop:"20px"}}>
        <div className="col-md-6">
        <div className="mt-4">
        <Bar data={{ labels: chartLabels, datasets: [{ data: chartData, backgroundColor: 'rgb(244, 132, 145)' }] }} options={chartOptions} />
      </div>
        </div>
        <div className="col-md-6">
        <div className="container mt-2" style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <table className="table">
        <thead>
          <tr>
            <th style={{background:"#ffca00"}}>Country</th>
            <th style={{background:"#ffca00"}}>Users</th>
            <th style={{background:"#ffca00"}}>Sales</th>
            <th style={{background:"#ffca00"}}>Conversion Rate</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.country}</td>
              <td>{row.users}</td>
              <td>{row.sales}<strong>$</strong></td>
              <td>{row.conversionRate}  <span className="ml-2">
                  {row.conversionRate >= data[index - 1]?.conversionRate ? (
                    <BsArrowUp color="green" />
                  ) : (
                    <BsArrowDown color="red" />
                  )}
                </span></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </div>
      </div>
      <div className="table-data">
        <div className="order">
          <div className="head">
            <h3>Recent Orders</h3>
          </div>
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Date Order</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {ordersData.map((order) => (
                <tr key={order.id}>
                  <td>
                    {/* <img src={order.userImage} alt="user" /> */}
                    <p>{order.user_name}</p>
                  </td>
                  <td>{order.starting_date}</td>
                  <td>
                    <span className={`status ${order.status.toLowerCase()}`}>
                      {order.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
<br></br>
      <div className="row">
        
        <div className="col-md-5">
        <ServicesSalesChart
         sale={sale||[ { category: 'Category1', count: 10 },
         { category: 'Category2', count: 20 },]} />
        </div>
        <div className="col-md-7"> <div className="todo">
        <div className="head">
          <h3>Recent Meetings</h3>
        </div>
        <div className="scrollable-table-container" style={{ maxHeight: '350px', overflowY: 'auto' }}>
        <table className="data-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Meeting Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {meetings.map((meeting) => (
              <tr key={meeting.id}>
                <td>{meeting.client_name}</td>
                <td>{meeting.client_email}</td>
                <td>{meeting.phone}</td>
                <td>{meeting.scheduled_date} & {meeting.scheduled_time}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div></div>
      </div>
      <TodoApp />
    </>
  );
};

export default Dashboard;
