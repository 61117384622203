import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BASE_URL from "./constant/constant";
import axios from "axios";

const MeetingDetailsPopup = ({ meetingData, onClose, onUpdateStatus }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleStatusChange = async () => {
    // Assume you have a function onUpdateStatus that updates the meeting status
    const apiUrl = BASE_URL+'/meetings_api.php/?id=' + meetingData.id; // Replace with your actual API URL
  const statusUpdate = { meetingStatus: "Completed" }; // Replace with your desired update

  try {
    const response = await axios.post(apiUrl, statusUpdate);
    onUpdateStatus(meetingData.id, 'NewStatus');
    //console.log('Update successful:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating meeting status:', error.response.data);
    throw error.response.data;
  }
    
    // setShow(false); // Replace 'NewStatus' with the desired new status
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Meeting Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <p>ID: {meetingData.id}</p> */}
        <p>Client Name: {meetingData.client_name}</p>
        <p>Phone: {meetingData.phone}</p>
        <p>Email: {meetingData.client_email}</p>
        <p>Scheduled Date: {meetingData.scheduled_date}</p>
        <p>Scheduled Time: {meetingData.scheduled_time}</p>
        <p>Category ID: {meetingData.category_id}</p>
        <p>Service Name: {meetingData.service_name}</p>
        <p>Budget: {meetingData.budget}</p>
        <p>Currency: {meetingData.currency}</p>
        <p>Message: {meetingData.message}</p>
        <p>Created At: {meetingData.created_at}</p>
        <p>Meeting Status: {meetingData.meetingStatus}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleStatusChange}>
          Change Status
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MeetingDetailsPopup;
