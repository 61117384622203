import React, { useState,useEffect } from 'react';
import BASE_URL from './constant/constant';
import axios from 'axios';
import { BsWhatsapp, BsTelephone, BsEnvelope, BsGeoAlt, BsFacebook, BsInstagram, BsLinkedin, BsX, BsYoutube } from 'react-icons/bs';

const SocialLinks = () => {

  const [formData, setFormData] = useState({
    contact_no: '',
    whatsapp_no: '',
    address: '',
    email: '',
    fb: '',
    insta: '',
    twitter: '',
    linkedin: '',
    youtube: '',
  });

  useEffect(() => {
    axios
      .get(`${BASE_URL}/general_settings.php/`)
      .then(response => {
        const data = response.data;
        //console.log(data );
        // Update the formData state with the fetched data
        setFormData(data || {});
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSave =async () => {
    try {
   
  
      const config = {
        method: 'post',
        url: `${BASE_URL}/general_settings.php`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      };
  
      // Make the Axios request
      const response = await axios(config);
      //console.log('Response:', response.data);
  
      // Check if the response indicates success
      if (response.data) {
        // Optionally, update UI or redirect the user
        //console.log('Social Links Updated successfully');
        alert('Social Links Updated successfully');
        // props.onHide();
      } 
      // else {
      //   console.error('Error adding testimonial:', response.data);
      //   alert('Error adding testimonial. Please check the console for details.');
      //   props.onHide();
      // }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form. Please check the console for details.');
      // props.onHide();
    }
    //console.log(formData);
  };
  return (
 
  <>
      <div className="container mt-5">
        <h3 className="font-weight-bold">Social Media Links</h3>
        <div className="row">
          {/* WhatsApp */}
          <div className="col-md-6 mt-4">
            <div className="input-group">
              <span className="input-group-text"><BsWhatsapp /></span>
              <input
                className="form-control"
                type="text"
                placeholder="WhatsApp Number"
                value={formData.whatsapp_no}
                onChange={(e) => handleInputChange('whatsapp_no', e.target.value)}
              />
            </div>
          </div>
          {/* Call Number */}
          <div className="col-md-6 mt-4">
            <div className="input-group">
              <span className="input-group-text"><BsTelephone /></span>
              <input
                className="form-control"
                type="text"
                placeholder="Call Number"
                value={formData.contact_no}
                onChange={(e) => handleInputChange('contact_no', e.target.value)}
              />
            </div>
          </div>
          {/* Email */}
          <div className="col-md-6 mt-4">
            <div className="input-group">
              <span className="input-group-text"><BsEnvelope /></span>
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
              />
            </div>
          </div>
          {/* Address */}
          <div className="col-md-6 mt-4">
            <div className="input-group">
              <span className="input-group-text"><BsGeoAlt /></span>
              <input
                className="form-control"
                type="text"
                placeholder="Address"
                value={formData.address}
                onChange={(e) => handleInputChange('address', e.target.value)}
              />
            </div>
          </div>
          {/* Facebook */}
          <div className="col-md-6 mt-4">
            <div className="input-group">
              <span className="input-group-text"><BsFacebook /></span>
              <input
                className="form-control"
                type="text"
                placeholder="Facebook"
                value={formData.fb}
                onChange={(e) => handleInputChange('fb', e.target.value)}
              />
            </div>
          </div>
          {/* Instagram */}
          <div className="col-md-6 mt-4">
            <div className="input-group">
              <span className="input-group-text"><BsInstagram /></span>
              <input
                className="form-control"
                type="text"
                placeholder="Instagram"
                value={formData.insta}
                onChange={(e) => handleInputChange('insta', e.target.value)}
              />
            </div>
          </div>
          {/* LinkedIn */}
          <div className="col-md-6 mt-4">
            <div className="input-group">
              <span className="input-group-text"><BsLinkedin /></span>
              <input
                className="form-control"
                type="text"
                placeholder="LinkedIn"
                value={formData.linkedin}
                onChange={(e) => handleInputChange('linkedin', e.target.value)}
              />
            </div>
          </div>
          {/* X */}
          <div className="col-md-6 mt-4">
            <div className="input-group">
              <span className="input-group-text"><BsX /></span>
              <input
                className="form-control"
                type="text"
                placeholder="X"
                value={formData.twitter}
                onChange={(e) => handleInputChange('twitter', e.target.value)}
              />
            </div>
          </div>
          {/* YouTube */}
          <div className="col-md-6 mt-4">
            <div className="input-group">
              <span className="input-group-text"><BsYoutube /></span>
              <input
                className="form-control"
                type="text"
                placeholder="Youtube"
                value={formData.youtube}
                onChange={(e) => handleInputChange('youtube', e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* Save Button */}
        <button className="btn btn-primary mt-5" onClick={handleSave}>
          Save
        </button>
      </div>
    </>
  );
};

export default SocialLinks;
// const SocialLinks = () => {
//   const [formData, setFormData] = useState({
//     contact_no: '',
//     whatsapp_no: '',
//     address: '',
//     email: '',
//     fb: '',
//     insta: '',
//     twitter: '',
//     linkedin: '',
//     youtube: '',
//   });

//   useEffect(() => {
//     axios
//       .get(`${BASE_URL}/general_settings.php/`)
//       .then(response => {
//         const data = response.data;

//         // Update the formData state with the fetched data
//         setFormData(data || {});
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//   return (
//     <>
//       <div className="container mt-5">
//         <h3 className="font-weight-bold">Social Media Links</h3>
//         <div className="row">
//           {/* Render input fields with values from formData state */}
//           {Object.entries(formData).map(([key, value]) => (
//             <div className="col-md-6 mt-4" key={key}>
//               <div className="input-group">
//                 <span className="input-group-text">
//                   {key === 'contact_no' || key === 'whatsapp_no' ? (
//                     <BsTelephone />
//                   ) : key === 'address' ? (
//                     <BsGeoAlt />
//                   ) : key === 'email' ? (
//                     <BsEnvelope />
//                   ) : key === 'fb' ? (
//                     <BsFacebook />
//                   ) : key === 'insta' ? (
//                     <BsInstagram />
//                   ) : key === 'twitter' ? (
//                     <BsX />
//                   ) : key === 'linkedin' ? (
//                     <BsLinkedin />
//                   ) : key === 'youtube' ? (
//                     <BsYoutube />
//                   ) : null}
//                 </span>
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
//                   value={value}
//                   onChange={(e) => setFormData(prevData => ({ ...prevData, [key]: e.target.value }))}
//                 />
//               </div>
//             </div>
//           ))}
//         </div>
//         {/* Save Button */}
//         <button className="btn btn-primary mt-5">
//           Save
//         </button>
//       </div>
//     </>
//   );
// };

// export default SocialLinks;