import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import BASE_URL from "./constant/constant";
import axios from "axios";

const WebsiteSessionsChart = () => {
  const [osVisitorCounts, setOsVisitorCounts] = useState({});
  const [osChartData, setOsChartData] = useState(null);

  useEffect(() => {
    const apiUrl = `${BASE_URL}/visitor.php`;

    const fetchVisitorData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const visitors = response.data;

        const osCounts = {};

        visitors.forEach(visitor => {
          // Normalize Windows OS versions
          let os = visitor.os.toLowerCase();
          if (os.includes('windows')) {
            os = 'windows';
          }
          osCounts[os] = osCounts[os] ? osCounts[os] + 1 : 1;
        });

        setOsVisitorCounts(osCounts);
      } catch (error) {
        console.error('Error fetching visitor data:', error);
      }
    };

    fetchVisitorData();
  }, []);

  useEffect(() => {
    if (osVisitorCounts) {
      const labels = ['Sessions'];
      const datasets = [];

      Object.entries(osVisitorCounts).forEach(([os, count]) => {
        const randomColor = getRandomColor(); // Generate random color
        const dataset = {
          label: os,
          data: [count],
          backgroundColor: randomColor,
          borderColor: randomColor,
          borderWidth: 1,
        };

        datasets.push(dataset);
      });

      const data = { labels, datasets };
      setOsChartData(data);
    }
  }, [osVisitorCounts]);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      datalabels: {
        formatter: (value) => value,
      },
    },
  };

  // Function to generate a random RGB color
  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 0.7)`;
  };

  return (
    <div className='visitor-chart box-bg'>
      <h3>Sessions by Device</h3>
      {osChartData && <Bar data={osChartData} options={options} plugins={[ChartDataLabels]} />}
    </div>
  );
};

export default WebsiteSessionsChart;
