import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { BsPencil, BsTrash } from 'react-icons/bs';
import BASE_URL from './constant/constant';
import axios from 'axios';
import { format } from 'date-fns';

const Employee = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/employee.php`)
      .then(response => {
        setEmployees(response.data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const openEditModal = (employee) => {
    setCurrentEmployee(employee);
    setIsEditing(true);
    setModalShow(true);
  };

  const openAddNewModal = () => {
    setCurrentEmployee(null);
    setIsEditing(false);
    setModalShow(true);
  };

  const handleSaveChanges = async () => {
    try {
      let formData = new FormData();
      if (currentEmployee.picture) {
        const timestamp = format(new Date(), 'yyyyMMddHHmmss');
        const filename = `employee-${timestamp}.png`;
        const imageFile = await convertBlobUrlToFile(currentEmployee.picture, filename);
        formData.append('picture', imageFile);
      }
      formData.append('name', currentEmployee.name);
      formData.append('designation', currentEmployee.designation);
      formData.append('joining_date', currentEmployee.joining_date);

      let response;
      if (isEditing) {
        response = await axios.put(`${BASE_URL}/employee.php/?id=${currentEmployee.id}`, formData);
      } else {
        response = await axios.post(`${BASE_URL}/employee.php`, formData);
      }

      if (response.data) {
        alert('Employee ' + (isEditing ? 'updated' : 'added') + ' successfully');
        setEmployees(response.data);
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form. Please check the console for details.');
    }
    setModalShow(false);
  };

  const handleRemoveEmployee = async (employeeId) => {
    try {
      const response = await axios.delete(`${BASE_URL}/employee.php/?id=${employeeId}`);
      if (response.data) {
        alert('Employee deleted successfully');
        setEmployees(employees.filter(employee => employee.id !== employeeId));
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
      alert('Error deleting employee. Please check the console for details.');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentEmployee(prevEmployee => ({
        ...prevEmployee,
        picture: URL.createObjectURL(file),
      }));
    }
  };

  const handleInputChange = (field, value) => {
    setCurrentEmployee(prevEmployee => ({
      ...prevEmployee,
      [field]: value,
    }));
  };

  async function convertBlobUrlToFile(blobUrl, filename) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], filename, { type: blob.type });
  }

  return (
    <>
      <div className="table-data">
        <div className="order">
          <div className="head">
            <h3>Employee Information</h3>
            <Button variant="success" onClick={openAddNewModal}>
              Add New Employee
            </Button>
          </div>
          <table>
            <thead>
              <tr>
                {/* <th>Employee ID</th> */}
                <th>Employee Picture</th>
                <th>Employee Name</th>
                <th>Designation</th>
                <th>Joining Date</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
            {Array.isArray(employees) && employees.length > 0 ? (
  employees.map((employee) => (
    <tr key={employee.id}>
      {/* <td>{employee.id}</td> */}
      <td>
        <img
          src={employee.picture}
          alt={employee.name}
          style={{ width: '50px', height: '50px', borderRadius: '50%' }}
        />
      </td>
      <td>{employee.name}</td>
      <td>{employee.designation}</td>
      <td>{employee.joining_date}</td>
      <td>
        <Button variant="primary" onClick={() => openEditModal(employee)}>
          <BsPencil />
        </Button>
        <Button variant="danger" style={{ marginLeft: '4px' }} onClick={() => handleRemoveEmployee(employee.id)}>
          <BsTrash />
        </Button>
      </td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No employees available.</td>
  </tr>
)}

            </tbody>
          </table>
        </div>
      </div>

      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit Employee Details' : 'Add a New Employee'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPicture">
              <Form.Label>Employee Picture</Form.Label>
              <div>
                {currentEmployee?.picture ? (
                  <>
                    <img
                      src={currentEmployee?.picture}
                      alt="Employee Preview"
                      style={{ width: '50px', height: '50px', marginRight: '5%', borderRadius: '50%' }}
                    />
                    <label htmlFor="upload" className="btn btn-outline-primary" style={{ cursor: 'pointer' }}>
                      Click to change
                    </label>
                  </>
                ) : (
                  <label htmlFor="upload" className="btn btn-outline-primary" style={{ cursor: 'pointer' }}>
                    Upload Employee Picture
                  </label>
                )}
                <input
                  id="upload"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={currentEmployee?.name || ''}
                onChange={(e) => handleInputChange('name', e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formDesignation">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                value={currentEmployee?.designation || ''}
                onChange={(e) => handleInputChange('designation', e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formJoiningDate">
              <Form.Label>Joining Date</Form.Label>
              <Form.Control
                type="text"
                value={currentEmployee?.joining_date || ''}
                onChange={(e) => handleInputChange('joining_date', e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setModalShow(false)}>
            Close
          </Button>
          <Button variant="success" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Employee;
