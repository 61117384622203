
import React, { useState,useEffect } from "react";
import ReactQuill from "react-quill";
import axios from 'axios';
import { BsCloudUpload, BsX } from 'react-icons/bs';
import BASE_URL from './constant/constant';
import "react-quill/dist/quill.snow.css"; // Import the styles
import { Button, Modal } from 'react-bootstrap';
import ProgressDialog from "./dialog";
// import FAQs from "./AddFaq";
// import ImageAndVideoUpload from "./Addmedia";


const AddService =({ showModal, setShowModal, isEditMode, editServiceData })=>{
    // const [showModal, setShowModal] = useState(false);
    const [videoProgress, setVideoProgress] = useState(0);
  const [showProgressModal, setShowProgressModal] = useState(false);
    const [servicedetails, setServicesDetails] = useState("");
    const [servicename, setServicesName] = useState("");
    const [selectedService, setSelectedService] = useState("");
    const [content, setContent] = useState("");
    const [categoryTitle, setCategoryTitle] = useState("");
    const [faqList, setFaqList] = useState([{ question: '', answer: '' }]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
  const maxQuestions = 15;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/categories_api.php/`)
      .then(response => {
        const data = response.data;

              //console.log(data);
              setCategories(data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });},[]);
  useEffect(() => {
    if (isEditMode && editServiceData) {
      //console.log(editServiceData.faqs);
      // If in edit mode and data is provided, set initial values
      setServicesName(editServiceData.name);
       setSelectedService(editServiceData.category.name);
      setServicesDetails(editServiceData.service_details)
      setContent(editServiceData.details);
       setCategoryTitle(editServiceData.title);
       const images = editServiceData.list_images.filter(url => !url.endsWith('.mp4') && !url.endsWith('.mkv') && !url.endsWith('.avi'));
       const videos = editServiceData.list_images.filter(url => url.endsWith('.mp4') || url.endsWith('.mkv') || url.endsWith('.avi'));
     
     
       
           setSelectedImages(images);
           setSelectedVideos(videos);
           const initialFaqList = [];

// Using a loop to populate faqList
if(editServiceData.faqs.questions!=null){
for (let i = 0; i < editServiceData.faqs.questions.length; i++) {
  const question =editServiceData.faqs.questions[i];
  const answer = editServiceData.faqs.answers[i];

  // Adding a new FAQ object to the list
  initialFaqList.push({ question, answer });
}


// Now, set the populated faqList to the state
setFaqList(initialFaqList);
      //  setFaqList(editServiceData.faqs);
   
    }}
  }, [isEditMode, editServiceData]);
  const handleAddQuestion = () => {
    if (faqList.length < maxQuestions) {
      setFaqList([...faqList, { question: '', answer: '' }]);
    }
  };
  const getCategoryByname = (name) => {


    const matchingcate = categories.find(cate =>cate.name === name);
  
    return matchingcate ? matchingcate.id : 0;
  };
  const handleSaveEdit = () => {
   
 

   

    if (editServiceData.id == null) {
      setShowProgressModal(true);
     const formData = new FormData();
     const questions = [];
     const answers = [];
 
     // Iterate through the data and separate questions and answers
     faqList.forEach(item => {
         questions.push(item.question);
         answers.push(item.answer);
     });
     //console.log({questions:questions});
     //console.log({answers:answers});
     // Append images without specifying indices
     // selectedImages.forEach(file => {
     //   formData.append('images[]', file);
     // });
     selectedImages.forEach((dataUrl, index) => {
      const now = new Date();
      const timestamp = now.toISOString().replace(/:/g, '-').replace(/\..+/, '');
      const filename = `Services_image${index}_${timestamp}.png`;
       const imageFile = dataURLtoFile(dataUrl, filename);
       //console.log(imageFile);
       formData.append('list_images[]', imageFile);
     });
   
     // Append other user data to the FormData object
     formData.append('name', servicename);
     formData.append('title', categoryTitle);
     formData.append('details', content);
     formData.append('service_details', servicedetails);
  
     
     formData.append('categories_id', getCategoryByname(selectedService));
     selectedVideos.forEach((dataUrl, index) => {
       // const filename = `video${index}.mp4`;
       // const imageFile = dataURLtoFile(dataUrl, filename);
       // //console.log(imageFile);
       formData.append('video[]', dataUrl.file);
     });
     faqList.forEach(item => {
      // questions.push(item.question);
      // answers.push(item.answer);
      formData.append('question_list[]',item.question);
      formData.append('answer_list[]', item.answer);
  });
   
    //  formData.append('question_list[]', questions);
    //  formData.append('answer_list[]', answers);
    
   
   const config = {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setVideoProgress(percentCompleted);
    },
    method: 'post',
    url: `${BASE_URL}/services_api.php`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  };

  // Make the Axios request
  axios(config)
    .then((response) => {
      //console.log(JSON.stringify(response.data));
      alert('Service added successfully');
      setShowProgressModal(false);
      setVideoProgress(0);
      setShowModal(false);
    })
    .catch((error) => {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form. Please check the console for details.');
      setShowProgressModal(false);
      setVideoProgress(0);
      setShowModal(false);
    });
}

   else{
    setShowProgressModal(true);
//  //console.log('Edited Category:', editedCategory);
const formData = new FormData();
const questions = [];
const answers = [];

// Iterate through the data and separate questions and answers
faqList.forEach(item => {
    questions.push(item.question);
    answers.push(item.answer);
});
//console.log({questions:questions});
//console.log({answers:answers});
// Append images without specifying indices
// selectedImages.forEach(file => {
//   formData.append('images[]', file);
// });
selectedImages.forEach((dataUrl, index) => {
  // //console.log('This dataUrl contains http or https:', dataUrl);
  if (!(dataUrl.startsWith('http://') || dataUrl.startsWith('https://'))) {
    const now = new Date();
const timestamp = now.toISOString().replace(/:/g, '-').replace(/\..+/, '');
const filename = `Services_image${index}_${timestamp}.png`;
  // const filename = `image${index}.png`;
  const imageFile = dataURLtoFile(dataUrl, filename);
  formData.append('list_images[]', imageFile);
}else
{
  formData.append('image_urls[]', dataUrl);
}

  });

// Append other user data to the FormData object
formData.append('name', servicename);
formData.append('title', categoryTitle);
formData.append('details', content);
formData.append('service_details', servicedetails);


formData.append('categories_id', getCategoryByname(selectedService));
selectedVideos.forEach((dataa, index) => {
  console.log(dataa);
  if (dataa.src!=null) {
       console.log("sent");
         formData.append('video[]', dataa.file);
       }else
       {
        formData.append('video_urls[]', dataa);
       }
});
faqList.forEach(item => {
  // questions.push(item.question);
  // answers.push(item.answer);
  formData.append('question_list[]',item.question);
  formData.append('answer_list[]', item.answer);
});


   const config = {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setVideoProgress(percentCompleted);
    },
     method: 'post',  // Use 'post' for update
     url: `${BASE_URL}/services_api.php/?id=${editServiceData.id}&_method=PATCH`,
     headers: {
       'Content-Type': 'multipart/form-data',
     },
     data: formData,
   };

//    // Make the Axios request
   axios(config)
     .then((response) => {
       //console.log(JSON.stringify(response.data));
       alert('Category Updated successfully');
       setShowProgressModal(false);
       setVideoProgress(0);
       setShowModal(false);
     })
     .catch((error) => {
       console.error('Error updating category:', error);
       alert('Error updating category. Please check the console for details.');
       setShowProgressModal(false);
       setVideoProgress(0);
       setShowModal(false);
     });
 }

  
 };
 function dataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const blob = new Blob([u8arr], { type: mime });
  const file = new File([blob], filename, { type: mime });
  
  return file;
}
  const handleQuestionChange = (index, value) => {
    const updatedList = [...faqList];
    updatedList[index].question = value;
    setFaqList(updatedList);
  };

  const handleAnswerChange = (index, value) => {
    const updatedList = [...faqList];
    updatedList[index].answer = value;
    setFaqList(updatedList);
  };
    const handleChange = (value) => {
      setContent(value);
    };
 
      const handleImageChange = (event) => {
        const files = event.target.files;
    
        if (files && files.length > 0) {
          const updatedImages = [...selectedImages];
    
          for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.onload = (e) => {
              updatedImages.push(e.target.result);
              if (i === files.length - 1) {
                // Set state after all files are processed
                setSelectedImages(updatedImages);
              }
            };
            reader.readAsDataURL(files[i]);
          }
        }
      };
    
      const handleRemoveImage = (index) => {
        const updatedImages = [...selectedImages];
        updatedImages.splice(index, 1);
        setSelectedImages(updatedImages);
      };
    
      const handleVideoChange = (event) => {
        const files = event.target.files;
    
        if (files.length > 0) {
          const updatedVideos = [...selectedVideos];
    
          for (const file of files) {
            const reader = new FileReader();
            reader.onload = (e) => {
              updatedVideos.push({ src: e.target.result, file });
              setSelectedVideos([...updatedVideos]);
            };
            reader.readAsDataURL(file);
          }
        }
      };
      const handleRemoveVideo = (index) => {
        const updatedVideos = [...selectedVideos];
        updatedVideos.splice(index, 1);
        setSelectedVideos([...updatedVideos]);
      };
    
return(
    <>
            <Modal.Header closeButton>
              <Modal.Title>Category Page</Modal.Title>
            </Modal.Header>
             {/* Pop Up Data */}
            <Modal.Body>
                <div> 
                <label className="form-label">Select Category</label>
          <select
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            className="form-control"
          >
            <option value="" disabled>Select a Category</option>
            {categories.map((service, index) => (
              <option key={index} value={service.name}>
                {service.name}
              </option>
            ))}
          </select>
          <br></br>
          <label  className="form-label">Service Title</label>
    <input
      id="categoryTitle"
      className="form-control"
      type="text"
      placeholder="Enter Category Title"
      value={categoryTitle}
      onChange={(e) => setCategoryTitle(e.target.value)}
    />
    <br></br>
    <label  className="form-label">Service Name</label>
    <input
      id="categoryTitle"
      className="form-control"
      type="text"
      placeholder="Ultravux will do .........."
      value={servicename}
      onChange={(e) => setServicesName(e.target.value)}
    />
    <br></br>
    <label  className="form-label">Description</label>
                  <ReactQuill
                    value={content}
                    onChange={handleChange}
                    className="py-3.5"
                    theme="snow"
                  />
                  <br></br>
                  <div class="form-outline">
                  <label class="form-label" for="textAreaExample">Service Details (Services separated by commmas    )</label>
  <textarea class="form-control" id="textAreaExample1"
  value={servicedetails}
  onChange={(e) => setServicesDetails(e.target.value)}
   rows="4"></textarea>
</div>
<br></br>
<div>
      <label
        htmlFor="imageInput"
        style={{ display: 'block', cursor: 'pointer', marginBottom: '8px' }}
      >
        <BsCloudUpload style={{ marginRight: '8px' }} />
        Upload Image 2352 × 1248 || 1752 × 981
      </label>
      <div className="">
        {[...Array(selectedImages.length + 1)].map((_, index) => (
          <div key={index} className="">
            <input
              id="imageInput"
              type="file"
              accept="image/*"
              className="form-control"
              style={{ display: 'none' }}
              onChange={handleImageChange}
              multiple={index === 0} // Allow selecting multiple files only for the first input
            />
          </div>
        ))}
      </div>

      {/* Preview of the uploaded images */}
      {selectedImages.length > 0 && (
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-4">
          {selectedImages.map((image, index) => (
            <div key={index} className="col">
            <div className="card">
              <img
                src={image}
                alt={`Uploaded Image Preview ${index + 1}`}
                style={{ maxWidth: '100%', height: '100px', marginBottom: '10px' }}
              />
                <div className="card-body">
              <button
                className="btn btn-danger btn-sm"
                onClick={() => handleRemoveImage(index)}
              >
                <BsX style={{ marginRight: '4px' }} />
                Remove
              </button>
              </div>
            </div>
            </div>
          ))}
        </div>
      )}
      <br />

       <div className="row">
        <label
          htmlFor="videoInput"
          className="col-12"
          style={{ cursor: 'pointer', marginBottom: '10px' }}
        >
          <BsCloudUpload style={{ marginRight: '8px' }} />
          Upload Video  2352 × 1248 || 1752 × 981
        </label>
        <input
          id="videoInput"
          type="file"
          accept="video/*"
          className="form-control col-12"
          style={{ display: 'none' }}
          onChange={handleVideoChange}
          multiple
        />
      </div>

      {/* Previews of the uploaded videos */}
      <div className="row">
        {selectedVideos.map((video, index) => (
          <div key={index} className="col-md-3 mb-3">
            <video
              controls
              src={video.src}
              alt={`Uploaded Video Preview ${index + 1}`}
              className="w-100"
              style={{ maxHeight: '120px', marginBottom: '5px' }}
            />
            <button
              onClick={() => handleRemoveVideo(index)}
              className="btn btn-danger btn-sm w-100"
            >
              <BsX /> Remove
            </button>
          </div>
        ))}
      </div>
    </div>
                  <br></br>
                  <div>
      <p>FAQs:</p>
      {faqList.map((faq, index) => (
        <div key={index} className="row">
          <div className="col-md-6">
            <input
              className="form-control mt-4"
              type="text"
              placeholder="Question"
              value={faq.question}
              onChange={(e) => handleQuestionChange(index, e.target.value)}
            />
            </div>
            <div className="col-md-6">
            <input
              className="form-control mt-4"
              type="text"
              placeholder="Answer"
              value={faq.answer}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
            />
          </div>
        </div>
      ))}
      {faqList.length < maxQuestions && (
        <button className="btn btn-primary mt-4" onClick={handleAddQuestion}>
          Add Question
        </button>
      )}
    </div>
                </div>
          {/* Pop Up Data */}
            </Modal.Body>
            <Modal.Footer>
              
              <Button variant="primary"  onClick={handleSaveEdit }>
                Save Changes
              </Button>
            </Modal.Footer>
            <ProgressDialog show={showProgressModal} onHide={() => setShowProgressModal(false)} progress={videoProgress} />
         
    </>
)
}
export default AddService;