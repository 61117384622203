import React, { useState ,useEffect} from "react";
import axios from "axios";
import BASE_URL from "./constant/constant";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IoCloseSharp } from "react-icons/io5";
import { BsFileEarmarkImage, BsFillCaretDownFill, BsPencil, BsPlus, BsTrash } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Markup } from 'interweave';
import { format } from 'date-fns';

const AddBlog = () => {
  useEffect(() => {
    axios
      .get(`${BASE_URL}/blogs_api.php`)
      .then(response => {
        const data = response.data;

              //console.log(data);
              setBlogs(data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });},[]);
  const [blogs, setBlogs] = useState([]);

  const [content, setContent] = useState( '');

  const [selectedBlog, setSelectedBlog] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAddBlogModal, setShowAddBlogModal] = useState(false);

  const handleAddBlogClick = () => {
    setShowAddBlogModal(true);
    setSelectedBlog(null); // Reset selected blog when adding a new one
  };

  const handleCloseModal = () => {
    setShowAddBlogModal(false);
    setSelectedBlog(null);
  };

  const handleEditClick = (blog) => {
    setSelectedBlog(blog);
    setShowAddBlogModal(true);
  };

  const handleRemoveClick = (id) => {
    axios.delete(`${BASE_URL}/blogs_api.php/?id=${id}`)
    .then(response => {
      // Handle success, e.g., update the state or show a success message
      //console.log('Blog deleted successfully:', response.data);
      alert('Blog Delete Successfully');
      // Perform any additional actions you need
    })
    .catch(error => {
      // Handle error, e.g., show an error message
      console.error('Error deleting blog:', error);
      // Perform any additional error handling or actions you need
    });
    // const config = {
    //   method: 'delete',
    //   url: `${BASE_URL}/blogs_api.php/?id=${id}`
    // };
  
    // // Make the Axios request
    // axios(config)
    //   .then((response) => {
    //     //console.log(JSON.stringify(response.data));
    //     alert('Blog Delete Successfully');
    //   })
    //   .catch((error) => {
    //     console.error('Error submitting the form:', error);
    //     alert('Error submitting the form. Please check the console for details.');
    //   });
      setBlogs((prevBlogs) => prevBlogs.filter(blog => blog.id !== id));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Set selected image for preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };
  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    const blob = new Blob([u8arr], { type: mime });
    const file = new File([blob], filename, { type: mime });
    
    return file;
  }
const handleInsertOrUpdate = ()=>{
  if(selectedBlog.id==null){

  
  const formData = new FormData();
  const timestamp = format(new Date(), 'yyyyMMddHHmmss');
    const filename = `blog${timestamp}.png`;
    const imageFile = dataURLtoFile(selectedImage, filename);
    //console.log(imageFile);
    formData.append('image', imageFile);
  formData.append('title', selectedBlog.title,);
  formData.append('description', content);
  

  // Configure the Axios request
  const config = {
    method: 'post',
    url: `${BASE_URL}/blogs_api.php`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  };

  // Make the Axios request
  axios(config)
    .then((response) => {
      //console.log(JSON.stringify(response.data));
      alert('Blog added successfully');
    })
    .catch((error) => {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form. Please check the console for details.');
    });}else{
      //console.log(
      //   {
      //     title:selectedBlog.title,
      //     image:selectedImage,
      //     description:content
      //   }
      // );
      const formData = new FormData();
      if (!(selectedImage.startsWith('http://') || selectedImage.startsWith('https://'))) {
        const timestamp = format(new Date(), 'yyyyMMddHHmmss');
        const filename = `blog${timestamp}.png`;
        const imageFile = dataURLtoFile(selectedImage, filename);
        //console.log(imageFile);
        formData.append('image', imageFile);
      }
      
      formData.append('title', selectedBlog.title,);
      formData.append('description', content);
      
    
      // Configure the Axios request
      const config = {
        method: 'post',
        url: `${BASE_URL}/blogs_api.php/?id=${selectedBlog.id}&_method=PATCH`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };
    
      // Make the Axios request
      axios(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          alert('Blog updated successfully');
        })
        .catch((error) => {
          console.error('Error submitting the form:', error);
          alert('Error submitting the form. Please check the console for details.');
        });
    }
    setShowAddBlogModal(false);
};
  const handleChange = (value) => {
    setContent(value);
  };
  function truncateString(str, maxLength) {
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
  }
  const modalTitle = selectedBlog ? 'Edit Blog' : 'Add New Blog';

  return (
    <div className="container mt-5">
      <button className="btn btn-success mb-3" onClick={()=>{handleAddBlogClick();
        setContent('');
        setSelectedImage(null);}}>
        <BsPlus className="mr-2" /> Add New Blog
      </button>

      {/* Table */}
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Blog Name</th>
            <th scope="col">Description</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {blogs.map(blog => (
            <tr key={blog.id}>
              <td>{blog.title}</td>
       
              <td> <Markup content={truncateString(blog.description, 150)} /> </td> 
              <td>
                <button className="btn btn-primary" onClick={() => {handleEditClick(blog);
                  setContent(blog.description);
                  setSelectedImage(blog.image);
                  }}>
                  <BsPencil className="mr-2" /> Edit
                </button>
                <button className="btn btn-danger ml-4" style={{marginLeft:"8px"}} onClick={() => handleRemoveClick(blog.id)}>
                  <BsTrash className="mr-2" /> 
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Table */}

      {/* Add New Blog Modal */}
      {showAddBlogModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modalTitle}</h5>
                <span aria-hidden="true" onClick={handleCloseModal}><IoCloseSharp /></span>
              </div>
              <div className="modal-body">
                <div className="w-80 mt-4">
                  {/* Add your form for adding/editing a blog here */}
                  <div className="mb-4">
                    <label className="form-label">Blog Title</label>
                    <input
                      id="BlogTitle"
                      className="form-control"
                      type="text"
                      placeholder="Blog Title"
                      value={selectedBlog? selectedBlog.title : ''}
                      // value={editedCategory.name || ''}
                      onChange={(e) => setSelectedBlog({ ...selectedBlog, title: e.target.value })}
                    />
                  </div>
                  <p className="font-weight-bold mt-4">Choose Media to upload</p>
                  <label htmlFor="mediaUpload" className="btn btn-outline-primary">
                    <BsFileEarmarkImage className="mr-2" /> Upload Image
                    <input type="file" accept="image/*" id="mediaUpload" onChange={handleImageChange} className="d-none" />
                  </label>
                  <br></br>
                  {/* Preview of the uploaded image */}
                  {selectedImage && (
                    <div>
                      <img src={selectedImage} alt="Uploaded Preview" style={{ maxWidth: '100px' }} />
                    </div>
                  )}
                  <br></br>
                  {/* Quill editor for blog content */}
                  <ReactQuill
                    value={content}
                    onChange={handleChange}
                    className="py-3.5"
                    theme="snow"
                  />
                  {/* Save button */}
                  {/* <button className="btn btn-danger mt-4">
                    <BsFillCaretDownFill className="mr-2" /> Save
                  </button> */}
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                <button type="button" className="btn btn-primary"onClick={handleInsertOrUpdate}>Save changes</button>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}
      {/* Add New Blog Modal */}
    </div>
  );
};

export default AddBlog;
